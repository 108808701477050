import router from '@/router/router.js'
import menuTypes from '../enums/menu_types.js'
import periodUnits from '@/shared/enums/period_units.js'
import timeFilter from '@/enums/time_filter.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import * as eventsTypes from '@/shared/enums/eventsTypes.js'
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import * as mutationTypes from '../store/mutations-types.js'
import store from '@/store/index.js'
import {manageSelectOption, onOff} from "./fonction-menu.js"
import * as d3 from 'd3'
import colorThemes from '../enums/color_themes.js'
import fontSize from '@/enums/fontSize.js'
import * as hierarchy from '@/shared/enums/hierarchy.js'
import * as HierarchyEntries from '@/libraries/hierarchy/hierarchyEntries.js'

function getItemMenu(contextMenuConfig, id) {
  const itemsMenu = [
    {
      id: 'Logo0',
      icon: 'Logo0',
      type: menuTypes.ICON,
      state: 'off',
      on: () => { },
      off: () => { },
      click: async function() { await onOff(contextMenuConfig, this) }
    },
    {
      id: 'Diags_memorises',
      label: "Diags mémorisés",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('event/common/controlEvent',{isEnabled: true, typeEventslist: borderListTypes.LIST_MEMORIZED_EVENTS})
      },
      off: async function () {
        await store.dispatch('event/common/controlEvent',{isEnabled: false, typeEventslist: borderListTypes.LIST_MEMORIZED_EVENTS})
      }
    },
    {
      id: 'DP',
      label: "DP",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
          dp: true
        })
        await store.dispatch('event/common/baseEnableFiltering',{label: 'Diagnostic principal', isEventsMemorized: false})
      },
      off: async function () {
        await store.dispatch('event/common/disableFiltering')
      }
    },
    {
      id: 'Sev>=2',
      label: "Sévérité ≥2",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
          severity: { min: 2, max: 4 }
        })
        await store.dispatch('event/common/baseEnableFiltering',{label: 'Sévérité ≥2', isEventsMemorized: false})
      },
      off: async function () {
        await store.dispatch('event/common/disableFiltering')
      }
    },
    {
      id: 'Sev>=3',
      label: "Sévérité ≥3",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
          severity: { min: 3, max: 4 },
        })
        await store.dispatch('event/common/baseEnableFiltering',{label: 'Sévérité ≥3', isEventsMemorized: false})
      },
      off: async function () {
        await store.dispatch('event/common/disableFiltering')
      }
    },
    {
      id: '5+recents',
      label: "5 plus récents",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
          severity: {},
          antecedent: {},
          last: 5
        })
        await store.dispatch('event/common/baseEnableFiltering',{label: '5 plus récents', isEventsMemorized: false})
      },
      off: async function () {
        await store.dispatch('event/common/disableFiltering')
      }
    },
    {
      id: 'ATCD>=3',
      label: "Antécédents ≥3",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
          antecedent: { min: 3 }
        })
        await store.dispatch('event/common/baseEnableFiltering',{label: 'Antécédents ≥3', isEventsMemorized: false})
      },
      off: async function () {
        await store.dispatch('event/common/disableFiltering')
      }
    },
    {
      id: 'Sev>=3+ATCD>=3',
      label: "Sév. ≥3 + Ant ≥3",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
          severity: { min: 3, max: 4 },
          antecedent: { min: 3 }
        })
        await store.dispatch('event/common/baseEnableFiltering',{label: 'Sév. ≥3 + Ant ≥3', isEventsMemorized: false})
      },
      off: async function () {
        await store.dispatch('event/common/disableFiltering')
      }
    },
    {
      id: 'Top10',
      label: "Top 10",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('event/common/controlEvent',{isEnabled: true, typeEventslist: borderListTypes.LIST_TOP_10})
      },
      off: async function () {
        await store.dispatch('event/common/controlEvent',{isEnabled: false, typeEventslist: borderListTypes.LIST_TOP_10})
      }
    },
    {
      id: 'Mode',
      label: "Mode",
      type: menuTypes.TEXT,
      state: 'off',
    },
    {
      id: 'DossierPatient',
      label: "Dossier Patient",
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`)
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.PATIENT,
          options: {}
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'Populationnel',
      label: "Populationnel",
    },
    {
      id: 'PopRegroupement/section',
      label: "Par section",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`)
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options: {
            mergedBy: eyediagMode.populationalOptions.mergedBy.SECTION,
            severity: eyediagMode.populationalOptions.severity.STATS,
            content: (store.state.mode.options.content)
              ? store.state.mode.options.content
              : [eyediagMode.populationalOptions.content.GHM]
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'PopRegroupement/code+sev',
      label: "Par code / Sev diag",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`)
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options: {
            mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
            severity: eyediagMode.populationalOptions.severity.CODE,
            content: (store.state.mode.options.content)
              ? store.state.mode.options.content
              : [eyediagMode.populationalOptions.content.GHM]
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'PopRegroupement/code+sev_stat',
      label: "Par code / Sev stats",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`)
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options: {
            mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
            severity: eyediagMode.populationalOptions.severity.STATS,
            content: (store.state.mode.options.content)
              ? store.state.mode.options.content
              : [eyediagMode.populationalOptions.content.GHM]
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'PopRegroupement/code+sev/podium',
      label: "Par code / Sev diag / Podium",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`)
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options: {
            mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
            severity: eyediagMode.populationalOptions.severity.CODE,
            podiumBy: eyediagMode.populationalOptions.podiumBy.ALL_CIRCLES,
            content: (store.state.mode.options.content)
              ? store.state.mode.options.content
              : [eyediagMode.populationalOptions.content.GHM]
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'PopRegroupement/code+sev_stat/podium',
      label: "Par code / Sev stats / Podium",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`)
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options: {
            mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
            severity: eyediagMode.populationalOptions.severity.STATS,
            podiumBy: eyediagMode.populationalOptions.podiumBy.ALL_CIRCLES,
            content: (store.state.mode.options.content)
              ? store.state.mode.options.content
              : [eyediagMode.populationalOptions.content.GHM]
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'PopRegroupement/code+sev/podiumM/cercle',
      label: "Par code / Sev diag / Podium per circle",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`)
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options: {
            mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
            severity: eyediagMode.populationalOptions.severity.CODE,
            podiumBy: eyediagMode.populationalOptions.podiumBy.CIRCLE,
            content: (store.state.mode.options.content)
              ? store.state.mode.options.content
              : [eyediagMode.populationalOptions.content.GHM]
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'PopRegroupement/code+sev_stat/podium/cercle',
      label: "Par code / Sev stats / Podium per circle",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`)
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options: {
            mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
            severity: eyediagMode.populationalOptions.severity.STATS,
            podiumBy: eyediagMode.populationalOptions.podiumBy.CIRCLE,
            content: (store.state.mode.options.content)
              ? store.state.mode.options.content
              : [eyediagMode.populationalOptions.content.GHM]
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },{
      id: 'Echelle',
      label: "Echelle",
    },
    {
      id: 'Echelle/lineaire',
      label: "Echelle linéaire",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options:{...store.state.mode.options,
            ...{typeMerge: eyediagMode.populationalOptions.typeMerge.LINEAR}}
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'Echelle/racine-carre',
      label: "Echelle racine carré",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options:{...store.state.mode.options,
            ...{typeMerge: eyediagMode.populationalOptions.typeMerge.RACINECARRE}
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'Echelle/algo-Jenks',
      label: "Echelle de Jenks",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(mutationTypes.SET_EYEDIAG_MODE, {
          type: eyediagMode.mode.POPULATIONAL,
          options:{...store.state.mode.options,
            ...{typeMerge: eyediagMode.populationalOptions.typeMerge.JENKS}}
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'LiensCR',
      label: "Liens comptes rendus",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "Liens comptes rendus")
        store.commit(`event/common/${mutationTypes.SET_DISPLAYED_LINES}`, true)
      },
      off: async function () {
        store.commit(`event/common/${mutationTypes.SET_DISPLAYED_LINES}`, false)
        store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "")
      }
    },
    {
      id: 'Temps',
      label: "Temps",
    },
    {
      id: 'Semaine',
      label: "Semaine",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('event/common/controlEvent',{isEnabled: true, typeEventslist: borderListTypes.LIST_TIME_FILTER, time: timeFilter.WEEK})
      },
      off: async function () {
        await store.dispatch('event/common/controlEvent',{isEnabled: false, typeEventslist: borderListTypes.LIST_TIME_FILTER, time: timeFilter.WEEK})
      }
    },
    {
      id: 'Mois',
      label: "Mois",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('event/common/controlEvent',{isEnabled: true, typeEventslist: borderListTypes.LIST_TIME_FILTER, time: timeFilter.MONTH})
      },
      off: async function () {
        await store.dispatch('event/common/controlEvent',{isEnabled: false, typeEventslist: borderListTypes.LIST_TIME_FILTER, time: timeFilter.MONTH})
      }
    },
    {
      id: 'Types_event',
      label: "Types d'événement",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_CHOOSE_TYPE_EVENTS_DISPLAY)
      },
      off: async function () {
        store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
      }
    },
    {
      id: 'carnetvaccin',
      label: "Carnet Vaccination",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, '')
        store.commit(mutationTypes.SET_TYPES_EVENTS_TO_DISPLAY, {
          immunization: {
            types: [
              {
                type: eventsTypes.immunization.ALL
              }
            ]
          }
        }, { root: true })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        store.commit(mutationTypes.SET_TYPES_EVENTS_TO_DISPLAY, {
          procedure: {
            types: [
              {
                type: eventsTypes.procedure.ALL
              }
            ]
          },
          immunization: {
            types: [
              {
                type: eventsTypes.immunization.ALL
              }
            ]
          },
          condition: {
            types: [
              {
                type: eventsTypes.condition.ALL
              }
            ]
          },
          allergy: {
            types: [
              {
                type: eventsTypes.allergy.ALL
              }
            ]
          }
        }, { root: true })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      }
    },
    {
      id: 'SauvegarderFiltres',
      label: "Sauvegarder mes filtres",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY)
      },
      off: async function () {
        store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
      }
    },
    {
      id: 'HistoryIcon',
      icon: 'HistoryIcon',
      type: menuTypes.ICON,
      state: 'off',
      on: () => { store.commit(`circle/${mutationTypes.UPDATE_HIDE_INNER_CIRCLE}`, true) },
      off: () => { store.commit(`circle/${mutationTypes.UPDATE_HIDE_INNER_CIRCLE}`, false) },
      click: async function() { await onOff(contextMenuConfig, this) },
    },
    {
      id: 'TempsOP',
      label: "Temps optimal",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.getters['circle/optimalYearPerCircle'],
          periodUnit: periodUnits.YEAR,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        });
      },
      off: async function () { }
    },
    {
      id: 'Trimestre',
      label: "Trimestre",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: 1,
          periodUnit: periodUnits.QUARTER,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        });
      },
      off: async function () { }
    },
    {
      id: 'Semestre',
      label: "Semestre",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: 1,
          periodUnit: periodUnits.SEMESTER,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        });
      },
      off: async function () { }
    },
    {
      id: 'MoisTemp',
      label: "Mois",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: 1,
          periodUnit: periodUnits.MONTH,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        });
      },
      off: async function () { }
    },
    {
      id: '1 an',
      label: "1 an",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: 1,
          periodUnit: periodUnits.YEAR,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        });
      },
      off: async function () { }
    },
    {
      id: '2 ans',
      label: "2 ans",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: 2,
          periodUnit: periodUnits.YEAR,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        });
      },
      off: async function () { }
    },
    {
      id: '3 ans',
      label: "3 ans",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: 3,
          periodUnit: periodUnits.YEAR,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        });
      },
      off: async function () { }
    },
    {
      id: '4 ans',
      label: "4 ans",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: 4,
          periodUnit: periodUnits.YEAR,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        });
      },
      off: async function () { }
    },
    {
      id: 'Logo2',
      icon: 'Logo2',
      type: menuTypes.ICON,
      state: 'off',
      on: () => { },
      off: () => { },
      click: async function() { await onOff(contextMenuConfig, this) },
    },
    {
      id: 'CustomHierarchy',
      label: "Hierarchy Custom",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_ENTER_HIERARCHY)
      },
      off: async function () {
        store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
        store.commit(mutationTypes.SET_HIERARCHY, {
          type: hierarchy.type.SYSTEM,
          id: hierarchy.hierarchy.DEFAULT
        })
        store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      }
    },
    {
      id: 'Diabetologie',
      label: "Diabétologie",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await HierarchyEntries.diabGlobal(store)
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'HierarchyGHM',
      label: "GHM",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        const newHierarchy = {
          type: hierarchy.type.USER,
          id: 'GHM'
        }
        if (store.state.hierarchy.id !== newHierarchy.id) {
          store.dispatch('addEntryHistorySections', { asSection: null, sections: [], hierarchy: newHierarchy})
          store.commit(mutationTypes.SET_HIERARCHY, newHierarchy)
          store.dispatch('circle/getDataRepresentation', {
            unitPerCircle: store.state.circle.unitPerCircle,
            periodUnit: store.state.circle.periodUnit,
            reloadDataTypes: reloadDataTypes.CATEGORISATION
          })
        }
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'TESTW',
      label: "Cats Standards",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        const newHierarchy = {
          type: hierarchy.type.USER,
          id: 'Welcoop'
        }
        if (store.state.hierarchy.id !== newHierarchy.id) {
          store.dispatch('addEntryHistorySections', { asSection: null, sections: [], hierarchy: newHierarchy})
          store.commit(mutationTypes.SET_HIERARCHY, newHierarchy)
          store.dispatch('circle/getDataRepresentation', {
            unitPerCircle: store.state.circle.unitPerCircle,
            periodUnit: store.state.circle.periodUnit,
            reloadDataTypes: reloadDataTypes.CATEGORISATION
          })
        }
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'TESTW2',
      label: "TESTW2",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        const newHierarchy = {
          type: hierarchy.type.USER,
          id: 'Welcoop2'
        }
        if (store.state.hierarchy.id !== newHierarchy.id) {
          store.dispatch('addEntryHistorySections', { asSection: null, sections: [], hierarchy: newHierarchy})
          store.commit(mutationTypes.SET_HIERARCHY, newHierarchy)
          store.dispatch('circle/getDataRepresentation', {
            unitPerCircle: store.state.circle.unitPerCircle,
            periodUnit: store.state.circle.periodUnit,
            reloadDataTypes: reloadDataTypes.CATEGORISATION
          })
        }
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'TESTW3',
      label: "Cats Etendues",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        const newHierarchy = {
          type: hierarchy.type.USER,
          id: 'Welcoop3'
        }
        if (store.state.hierarchy.id !== newHierarchy.id) {
          store.dispatch('addEntryHistorySections', { asSection: null, sections: [], hierarchy: newHierarchy})
          store.commit(mutationTypes.SET_HIERARCHY, newHierarchy)
          store.dispatch('circle/getDataRepresentation', {
            unitPerCircle: store.state.circle.unitPerCircle,
            periodUnit: store.state.circle.periodUnit,
            reloadDataTypes: reloadDataTypes.CATEGORISATION
          })
        }
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'QOR',
      label: "Qor",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        const newHierarchy = {
          type: hierarchy.type.USER,
          id: 'QOR'
        }
        if (store.state.hierarchy.id !== newHierarchy.id) {
          store.dispatch('addEntryHistorySections', { asSection: null, sections: [], hierarchy: newHierarchy})
          store.commit(mutationTypes.SET_HIERARCHY, newHierarchy)
          store.dispatch('circle/getDataRepresentation', {
            unitPerCircle: store.state.circle.unitPerCircle,
            periodUnit: store.state.circle.periodUnit,
            reloadDataTypes: reloadDataTypes.CATEGORISATION
          })
        }
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'HierarchyUM',
      label: "UM",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        const newHierarchy = {
          type: hierarchy.type.USER,
          id: 'UM'
        }
        if (store.state.hierarchy.id !== newHierarchy.id) {
          store.dispatch('addEntryHistorySections', { asSection: null, sections: [], hierarchy: newHierarchy})
          store.commit(mutationTypes.SET_HIERARCHY, newHierarchy)
          store.dispatch('circle/getDataRepresentation', {
            unitPerCircle: store.state.circle.unitPerCircle,
            periodUnit: store.state.circle.periodUnit,
            reloadDataTypes: reloadDataTypes.CATEGORISATION
          })
        }
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'DiabetologieNurseEntry',
      label: "Diabétologie Entrée",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await HierarchyEntries.diabNurseEntry(store)
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'DiabetologieNurseEntryTrt',
      label: "Diabétologie Entrée Traitement",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await HierarchyEntries.diabNurseEntryTrt(store)
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'AfficherMasquerCatLabel',
      label: "Afficher / Masquer",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(mutationTypes.SET_DISPLAY_LABEL_SECTIONS, true, { root: true })
      },
      off: async function () {
        store.commit(mutationTypes.SET_DISPLAY_LABEL_SECTIONS, false, { root: true })
      }
    },
    // {
    //   id: `MasquerCatLabel`,
    //   label: "Masquer",
    //   type: menuTypes.TEXT,
    //   state: 'off',
    //   click: async function () {
    //     manageSelectOption(this, contextMenuConfig)
    //     await onOff(contextMenuConfig, this)
    //   },
    //   on: async function () {
    //     store.commit(mutationTypes.SET_DISPLAY_LABEL_SECTIONS, false)
    //   },
    //   off: async function () {
    //     store.commit(mutationTypes.SET_DISPLAY_LABEL_SECTIONS, true)
    //   }
    // },
    {
      id: `TailleMenu`,
      label: "Taille Txt",
      type: menuTypes.TEXT,
    },
    {
      id: `InterfaceTaille1`,
      label: "Taille 1",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(mutationTypes.SET_RATIO_REM_PX, fontSize.SIZE1, { root: true })
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: `InterfaceTaille2`,
      label: "Taille 2",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(mutationTypes.SET_RATIO_REM_PX, fontSize.SIZE2, { root: true })
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: `InterfaceTaille3`,
      label: "Taille 3",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(mutationTypes.SET_RATIO_REM_PX, fontSize.SIZE3, { root: true })
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: `CouleurRepCercle`,
      label: "Couleur cercle 0",
      type: menuTypes.TEXT,
    },
    {
      id: `CouleurRepCercleOriginal`,
      label: "original",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#95CD41")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercle#A8FF98`,
      label: "#A8FF98",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#A8FF98")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercleLime`,
      label: "Lime",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#00FF00")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercleChartreuse`,
      label: "Chartreuse",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#7FFF00")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercleSpringGreen`,
      label: "SpringGreen",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "springGreen")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercle#74FF00`,
      label: "#74FF00",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#74FF00")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercle#3AFF00`,
      label: "#3AFF00",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#3AFF00")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercle#00FF23`,
      label: "#00FF23",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#00FF23")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercle#7BFF57`,
      label: "#7BFF57",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#7BFF57")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercle#8EFF70`,
      label: "#8EFF70",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#8EFF70")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `CouleurRepCercleBlue`,
      label: "blue",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.CHANGE_REP_CIRCLE_COLOR}`, "#6495ED")
      },
      off: async function () { 
        this.on()
      }
    },
    {
      id: `Theme`,
      label: "Couleurs",
      type: menuTypes.TEXT,
    },
    {
      id: `ThemeOriginal`,
      label: "Vert",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/changeTheme', colorThemes.ORIGINAL)
      },
      off: async function () { 
        await this.on()
      }
    },
    {
      id: `ThemeVert+`,
      label: "Vert",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/changeTheme', colorThemes['GREEN+'])
      },
      off: async function () { 
        await this.on()
      }
    },
    {
      id: `ThemeBleu`,
      label: "bleu",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/changeTheme', colorThemes.BLUE)
      },
      off: async function () { 
        await this.on()
      }
    },
    {
      id: `ThemeBleu+`,
      label: "Bleu",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('circle/changeTheme', colorThemes['BLUE+'])
      },
      off: async function () { 
        await this.on()
      }
    },
    {
      id: `ThemeGeneral`,
      label: "Thème",
      type: menuTypes.TEXT,
    },
    {
      id: `ThemeGeneralClair`,
      label: "Clair",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        document.documentElement.classList.remove("dark")
        document.documentElement.classList.add("light")
        localStorage.setItem('mainTheme', 'light')
  
        //Corriger la couleur à l'intérieur des cercles d'événement
        store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, store.state.event.common.events)
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: `ThemeGeneralSombre`,
      label: "Sombre",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        document.documentElement.classList.remove("light")
        document.documentElement.classList.add("dark")
        localStorage.setItem('mainTheme', 'dark')
  
        //Corriger la couleur à l'intérieur des cercles d'événement
        store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, store.state.event.common.events)
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'Centrer_representation',
      label: "Centrer la figure",
      type: menuTypes.TEXT,
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        window.dispatchEvent(new Event('resize'))
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'Logo3',
      icon: 'Logo3',
      type: menuTypes.ICON,
      state: 'off',
      on: () => { },
      off: () => { },
      click: async function() { await onOff(contextMenuConfig, this) },
    },
    {
      id: 'FiltrePatient',
      label: "Filtre Patient",
    },
    {
      id: 'AucunFiltrePatient',
      label: "Aucun",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.UPDATE_CIRCLE_FILTERS}`, {
          ...store.state.circle.filters,
          ...{ 
            sectionsEventsOnly: false,
            matchOnly: false
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        store.commit(`circle/${mutationTypes.UPDATE_CIRCLE_FILTERS}`, {
          ...store.state.circle.filters,
          ...{ 
            sectionsEventsOnly: false,
            matchOnly: false
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      }
    },
    {
      id: 'CatFiltrePatient',
      label: "Catégories",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.UPDATE_CIRCLE_FILTERS}`, {
          ...store.state.circle.filters,
          ...{
            sectionsEventsOnly: true,
            matchOnly: false
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        store.commit(`circle/${mutationTypes.UPDATE_CIRCLE_FILTERS}`, {
          ...store.state.circle.filters,
          ...{
            sectionsEventsOnly: false,
            matchOnly: false
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      }
    },
    {
      id: 'CorresFiltrePatient',
      label: "Corres.seules",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.UPDATE_CIRCLE_FILTERS}`, {
          ...store.state.circle.filters,
          ...{
            sectionsEventsOnly: false,
            matchOnly: true
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      },
      off: async function () {
        store.commit(`circle/${mutationTypes.UPDATE_CIRCLE_FILTERS}`, {
          ...store.state.circle.filters,
          ...{
            sectionsEventsOnly: false,
            matchOnly: true
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      }
    },
    {
      id: 'ScoreWelcoop',
      label: "Alerte",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 9)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'ScoreQor0',
      label: "Score QOR 0",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 10)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'ScoreQor1',
      label: "Score QOR 1",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 11)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'ScoreQor2',
      label: "Score QOR 2",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 12)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'ScoreQor3',
      label: "Score QOR 3",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 13)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'ScoreQor4',
      label: "Score QOR 4",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 14)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'FactAggrCOVID',
      label: "Fact.Aggr.COVID",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 1)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'Euroscore2.2',
      label: "Euroscore2.2",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 4)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'Desir',
      label: "Desir",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 6)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'Findrisc',
      label: "Findrisc",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 7)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'ATCDfam',
      label: "Antéc.Familiaux",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`refCircle/familyHistory/${mutationTypes.SET_DISPLAY_FAMILY_HISTORY_CIRCLE}`, true)
      },
      off: async function () {
        store.commit(`refCircle/familyHistory/${mutationTypes.SET_DISPLAY_FAMILY_HISTORY_CIRCLE}`, false)
      }
    },
    {
      id: 'CHA2',
      label: "CHA₂DS₂-VASc",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 3)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'HasBled',
      label: "HasBled",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch('refCircle/score/baseEnableScore', 2)
      },
      off: async function () {
        store.dispatch('refCircle/score/disableScore')
      }
    },
    {
      id: 'Emplacement',
      label: "Emplacement",
    },
    {
      id: 'Emplacement1.012',
      label: "1.012",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.012, { root: true })
      },
      off: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.012, { root: true })
      }
    },
    {
      id: 'Emplacement1.03',
      label: "1.03",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.03, { root: true })
      },
      off: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.012, { root: true })
      }
    },
    {
      id: 'Emplacement1.066',
      label: "1.066",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.066, { root: true })
      },
      off: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.012, { root: true })
      }
    },
    {
      id: 'Emplacement1.1',
      label: "1.1",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.1, { root: true })
      },
      off: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.012, { root: true })
      }
    },
    {
      id: 'Emplacement1.15',
      label: "1.15",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.15, { root: true })
      },
      off: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.012, { root: true })
      }
    },
    {
      id: 'Emplacement1.2',
      label: "1.2",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.2, { root: true })
      },
      off: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.012, { root: true })
      }
    },
    {
      id: 'Emplacement1.3',
      label: "1.3",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.3, { root: true })
      },
      off: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.012, { root: true })
      }
    },
    {
      id: 'Emplacement1.4',
      label: "1.4",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.4, { root: true })
      },
      off: async function () {
        store.commit(`refCircle/score/${mutationTypes.SET_COEF_RADIUS_SCORE}`, 1.012, { root: true })
      }
    },
    {
      id: 'Logo4',
      icon: 'Logo4',
      type: menuTypes.ICON,
      state: 'off',
      on: () => { },
      off: () => { },
      click: async function() { await onOff(contextMenuConfig, this) },
    },
    {
      id: 'RetourAccueil',
      label: "Retour Accueil",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        router.push({ name: "Home" });
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'AffichageTOUS',
      label: "Affichage TOUS",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.DISPLAY_ALL_ACTIVE_CIRCLE_EVENT}`, true)
        await store.dispatch('event/common/generateEvents')
      },
      off: async function () {
        store.commit(`event/common/${mutationTypes.DISPLAY_ALL_ACTIVE_CIRCLE_EVENT}`, false)
        await store.dispatch('event/common/generateEvents')
      }
    },
    {
      id: 'Tooltips',
      label: "Tooltips",
      type: menuTypes.TEXT,
    },
    {
      id: 'TooltipsOnOff',
      label: "Afficher / Masquer",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.HIDE_AND_SHOW_TOOLTIPS}`)
      },
      off: async function () {
        store.commit(`event/common/${mutationTypes.HIDE_AND_SHOW_TOOLTIPS}`)
      }
    },
    {
      id: 'TooltipsReorg',
      label: "Réorganiser",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.ORDER_TOOLTIPS}`)
  
        d3.selectAll('.tooltip')
          .style('width', 'var(--tooltip-event-default-width)')
          .style('height', 'var(--tooltip-event-default-height)')
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'TooltipsSupp',
      label: "Supprimer",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`);
      },
      off: async function () {
        store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`);
      }
    },
    {
      id: 'AfficherNonCl',
      label: "Afficher les non classés",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_UNCLASSIFIED)
      },
      off: async function () {
        store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
      }
    },
    {
      id: 'ModeEdition',
      label: "Mode Edition",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        if (store.getters['isInNurseEntry'] === false) {
          store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "Edition")
          store.commit(`${mutationTypes.IS_EDIT_MODE}`);
        }
      },
      off: async function () {
        if (store.getters['isInNurseEntry'] === false) {
          store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "")
          store.commit(`${mutationTypes.IS_EDIT_MODE}`);
        }
      }
    },
    {
      id: 'Collaboration',
      label: "Collaboration",
      type: menuTypes.TEXT,
      state: 'off',
    },
    {
      id: 'CollabCreer',
      label: "Créer une session",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        if (store.state.ws.modalState.sessionCreation) {
          store.commit(`ws/${mutationTypes.SET_STATE_MODAL_SESSION_CREATION}`, false)
        } else {
          store.commit(`ws/${mutationTypes.SET_STATE_MODAL_SESSION_CREATION}`, true)
        }
      },
      off: async function () {
        this.on()
      }
    },
    {
      id: 'CollabJoindre',
      label: "Joindre une session",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`ws/${mutationTypes.SET_STATE_MODAL_SESSION_JOIN}`, true)
      },
      off: async function () {
        store.commit(`ws/${mutationTypes.SET_STATE_MODAL_SESSION_JOIN}`, true)
      }
    },
    {
      id: 'CollabQuit',
      label: "Quitter la session",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        if (store.state.ws.inCollaboration && store.state.ws.viewer) {
          return
        } else {
          await store.dispatch("ws/leaveRoom")
        }
      },
      off: async function () {
        if (store.state.ws.inCollaboration && store.state.ws.viewer) {
          return
        } else {
          await store.dispatch("ws/leaveRoom")
        }
      }
    },
    {
      id: 'CollabCouperVisio',
      label: "Couper la Visio",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        await store.dispatch("ws/stopVisio")
      },
      off: async function () {
        await this.on()
      }
    },
    {
      id: 'CollabListForceJoin',
      label: "Ajouter des participants",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        const roomCreatorData = store.getters['ws/roomParticipants'].find(participant => participant.isCreator)

        //Ouverture de la liste seulement si l'on est le créateur de la session
        if (roomCreatorData.id === store.getters['user/userData'].sub) {
          store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_FORCE_JOIN_COLLAB)
        }
      },
      off: async function () {
        const roomCreatorData = store.getters['ws/roomParticipants'].find(participant => participant.isCreator)

        //Ouverture de la liste seulement si l'on est le créateur de la session
        if (roomCreatorData.id === store.getters['user/userData'].sub) {
          store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
        }
      }
    },
    {
      id: 'deconnecter',
      label: "Se déconnecter",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.dispatch('user/logout')
      },
      off: async function () { }
    },
    {
      id: "PrendreLaMain",
      label: "Prendre la main",
      type: menuTypes.TEXT,
      click: () => {
        const roomCreatorData = store.getters['ws/roomParticipants'].find(participant => participant.isCreator)

        //Prise de contrôle de force de l'application si le créateur veut prendre la main
        if (roomCreatorData.id === store.getters['user/userData'].sub) {
          store.dispatch("ws/presenter", { force: true, creatorData: roomCreatorData })
        } else {
          store.dispatch("ws/presenter", {})
        }
      },
    },
    {
      id: "QuitterCollabSession",
      label: "Quitter la session collaborative",
      type: menuTypes.TEXT,
      state: "off",
      click: () => {
        store.dispatch("ws/leaveRoom");
      },
    },
    // {
    //   id: 'ImporterFichier',
    //   label: "Importer un fichier",
    //   type: menuTypes.TEXT,
    //   selected: false,
    //   state: 'off',
    //   click: function () {
    //     const mode = {
    //       type: eyediagMode.mode.UNIVERSAL,
    //       options: {},
    //     };
    //     store.commit(mutationTypes.SET_EYEDIAG_MODE, mode);
    //     router.push({ name: "ImportView" })
    //   }
    // },
    {
      id: 'EchelleW',
      label: "Echelle W",
      type: menuTypes.TEXT,
      state: 'off',
    },
    {
      id: 'EchelleValeurW',
      label: "Echelle Valeur",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.UPDATE_DEBUG}`, {
          ...store.state.circle.debug,
          ...{
            welcoopSevScale: false
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        })
      },
      off: async function () { }
    },
    {
      id: 'EchelleSeverityW',
      label: "Echelle Sévérité",
      type: menuTypes.TEXT,
      state: 'off',
      click: async function () {
        manageSelectOption(this, contextMenuConfig)
        await onOff(contextMenuConfig, this)
      },
      on: async function () {
        store.commit(`circle/${mutationTypes.UPDATE_DEBUG}`, {
          ...store.state.circle.debug,
          ...{
            welcoopSevScale: true
          }
        })
        await store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: store.state.circle.unitPerCircle,
          periodUnit: store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.TEMPORALITY
        })
      },
      off: async function () {
        this.on()
      }
    }
  ]

  return itemsMenu.find(i => i.id === id)
}


export {
  getItemMenu
}