<template>
  <div id="label-sections-container">
    <Transition name="sectionContainer">
      <div v-if="displayLabelSections">
        <TransitionGroup name="sectionItem">
          <div
            v-for="(section) in sectionsToDisplay"
            :key="section.id"
            :style="`left: ${section.x}px;top: ${section.y}px;direction: ${section.direction}`"
            class="absolute w-0 whitespace-nowrap"
          >
            <p
              class="sections"
            >
              {{ section.name }}
            </p>
            <Transition name="pie-extension">
              <PieExtension
                v-if="pieHoveredSections.length > 0 && pieHoveredSections[0].id === section.id"
                :selected-section="section"
              />
            </Transition>
          </div>
        </TransitionGroup>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CircleUtility from "@/libraries/CircleUtility"
import PieExtension from "@/components/pie/PieExtension.vue"

let canvas = null

export default {
  name: "SectionsName",
  components: {
    PieExtension
  },
  computed: {
    ...mapGetters({
      sections: "sections",
      radius: "layout/radius",
      pieHoveredSections: 'pie/pieHoveredSections'
    }),
    /**
     * Indique si les libellés des sections doivent être affichés
     * @type {Number}
     */
    displayLabelSections() {
      return this.$store.state.displayLabelSections;
    },
    sectionsToDisplay() {
      return this.sections.map(d => {
        let spaceToRemove = 0
        const result = {...d}
        const angleInRadians = CircleUtility.degreesToRadians(d.angle)
        const angle = angleInRadians - Math.PI / 2 + CircleUtility.degreesToRadians(d.size) / 2

        result.direction = this.justifie(d.angle)

        if (result.direction === 'center-right') {
          result.direction = 'ltr'
          spaceToRemove = (this.getTextWidth(d.name) / 4) * -1
        } else if (result.direction === 'center-left') {
          result.direction = 'rtl'
          spaceToRemove = this.getTextWidth(d.name) / 4
        }
        result.x = 1.3 * this.radius * Math.cos(angle) + spaceToRemove
        result.y = 1.3 * this.radius * Math.sin(angle)
        return result
      })
    }
  },
  methods: {
    /**
      * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
      * 
      * @param {String} text The text to be rendered.
      * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
      * 
      * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
      */
    getTextWidth(text) {
      if (canvas === null) {
        canvas = document.createElement("canvas")
      }
      const context = canvas.getContext("2d")
      context.font = "normal 1.2rem Helvetica"
      const metrics = context.measureText(text)
      return metrics.width
    },
    /**
     * Détermine la position du texte
     * A gauche, si le libellé est placé sur la portion gauche de la représentation
     * A droite, si le libellé est placé sur la portion droite de la représentation
     * Au milieu, si le libellé est placé sur le milieu de la représentation
     * @method
     * @public
     * @param {Number} cx coordonée x de la position du libellé de section
     */
    justifie(angle) {
      if (angle >= 0 && angle < 30 || angle >= 150 && angle < 180) {
        return "center-right"
      } else if (angle >= 330 || angle >= 180 && angle < 210) {
        return "center-left"
      } else {
        if (angle < 180) {
          return "ltr";
        } else {
          return "rtl";
        }
      }
    },
  },
};
</script>

<style>

#label-sections-container {
  transform-origin: top left;
}

.sections {
  /* opacity: 0.7; */
  font-size: 1.2rem;
  /* opacity: 0.9; */
  font-family: Helvetica;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sectionItem-enter-active,
.sectionItem-leave-active {
  transition: all 0.5s ease;
}
.sectionItem-enter-from,
.sectionItem-leave-to {
  opacity: 0;
}

.sectionContainer-enter-active,
.sectionContainer-leave-active {
  transition: all 0.3s ease;
}
.sectionContainer-enter-from,
.sectionContainer-leave-to {
  opacity: 0;
}

.pie-extension-enter-from, .pie-extension-leave-to {
  opacity: 0;
  transform: translate(0px, 20px);
}

.pie-extension-enter-to, .pie-extension-leave-from {
  opacity: 1;
  transform: translate(0px, 0px);
}

.pie-extension-enter-active, .pie-extension-leave-active {
  transition: all 0.5s ease;
}

</style>