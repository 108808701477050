<template>
  <div>
    <div class="add-event-notes-container flex flex-col border-0 border-dashed">
      <label
        class="mb-2"
      >
        Notes
      </label>
      <textarea
        v-model="note"
        class="grow"
        name="notes"
        rows="5"
        placeholder="Notes..."
        @input="(e) => onInputChange(e, note)"
      />
    </div>
    <div class="mt-5">
      <div class="flex flex-row">
        <label
          class="flex content-center flex-wrap"
        >
          Récurrence
        </label>
        <label class="switch ml-2">
          <input
            v-model="recurrence.enable"
            type="checkbox"
            name="recurrence"
            @click="onRecurrence"
          >
          <span class="slider round" />
        </label>
      </div>
      <div
        v-if="recurrence.enable"
        class="mt-4"
      >
        <div class="flex flex-row">
          <div class="flex flex-col grow basis-0 mr-1.5">
            <label>Début de période</label>
            <input
              v-model="recurrence.start"
              type="date"
              @change="(e) => onInputChange(e, recurrence.start)"
            >
          </div>
          <div class="flex flex-col grow basis-0 ml-1.5">
            <label>Fin de période</label>
            <input
              v-model="recurrence.end"
              type="date"
              @change="(e) => onInputChange(e, recurrence.end)"
            >
          </div>
        </div>
        <p
          v-for="(error, index) in errors.period"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
        <div>
          <div class="mt-1.5 mb-1.5">
            <label>Fréquence</label>
          </div>
          <div class="flex flex-row">
            <p class="flex content-center flex-wrap">
              Tout les
            </p>
            <input
              v-model="recurrence.interval"
              class="mr-1.5 ml-1.5 w-14 border-0 border-b-2 border-solid"
              type="number"
              placeholder="2"
              min="1"
              @input="(e) => onInputChange(e, recurrence.interval)"
            >
            <select
              v-model="recurrence.intervalUnit"
              class="grow"
              @change="e => onInputChange(e, recurrence.intervalUnit, true)"
            >
              <option
                v-for="(intervalUnitOption, index) in recurrence.intervalUnitOptions"
                :key="index"
                :value="intervalUnitOption.value"
              >
                {{ intervalUnitOption.label }}
              </option>
            </select>
          </div>
          <p
            v-for="(error, index) in errors.interval"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </div>
      </div>
      <div class="grid grid-cols-2 mt-5">
        <button
          class="col-start-1"
          @click="onCancel"
        >
          Retour
        </button>
        <button
          class="col-start-2"
          @click="onSaveOptions"
        >
          Enregistrer les options
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapActions } from 'vuex'

export default {
  name: 'AddEventOptions',
  emits: ['save', 'cancel'],
  data: () => ({
    note: "",
    recurrence: {
      enable: false,
      start: null,
      end: null,
      interval: 1,
      intervalUnit: "utcYear",
      intervalUnitOptions: [
        {
          label: "Ans",
          value: "utcYear"
        },
        {
          label: "Mois",
          value: "utcMonth"
        },
        {
          label: "Semaines",
          value: "utcMonday"
        },
        {
          label: "Jours",
          value: "utcDay"
        }
      ]
    },
    errors: {
      period: [],
      interval: []
    }
  }),
  mounted() {
    this.recurrence.start =
    this.recurrence.end = d3.timeFormat('%Y-%m-%d')(new Date())
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    checkForm() {
      this.errors = {
        period: [],
        interval: []
      }

      if (this.recurrence.end < this.recurrence.start) {
        this.errors.period.push('La période n\'est pas valide')
      }
      if (isNaN(this.recurrence.interval) || !this.recurrence.intervalUnitOptions.map(i => i.value).includes(this.recurrence.intervalUnit)) {
        this.errors.interval.push('La fréquence n\'est pas valide')
      }
    },
    resetForm() {
      this.note = "",
      this.recurrence.enable = false
      this.recurrence.start = null
      this.recurrence.end = null
      this.recurrence.interval = 1
      this.recurrence.intervalUnit = 'utcYear'
    },
    editEvent(event) {
      this.note = event.note,
      this.recurrence.enable = false
    },
    async onSaveOptions(event) {
      this.sendEvent({event})
      this.checkForm()

      for (const errors of Object.values(this.errors)) {
        if (errors.length > 0) {
          return
        }
      }

      await this.$emit('save', {
        note: this.note,
        recurrence: {
          enable: this.recurrence.enable,
          start: this.recurrence.start,
          end: this.recurrence.end,
          interval: this.recurrence.interval,
          intervalUnit: this.recurrence.intervalUnit,
        }
      })
      this.collaborativeEventTreated()
    },
    async onCancel(event) {
      this.sendEvent({event})
      await this.$emit('cancel')
      this.collaborativeEventTreated()
    },
    onInputChange(event, text, select = false) {
      if (event.isTrusted) {
        this.sendEvent({
          event: event,
          params: {
            text: text,
            change: select
          }
        })
      } else {
        this.sendEvent({event: event})
        this.collaborativeEventTreated()
      }
    },
    onRecurrence(event) {
      this.sendEvent({event: event})
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
.add-event-notes-container {
  border-color: var(--c-gray-1);
  border-bottom-width: 1px;
}
</style>