<template>
  <GenericModal
    :id="id"
    :title="title"
    :description="description"
    :buttons="buttons"
    theme="success"
    @close="onCancel"
  >
    <div id="modal-collaborative-create-session-container">
      <p v-if="isVisioAvailable">
        Activer Visio
      </p>
      <p v-else>
        Visio non disponible
      </p>
      <label class="switch m-b-10 m-t-5">
        <input
          v-model="enableVisio"
          type="checkbox"
          :disabled="!isVisioAvailable"
        >
        <span class="slider round" />
      </label>
      <VisioChooseDevices
        v-if="enableVisio"
        ref="visio-devices-manager"
      />
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modal/GenericModal.vue'
import { mapGetters } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'
import VisioChooseDevices from '@/components/visio/ChooseDevices.vue'

export default {
  components: {
    GenericModal,
    VisioChooseDevices
  },
  data: () => ({
    /**
     * Titre de la modal
     * @type {String}
     */
    title: 'Créer une session',
    /**
     * Description de la modal
     * @type {String}
     */
    description: '',
    /**
     * Tableau décrivant les boutons présent sur la modal
     * @type {EyeGenericModalButton[]}
     */
    buttons: [],
    /**
     * Identifiant de la modal générique
     * @type {String}
     */
    id: "modal-collaborative-create-session",
    /**
     * Détermine si les options permettant de choisir ses périphériques audio / video pour la visio sont activées
     * @type {Boolean}
     */
    enableVisio: false
  }),
  computed: {
    ...mapGetters({
      isInCollaboration: 'ws/isInCollaboration',
      stateModalSessionCreation: 'ws/stateModalSessionCreation'
    }),
    /**
     * Détermine si les options de visio sont disponibles pour cette instance de l'application
     * @type {Boolean}
     */
    isVisioAvailable() {
      return process.env.VUE_APP_ENABLE_VISIO == "true"
    }
  },
  watch: {
    stateModalSessionCreation() {
      if (this.stateModalSessionCreation) {
        const elem = document.getElementById(this.id)
        if (elem.open === false) {
          elem.showModal()
        }
        
      }
    }
  },
  mounted() {
    this.setButtons()
  },
  methods: {
    /**
     * Cette fonction permet de définir les boutons qui seront présent sur la modal
     */
    setButtons() {
      this.buttons = [
        {
          label: 'Créer',
          backgroundColorTheme: 'success',
          color: 'white',
          onClick: this.onConfirm
        },
        {
          label: 'Annuler',
          backgroundColorTheme: 'error',
          color: 'white',
          onClick: this.onCancel
        }
      ]
    },
    /**
     * Cette fonction est appelé lorsque l'utilisateur appuie sur le bouton de confirmation de la modal. Elle permet la sauvegarde des périphériques audio / video choisi par l'utilisateur et la création de la session collaborative
     */
    onConfirm() {
      const elem = document.getElementById(this.id)
      if (this.enableVisio) {
        this.$refs['visio-devices-manager'].saveDevices()
        this.$refs['visio-devices-manager'].endPreview()
      }
      elem.close()
      this.$store.commit(`ws/${mutationTypes.SET_STATE_MODAL_SESSION_CREATION}`, false)

      if (this.isInCollaboration) {
        this.$store.dispatch("ws/leaveRoom")
      }
      this.$store.dispatch("ws/createRoom")
      this.enableVisio = false
    },
    /**
     * Cette fonction est appelé lorsque l'utilisateur clique sur le bouton annuler ou la croix de la modal. Elle annulé la création de la session collaborative
     */
    onCancel() {
      const elem = document.getElementById(this.id)
      if (this.enableVisio) {
        this.$refs['visio-devices-manager'].endPreview()
      }
      elem.close()
      this.enableVisio = false
      this.$store.commit(`ws/${mutationTypes.SET_STATE_MODAL_SESSION_CREATION}`, false)
    }
  }
}
</script>

<style>

#modal-collaborative-create-session-container {
  padding-bottom: 10px;
}

</style>