<template>
  <div id="disclaimer-container">
    <div class="overflow-scroll" style='word-wrap:break-word'>
      <div class=WordSection1>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal align=center style='text-align:center'><b><span
      style='font-size:12.0pt'>Conditions d’Utilisation Weveyes</span></b></p>

      <p class=MsoNormal style='page-break-after:avoid'><b>&nbsp;</b></p>

      <p class=MsoNormal style='page-break-after:avoid'><b>&nbsp;</b></p>

      <p class=MsoNormal><a name="_Hlk25934305">Toute personne physique ou morale,
      ayant accès ou permettant l’accès à l’application <b>EYEDIAG</b> («&nbsp;l’Application&nbsp;»),
      est tenue de prendre connaissance des présentes Conditions d’Utilisation de l’Application,
      de les respecter et de les faire respecter, sans que cette liste soit
      exhaustive, par ses clients, préposés, affiliés, etc.</a></p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>Le terme utilisateur désigne toute personne physique
      accédant ou permettant l’accès à une autre personne physique à l’Application.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>Pour accéder à l’Application, l’utilisateur doit communiquer
      des informations exactes, notamment son nom, prénom et son adresse e-mail. A
      défaut, l’accès à l’Application peut être refusé ou suspendu.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’utilisateur s’engage à respecter les règles et limitations
      d’utilisation de l’Application figurant dans le présent document et dans la
      documentation de l’Application.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>Toute utilisation ou toute reproduction même partielle de
      l’Application, hors des cas expressément autorisés et des restrictions imposées
      par les présentes Conditions d’Utilisation, est strictement interdite et
      constituerait une contrefaçon passible de poursuites civiles et pénales.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>Aucun droit de propriété intellectuelle sur l’Application ou
      sur des composants tiers n’est cédé ou concédé à l’utilisateur, si ce n’est le
      droit non exclusif d’utiliser l’Application, dans les conditions et limites
      définies ci-après, que l’utilisateur s’engage à respecter.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’utilisateur n’est autorisé à utiliser l’Application qu’à
      des fins strictement professionnelles.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’utilisateur s’engage à ne pas utiliser l’Application dans
      des conditions pouvant porter atteinte aux droits de tiers, notamment à leurs
      droits de propriété intellectuelle ou à leur droit au respect de la vie privée.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal><a name="_Hlk27907104">Il appartient à l’utilisateur de
      s’assurer que son système informatique est adapté à l’utilisation de
      l’Application et notamment qu’il dispose d’un navigateur récent. L’éditeur de
      l’Application ne saurait être tenu responsable des incompatibilités entre le
      système informatique de l’utilisateur et l’Application</a>.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’utilisateur garantit que les données qu’il transmet ou
      importe dans l’Application sont exemptes d’erreur, de virus et de tout élément
      illicite, et qu’elles ne sont pas de nature à porter atteinte à <a
      name="_Hlk27907419">l’éditeur de l’Application</a> ou aux droits des tiers. En
      conséquence, l’utilisateur est seul responsable des dommages que ces données ou
      lui-même pourraient causer à l’éditeur de l’Application ou à un tiers, y
      compris des conséquences de tout recours qui pourrait être exercé par un tiers
      du fait de l’utilisation de l’Application par l’utilisateur.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’utilisateur est informé que l’accès au service suppose une
      connexion préalable au réseau Internet. L’utilisateur fait son affaire, sous sa
      seule responsabilité, de l’acquisition des matériels et logiciels, ainsi que de
      la souscription des abonnements de télécommunication (accès Internet),
      nécessaires à l’utilisation de l’Application.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’utilisateur s’engage à ne pas porter atteinte au bon
      fonctionnement de la plateforme hébergeant l’Application et à respecter les
      droits de propriété intellectuelle de l’éditeur de l’Application et des tiers.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>Un compte utilisateur ne peut être utilisé que par une seule
      personne physique utilisant son identifiant et de son mot de passe, dans le
      cadre d’une procédure d’authentification.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>Les identifiants et mots de passe fournis sont
      confidentiels, uniques et personnels à chacun des utilisateurs. L’utilisateur
      s’engage à assurer la confidentialité et la sécurité du dispositif d’accès de
      façon à empêcher toute utilisation par un tiers. L’utilisateur s’engage à ne
      pas divulguer son identifiant ni son mot de passe. En cas de divulgation
      accidentelle (perte, vol, etc.) ou volontaire, l’utilisateur s’engage à en
      informer sa hiérarchie dès qu’il en aura connaissance et à modifier
      immédiatement son mot de passe et son adresse e-mail. L’authentification d’un
      utilisateur au moyen d’un identifiant et d’un mot de passe emporte, de manière
      irréfragable, imputabilité, au titulaire de l’identifiant utilisé, des
      opérations effectuées au moyen de cet identifiant.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal style='page-break-after:avoid'><b>Gestion de la sécurité</b></p>

      <p class=MsoNormal style='page-break-after:avoid'><b>&nbsp;</b></p>

      <p class=MsoNormal>L’utilisateur s’engage à ne pas contourner ou tenter de
      contourner les systèmes de protection de l’Application.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal style='page-break-after:avoid'><b>Autorisations légales et
      administratives</b></p>

      <p class=MsoNormal style='page-break-after:avoid'><b>&nbsp;</b></p>

      <p class=MsoNormal>L’utilisateur s’engage à respecter, dans le cadre de
      l’utilisation de l’Application, les lois et règlements en vigueur applicables
      selon le lieu d’utilisation de l’Application.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’utilisateur s’engage à respecter l’ensemble des règles
      légales et réglementaires applicables à son activité. Il garantit qu’il dispose
      de l’ensemble des autorisations légales et administratives requises pour
      l’utilisation et la réalisation des traitements réalisés au moyen de
      l’Application.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal style='page-break-after:avoid'><b>Propriété intellectuelle </b></p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’utilisateur s’interdit notamment de procéder à :</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>-      toute
      reproduction, représentation, diffusion ou distribution de tout ou partie de
      l’Application ou de la documentation, que ce soit à titre onéreux ou gracieux ;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>&nbsp;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>-      toute
      forme d’utilisation de l’Application ou de la documentation, de quelque façon
      que ce soit, aux fins de conception, de réalisation, de diffusion ou de
      commercialisation de produits ou services similaires, équivalents ou de
      substitution ou d’une documentation similaire, équivalente ou de substitution ;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>&nbsp;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>-      l’adaptation,
      la modification, la transformation, l’arrangement de l’Application ou de la
      documentation, pour quelque raison que ce soit, y compris pour corriger des
      erreurs ;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>&nbsp;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>-      toute
      transcription, directe ou indirecte, toute traduction dans d’autres langues de
      l’Application ou de la documentation ;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>&nbsp;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>-      toute
      décompilation de l’Application, en dehors des cas expressément prévus par la
      loi ; </p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>&nbsp;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>-      toute
      utilisation pour un traitement non autorisé ;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>&nbsp;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>-      toute
      modification ou contournement des codes de protection tels que, notamment, les
      Identifiants et mots de passe ;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>&nbsp;</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>-      toute
      intrusion ou tentative d’intrusion non autorisée dans l’Application ou la
      plateforme.</p>

      <p class=MsoNormal style='margin-left:21.3pt;text-indent:-21.3pt'>&nbsp;</p>

      <p class=MsoNormal style='page-break-after:avoid'><a name="_Ref21005843"><b>Suspension</b></a></p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’accès d’un utilisateur à l’Application pourra être
      suspendu, totalement ou partiellement, avec effet immédiat, sans information
      préalable et sans indemnisation, si&nbsp;:</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal style='margin-left:18.0pt;text-indent:-18.0pt'><span
      style='font-family:"Times New Roman",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span>des éléments techniques permettent raisonnablement de penser que
      la confidentialité ou la sécurité d’un identifiant ou d’un mot de passe ont été
      violées ou que l’Application est utilisée, ou l’a été, en violation des
      dispositions des présentes Conditions d’Utilisation ;</p>

      <p class=MsoNormal style='margin-left:18.0pt;text-indent:-18.0pt'><span
      style='font-family:"Times New Roman",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span>l’utilisateur a fourni des informations erronées.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>L’utilisateur sera informé par tous moyens de cette
      suspension. La suspension ne libère pas l’utilisateur de ses obligations.</p>

      <p class=MsoNormal><span style='font-size:10.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='page-break-after:avoid'><b>Garanties et Responsabilité</b></p>

      <p class=MsoNormal style='page-break-after:avoid'><b>&nbsp;</b></p>

      <p class=MsoNormal>Il incombe à l’utilisateur de vérifier la conformité de
      l’Application à ses besoins, tant en termes de fonctionnalités que de finalité.
      En conséquence, il n’est fourni aucune garantie de conformité de l’Application
      aux besoins propres de l’utilisateur, ni de garantie que l’Application
      fonctionnera sans interruption, ni qu’elle sera exempte d’anomalies ou
      d’erreurs, ni que de telles anomalies ou erreurs pourront être corrigées.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal>Plus généralement, l’utilisateur ne pourra en aucun cas
      rechercher la responsabilité de l’éditeur de l’Application, celui-ci ne souscrivant
      aucune obligation à l’égard de l’utilisateur.</p>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal><span style='font-size:10.0pt'>&nbsp;</span></p>

      <p class=MsoNormal>&nbsp;</p>

      </div>
    </div>
    <button
      @click="onAgreedDisclaimer"
    >
      Accepter
    </button>
  </div>
</template>

<script>
export default {
  name: 'EyeDisclaimer'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import EyeFetch from '@/libraries/EyeFetch'

const store = useStore()
const router = useRouter()

let disclaimerData = ref(null)

onMounted(() => {
  getDisclaimerData()
})

async function getDisclaimerData() {
  const codeDisclaimer = 'MAIN'
  const searchParams = new URLSearchParams()

  searchParams.append('code', codeDisclaimer)

  let response = await EyeFetch(store, `${process.env.VUE_APP_SERVER_BASE_URL}/user/disclaimer?${searchParams}`, {
    method: 'GET',
    credentials: 'include'
  })
  response = await response.json()
  disclaimerData.value = response.disclaimerData
  console.log('disclaimerData', disclaimerData.value)
}

async function onAgreedDisclaimer() {
  if (disclaimerData.value === null) {
    return
  }

  const body = {
    idDisclaimer: disclaimerData.value.Id
  }

  try {
    await EyeFetch(store, `${process.env.VUE_APP_SERVER_BASE_URL}/user/disclaimer`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body)
    })

    await store.dispatch('user/getUserData')
    router.push({ name: 'Home' })
  } catch (err) {
    console.error(err)
  }
}

</script>

<style>
#disclaimer-container {
  max-height: 100vh;
  margin-inline: 20%;
  overflow: scroll;
}

/* Font Definitions */
@font-face
  {font-family:Courier;
  panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
  {font-family:Wingdings;
  panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
  {font-family:"MS Mincho";
  panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
  {font-family:"Cambria Math";
  panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
  {font-family:Calibri;
  panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
  {font-family:"Arial Unicode MS";
  panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
  {font-family:"Arial Gras";
  panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
  {font-family:Tahoma;
  panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
  {font-family:"\@Arial Unicode MS";
  panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
  {font-family:"\@MS Mincho";
  panose-1:2 2 6 9 4 2 5 8 3 4;}
/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
  {margin:0cm;
  text-align:justify;
  font-size:11.0pt;
  font-family:"Arial",sans-serif;}
.MsoChpDefault
  {font-family:"Cambria",serif;}
.MsoPapDefault
  {margin-bottom:10.0pt;}
/* Page Definitions */
@page WordSection1
  {size:595.3pt 841.9pt;
  margin:76.85pt 63.8pt 2.0cm 70.9pt;}
div.WordSection1
  {page:WordSection1;}
/* List Definitions */
ol
  {margin-bottom:0cm;}
ul
  {margin-bottom:0cm;}
</style>
