<template>
  <div>
    <p class="font-bold">
      {{ `${note.creatorName} ${note.creatorSurname}` }}
    </p>
    <p>
      {{ note.CreationDate.slice(0, 10) }}
    </p>
    <p
      class="mt-1"
      :class="{
        underline: note.State !== null,
        'cursor-pointer': note.State !== null
      }"
      @click="(e) => onApplyState(e, note)"
    >
      {{ note.Note }}
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

export default {
  name: 'PatientNote',
  props: {
    note: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      stateInitCollab: 'stateInitCollab',
      resetState: 'resetState'
    }),
    async onApplyState(event, noteData) {
      if (noteData.state === null) {
        return
      }
      this.sendEvent({event: event})
      // console.log(noteData)
      // const params = new URLSearchParams()
      // params.append('idPatient', this.patientData.id)

      // let response = await EyeFetch(this.$store,
      //   `${process.env.VUE_APP_SERVER_BASE_URL}/patient/note/${noteData.Id}/?${params}`,
      //   {
      //     method: 'GET',
      //     credentials: 'include'
      //   }
      // )

      // if (!response.ok) {
      //   return
      // }
      // response = await response.json()
      await this.resetState()
      await this.stateInitCollab({
        store: noteData.State,
        reload: reloadDataTypes.TEMPORALITY
      })
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
p {
  font-size: 1.2rem;
}
</style>