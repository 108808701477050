import colorThemes from '@/enums/color_themes.js'
import featureGroup from '@/enums/featureGroup.js'
import fontSize from '@/enums/fontSize.js'
import utils from '@/libraries/utils.js'

export default [
  { labelId: 'AfficherMasquerCatLabel', featureGroups: [featureGroup.DISPLAY_LABEL_SECTION], selected: true },
  // { labelId: 'MasquerCatLabel', featureGroups: [featureGroup.DISPLAY_LABEL_SECTION], selected: false },
  {
    labelId: 'TailleMenu',
    selected: false,
    children: [
      { labelId: 'InterfaceTaille1', featureGroups: [featureGroup.DISPLAY_SIZE], selected: !localStorage.getItem('ratioRemPx') || localStorage.getItem('ratioRemPx') == fontSize.SIZE1 },
      { labelId: 'InterfaceTaille2', featureGroups: [featureGroup.DISPLAY_SIZE], selected: localStorage.getItem('ratioRemPx') == fontSize.SIZE2 },
      { labelId: 'InterfaceTaille3', featureGroups: [featureGroup.DISPLAY_SIZE], selected: localStorage.getItem('ratioRemPx') == fontSize.SIZE3 },
    ]
  },
  // {
  //   labelId: 'CouleurRepCercle',
  //   selected: false,
  //   children: [
  //     { labelId: 'CouleurRepCercleOriginal', featureGroups: [featureGroup.REPCOLOR], selected: !localStorage.getItem('repCircleColor') || localStorage.getItem('repCircleColor') === '#95CD41' },
  //     { labelId: 'CouleurRepCercle#A8FF98', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === '#A8FF98' },
  //     { labelId: 'CouleurRepCercleLime', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === '#00FF00' },
  //     { labelId: 'CouleurRepCercleChartreuse', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === '#7FFF00' },
  //     { labelId: 'CouleurRepCercleSpringGreen', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === 'springGreen' },
  //     { labelId: 'CouleurRepCercle#74FF00', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === '#74FF00' },
  //     { labelId: 'CouleurRepCercle#3AFF00', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === '#3AFF00' },
  //     { labelId: 'CouleurRepCercle#00FF23', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === '#00FF23' },
  //     { labelId: 'CouleurRepCercle#7BFF57', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === '#7BFF57' },
  //     { labelId: 'CouleurRepCercle#8EFF70', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === '#8EFF70' },
  //     { labelId: 'CouleurRepCercleBlue', featureGroups: [featureGroup.REPCOLOR], selected: localStorage.getItem('repCircleColor') === '#6495ED' }
  //   ]
  // },
  {
    labelId: 'Theme',
    selected: false,
    children: [
      { labelId: 'ThemeOriginal', featureGroups: [featureGroup.CIRCLE_THEME], selected: !localStorage.getItem('theme') || localStorage.getItem('theme') === colorThemes['ORIGINAL'] },
      // { labelId: 'ThemeVert+', featureGroups: [featureGroup.CIRCLE_THEME], selected: localStorage.getItem('theme') === colorThemes['GREEN+'] },
      // { labelId: 'ThemeBleu', featureGroups: [featureGroup.CIRCLE_THEME], selected: localStorage.getItem('theme') === colorThemes['BLUE'] },
      { labelId: 'ThemeBleu+', featureGroups: [featureGroup.CIRCLE_THEME], selected: localStorage.getItem('theme') === colorThemes['BLUE+'] },
    ]
  },
  {
    labelId: 'ThemeGeneral',
    selected: false,
    children: [
      { labelId: 'ThemeGeneralClair', featureGroups: [featureGroup.APP_THEME], selected: !utils.isInDarkMode() },
      { labelId: 'ThemeGeneralSombre', featureGroups: [featureGroup.APP_THEME], selected: utils.isInDarkMode() },
    ]
  }
]
