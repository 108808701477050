<template>
  <div
    id="list-patient-notes-add"
  >
    <div class="flex flex-row items-end">
      <div
        v-if="displayTooltipNote"
        class="note-tooltip"
      >
        <textarea
          v-model="note"
          rows="5"
          placeholder="Notes..."
          @input="onInputNotes"
        />
        <ToggleSwitch
          text="Joindre la vue"
          @on-off="onToggleJoinStateChange"
        />
        <div class="flex justify-end mt-2">
          <button @click="onSaveNote">
            Enregistrer
          </button>
        </div>
      </div>
      <AddNoteIcon
        width="30px"
        height="30px"
        @click="onClickAddNote"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EyeFetch from '@/libraries/EyeFetch.js'
import ToggleSwitch from '@/components/ToggleSwitch.vue'
import AddNoteIcon from '@/assets/icons/addNote.vue'

export default {
  name: 'AddPatientNotes',
  components: {
    ToggleSwitch,
    AddNoteIcon
  },
  data: () => ({
    displayTooltipNote: false,
    note: '',
    joinState: false
  }),
  computed: {
    ...mapGetters({
      patientData: 'patient/patientData'
    })
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      getState: 'getState',
      getAllPatientNotes: 'patient/getAllPatientNotes'
    }),
    onToggleJoinStateChange(event, status) {
      this.sendEvent({event: event})
      this.joinState = status.value
      this.collaborativeEventTreated()
    },
    onClickAddNote(event) {
      this.sendEvent({event: event})
      this.displayTooltipNote = !this.displayTooltipNote
      this.collaborativeEventTreated()
    },
    async onSaveNote(event) {
      if (event.isTrusted) {
        const state = await this.getState()
        await EyeFetch(this.$store, 
          `${process.env.VUE_APP_SERVER_BASE_URL}/patient/note`,
          {
            method: "POST",
            body: JSON.stringify({
              idPatient: this.patientData.id,
              note: this.note,
              state: (this.joinState) ? JSON.stringify(state) : null
            }),
            credentials: 'include'
          }
        )
      }
      this.sendEvent({event: event})
      this.note = ''
      this.displayTooltipNote = false
      await this.getAllPatientNotes()
      this.collaborativeEventTreated()
    },
    onInputNotes(event) {
      if (event.isTrusted) {
        this.sendEvent({
          event: event,
          params: {
            text: this.note
          }
        })
      } else {
        this.sendEvent({event: event})
      }
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
#list-patient-notes-add {
  font-size: large;
  margin-bottom: 10px;
}

.note-tooltip {
  position: absolute;
  right: 35px;
  background-color: var(--color-bg-1);
  border: solid;
  border-color: var(--color-border);
  border-width: 1px;
  border-radius: 10px;
  padding: 5px;
}

span {
  padding-right: 3px;
}

</style>