<template>
  <div>
    <ContextMenu
      v-if="menuConfig.show"
      :x="menuConfig.x"
      :y="menuConfig.y"
      :items="menuConfig.items"
      :z-index="5"
      @close="onCloseContextMenu"
    />
    <div id="menu-list-top-container-time-filter">
      <IconMenuList
        id="selected-menu-list-time-filter"
        ref="selected-menu-list-time-filter"
      />
    </div>
  </div>
</template>

<script>
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import IconMenuList from '@/assets/icons/menuList.vue'
import ContextMenu from "@/components/ContextMenu.vue"
import * as d3 from 'd3'
import menuTypes from '@/enums/menu_types.js'
import { modifySelection } from '@/config/fonction-menu.js'
import { mapActions, mapGetters } from 'vuex'
import timeFilter from '@/enums/time_filter.js'

export default {
  name: 'ListTimeFilterOptions',
  components: {
    ContextMenu,
    IconMenuList
  },
  data: () => ({
    /**
     * Configuration du menu contextuel s'affichant lors d'un clique droit sur le bouton
     * @type EyeContextMenu
     */
    menuConfig: {
      show: false,
      x: 0,
      y: 0,
      items: []
    }
  }),
  computed: {
    ...mapGetters({
      eventFilters: 'event/common/eventFilters',
      currentDisplayedBorderList: 'borderList/currentDisplayedBorderList'
    })
  },
  watch: {
    eventFilters(_, oldValue) {
      if (this.currentDisplayedBorderList === borderListTypes.LIST_TIME_FILTER && this.eventFilters.time && oldValue.time) {
        modifySelection(this.menuConfig, this.eventFilters.time.unit, oldValue.time.unit)
      }
    }
  },
  mounted() {
    this.initEvents()

    this.menuConfig.items = [
      {
        id: 0,
        label: "Semaine",
        labelId: "WEEK",
        type: menuTypes.TEXT,
        selected: true,
        click: () => {
          this.$store.dispatch('event/common/controlEvent',{isEnabled: true, typeEventslist: borderListTypes.LIST_TIME_FILTER, time: timeFilter.WEEK})
        }
      },
      {
        id: 1,
        label: "Mois",
        labelId: "MONTH",
        type: menuTypes.TEXT,
        selected: false,
        click: () => {
          this.$store.dispatch('event/common/controlEvent',{isEnabled: true, typeEventslist: borderListTypes.LIST_TIME_FILTER, time: timeFilter.MONTH})
        }
      }
    ]
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    /**
     * Permet l'initialisation du panel affichant le filtrage par temps
     * @method
     * @public
     */
    initEvents() {
      d3.select(this.$refs['selected-menu-list-time-filter'].$el)
        .on('click', (event) => {
          this.sendEvent({ event: event })
          event.preventDefault()
          event.stopPropagation()
          this.menuConfig.show = !this.menuConfig.show

          const boundingRect = this.$refs['selected-menu-list-time-filter'].$el.getBoundingClientRect()
          this.menuConfig.x = boundingRect.x - 100
          this.menuConfig.y = boundingRect.y - 47

          this.collaborativeEventTreated()
        })
    },
    onCloseContextMenu() {
      this.menuConfig.show = false
    }
  }
}
</script>