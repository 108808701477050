
/**
 * Lorsque l'utilisateur selectionne une fonctionnalité du menu contextuel, cette fonction permet l'initialisation du on/off sur l'icon de la section dans laquelle se trouve la fonctionnalité
 * @param {Objet} context Context dans lequel la fonction est appelée
 * @param {EyeContextMenuItem[]} contextMenuConfig Il s'agit du tableau parent decrivant le menu contextuel / contenant l'ensemble des fonctionnalités du menu contextuel
 */
export function manageSelectOption(context, contextMenuConfig) {
  const idSection = context.id.split('-')
  if (idSection.length > 0) {
    // "+ number" mean casting an interger to a string value. Il ne faut pas que dans le cas d'1 seule hierarchie executer ce bout de code, car cela va modifier l'objet de la fonctionnalité et non pas l'objet des "icones"
    if (context.on && context.off && context !== contextMenuConfig[+idSection[0]]) {
      contextMenuConfig[+idSection[0]].state = 'on'
      contextMenuConfig[+idSection[0]].selected = true
      contextMenuConfig[+idSection[0]].on = context.on
      contextMenuConfig[+idSection[0]].off = context.off
      contextMenuConfig[+idSection[0]].onOffLabelId = context.labelId
    }
  }
}

/**
 * Cette fonction permet de selectionner et mettre en évidence la fonctionnalité sur laquelle l'utilisateur à cliqué dans le menu contextuel et également désélectionner les fonctionnalités associées (Exemple si l'utilisateur était en filtrage par sévérité 3 puis décide de passer en sévérité 2, le filtrage sévérité 3 doit être déselectionné)
 * @param {Objet} context Context dans lequel la fonction est appelée
 * @param {Number[]} idSection Hiérarchie dans laquelle se trouve la fonctionnalité cliqué par l'utilisateur 
 */
export function selectOption(context, featureGroups) {
  for (const element of context) {
    if (element.children) {
      selectOption(element.children, featureGroups)
    }
    if (featureGroups) {
      for (const element of context) {
        if (element.featureGroups && _.intersection(featureGroups, element.featureGroups).length > 0) {
          element.selected = false
          element.state = 'off'
        }
      }
    }
  }
}

/**
 * Cette fonction permet de désélectionner et ne plus mettre en évidence la fonctionnalité que l'utilisateur avait précédement selectionné dans le menu contextuel
 * @param {Objet} context Context dans lequel la fonction est appelée
 * @param {Number[]} idSection Hiérarchie dans laquelle se trouve la fonctionnalité cliqué par l'utilisateur
 */
export function unselectLightOption(context, idSection) {
  if (idSection.length === 1) {
    context[+idSection[0]].state = 'off'
    context[+idSection[0]].selected = false
  }
  else if (idSection.length > 0) {
    unselectLightOption(context[+idSection[0]].children, idSection.slice(1))
  }
}

/**
 * fonction pour modifier la seleciton dans un menu contextuel
 * @method
 * @public
 * @param {*} menuConfig
 * @param {*} newLabelId
 * @param {*} oldLabelId   
 */
export function modifySelection(menuConfig, newLabelId, oldLabelId) {
  for (const item of menuConfig.items) {
    if (item.labelId === newLabelId) {
      item.selected = true
    } else if (item.labelId === oldLabelId) {
      item.selected = false
    }
  }
}

/**
 * Lors d'un clique sur une fonctionnalité du menu contextuel ou sur une icon, cette fonction permet l'execution de la fonctionnalité "on/off"
 * @param {Objet} context Context dans lequel la fonction est appelée
 */
var tableauGestion = ['Diags_memorises','Semaine','Mois','Top10','Types_event','AfficherNonCl','SauvegarderFiltres','FactAggrCOVID','Euroscore2.2','Desir','SauvegarderFiltres','Findrisc','CHA2']
var bool = false

export async function onOff(contextMenuConfig, context, origin = null) {
  if(origin !== null && bool === true){
    bool = false
  }else{
    const idSection = context.id.split('-')
    if(origin === null && tableauGestion.includes(context.labelId)){
      bool = true
    }
    if (context.state === 'off') {
      selectOption(contextMenuConfig, context.featureGroups)
      context.state = 'on'
      context.selected = true
      if (origin === null) {
        await context.on()
      }
    }else{
      context.state = 'off'
      context.selected = false
      unselectLightOption(contextMenuConfig[+idSection[0]].children, idSection.slice(1))
      if (origin === null) {
        await context.off()
      }
    }
  }
}