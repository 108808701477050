<template>
  <div id="state-manager">
    <div>
      <div
        v-for="(state, index) in states"
        :key="index"
        class="mb-2"
        @click="(e) => onShowState(e, index)"
      >
        <p class="cursor-pointer">
          {{ `Vue ${index}` }}
        </p>
      </div>
    </div>
    <div>
      <AddViewIcon
        width="30px"
        height="30px"
        @click="onSaveState"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import AddViewIcon from '@/assets/icons/addView.vue'

export default {
  name: 'StateManager',
  components: {
    AddViewIcon
  },
  computed: {
    ...mapGetters({
      states: 'stateManager/states'
    })
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      saveState: 'stateManager/saveState',
      showState: 'stateManager/showState'
    }),
    onSaveState(event) {
      this.sendEvent({event: event})
      this.saveState({
        name: 'Test'
      })
      this.collaborativeEventTreated()
    },
    onShowState(event, index) {
      this.sendEvent({event: event})
      this.showState(this.states[index])
      this.collaborativeEventTreated()
    }
  }
}

</script>

<style scoped>

</style>