<template>
  <div
    ref="content"
    :id-section="idSection"
  >
    <div
      v-if="fields.length > 0"
      class="flex flex-row justify-between"
    >
      <div>
        <h3>
          {{ fields[0].title }}
        </h3>
      </div>
      <div class="flex content-center flex-wrap">
        <PadLockIcon
          v-if="isTooltipLocked"
          color="var(--color-primary-accent-opaque)"
          @click="onClickPadLockSection"
        />
      </div>
    </div>
    <div
      :class="{
        'opacity-25': isFieldDisabled
      }"
    >
      <p class="font-medium pb-1 text-base">
        Date
      </p>
      <div class="flex">
        <input
          ref="dateItem"
          v-model="inputDate"
          type="date"
          :disabled="hoveredSection === false"
          class="grow py-1 px-1"
          :class="{
            'text-input-to-be-saved': showElementToBeSaved
          }"
          @change="onInputDate"
          @keyup.enter="onPressEnterField"
          @focus="(e) => onFocusEvent(e, null)"
          @blur="onBlurEvent"
        >
      </div>
    </div>
    <div
      v-for="(field, index) in fields"
      :key="index"
      class="mb-3"
      :class="{
        'opacity-25': isFieldDisabled
      }"
    >  
      <div v-if="checkConditions(field)">
        <div class="flex flex-row">
          <p class="flex content-center flex-wrap font-medium pt-1 text-base">
            {{ field.label }}
          </p>
        </div>
        <div class="flex flex-row items-center">
          <div class="grow">
            <div v-if="field.type === 'radio'">
              <div
                v-for="(choice, i) in field.choices"
                :key="i"
                class="flex mt-2"
              >
                <input
                  v-model="field.value"
                  type="radio"
                  :value="choice.option"
                  :disabled="isFieldDisabled"
                  :id-group="field.idGroup"
                  :id-ttip="field.idData"
                  :name="field.idData"
                  class="mt-0"
                  :class="{
                    'data-entry-panel-input': isRightPanel,
                    'radio-input-to-be-saved': showElementToBeSaved && editedFields[field.idData]
                  }"
                  @change="(e) => onInput(e, field, field.value, true)"
                  @keyup.enter="onPressEnterField"
                  @focus="(e) => onFocusEvent(e, field)"
                  @blur="onBlurEvent"
                >
                <label class="flex flex-wrap content-center">
                  {{ choice.option }}
                </label>
              </div>
            </div>
            <div v-if="field.type === 'check'">
              <div
                v-for="(choice, i) in field.choices"
                :key="i"
                class="flex mt-1"
              >
                <input
                  v-model="field.value"
                  type="checkbox"
                  :value="choice.option"
                  :disabled="isFieldDisabled"
                  :id-group="field.idGroup"
                  :id-ttip="field.idData"
                  :class="{
                    'data-entry-panel-input': isRightPanel,
                    'checkbox-input-to-be-saved': showElementToBeSaved && editedFields[field.idData]
                  }"
                  @click="e => onClickCheckbox(e, field)"
                  @keyup.enter="onPressEnterField"
                  @focus="(e) => onFocusEvent(e, field)"
                  @blur="onBlurEvent"
                >
                <label class="flex flex-wrap content-center">
                  {{ choice.option }}
                </label>
              </div>
            </div>
            <div v-else-if="field.type === 'input'">
              <div
                v-for="(content, i) in field.content"
                :key="i"
                class="flex"
              >
                <input
                  v-if="content.type === 'input'"
                  v-model="content.value"
                  :type="content.typeInput"
                  :disabled="isFieldDisabled"
                  class="grow py-1 px-1"
                  :class="{
                    'data-entry-panel-input': isRightPanel,
                    'text-input-to-be-saved': showElementToBeSaved && editedFields[field.idData]
                  }"
                  :id-group="field.idGroup"
                  :id-ttip="field.idData"
                  step="any"
                  @input="(e) => onInput(e, field, content.value, false)"
                  @keyup.enter="onPressEnterField"
                  @focus="(e) => onFocusEvent(e, field)"
                  @blur="onBlurEvent"
                >
                <span v-if="content.type === 'label'">{{ content.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="idSection === 'EAL' && tooltipData.extension === undefined">
      <input
        ref="bioFile"
        type="file"
        name="file"
        accept=".pdf"
        class="inputfile"
        :disabled="isFieldDisabled"
        @change="(e) => readBioPdf(e, $refs['bioFile'].files[0])"
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'
import EyeFetch from '@/libraries/EyeFetch.js'
import PadLockIcon from '@/assets/icons/padLock.vue'

export default {
  name: 'TooltipForm',
  components: {
    PadLockIcon
  },
  props: {
    idSection: {
      type: String,
      required: true
    },
    tooltipData: {
      type: Object,
      required: true,
    },
    hoveredSection: {
      type: [Boolean, null],
      required: true
    }
  },
  data: () => ({
    fields: [],
    editedFields: {},
    section: null,
    date: null,
    dataForm: null,
    inputDate: null,
    isDateValid: false
  }),
  computed: {
    ...mapGetters({
      reqAbortController: 'reqAbortController',
      wsSocket: 'ws/wsSocket',
      events: "event/common/events",
      hoveredEvent: "event/common/hoveredEvent",
      lockedPie: 'formEntry/lockedPie',
      lockedEvent: 'formEntry/lockedEvent',
      isLockedDataEntry: 'formEntry/isLocked',
      hierarchy: 'hierarchy',
      circles: 'circle/circles',
      isHoveredSaveButton: 'formEntry/isHoveredSaveButton',
      configSaveButton: 'formEntry/configSaveButton'
    }),
    selectedPeriod() {
      return this.$store.state.selectedPeriod
    },
    isRightPanel() {
      return Object.keys(this.tooltipData).length === 0
    },
    isFieldDisabled() {
      return this.hoveredSection === false
    },
    isTooltipLocked() {
      return (this.lockedPie && this.idSection === this.lockedPie.name)
        || (this.lockedEvent && this.lockedEvent.Section === this.idSection)
    },
    showElementToBeSaved() {
      return this.isDateValid
        && this.configSaveButton !== null
        && this.isHoveredSaveButton === true
    }
  },
  watch: {
    selectedPeriod() {
      if (this.selectedPeriod && this.inputDate === null && this.date === null) {
        this.inputDate = this.selectedPeriod.start.slice(0, 10)
      }
      if (this.selectedPeriod) {
        this.fillTooltipFields(true, true)
      }
    },
    events() {
      this.fillTooltipFields(true, true)
    },
    configSaveButton(_, oldConfigSaveButton) {
      if (this.configSaveButton === null && oldConfigSaveButton !== null) {
        this.fillTooltipFields(true, false)
      } else if (oldConfigSaveButton === null && this.configSaveButton !== null) {
        this.fillTooltipFields(false, true)
      }
    }
  },
  mounted() {
    this.getDataForm()
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      changeMainCircle: 'circle/changeMainCircle'
    }),
    setDate(date) {
      this.date = date
      this.inputDate = (this.date || this.selectedPeriod.start).slice(0, 10)
      this.checkDateValidity(false)
    },
    /**
     * Cette fonction permet de parser les éléments du formulaire pour préparer leur mise en page et les remplir si des données sont déjà associés
     * @param {EyeEvent} data Données de l'événement
     */
    parseForm(content, allowEditedData) {
      const isUsingEdited = allowEditedData === true && Object.keys(this.editedFields).length >= 1

      this.fields = []

      if (isUsingEdited) {
        if (this.editedFields.date) {
          this.inputDate = this.editedFields.date
        }
      }

      if (this.dataForm === null) {
        return
      }

      if (this.dataForm.length > 0) {
        this.section = this.dataForm[0].Section
      }

      for (const datum of this.dataForm) {
        let field = null
        datum.Type = datum.Type.replaceAll('"', '')

        switch (datum.Type) {
        case 'in':
          field = this.parseInput(datum)
          break
        case 'check':
          field = this.parseCheck(datum)  
          break
        case 'radio':
          field = this.parseCheck(datum)  
          break
        }
        field.label = datum.Donnee
        field.idData = datum['ID-TTip']
        field.idGroup = datum.IdGroupe
        field.title = datum.title

        this.parseConditions(datum, field)

        //cas de juste la date rentrée ou cas de juste un champs de rentrée
        if (isUsingEdited === true) {
          const label = field.type === 'input' ? 'content' : 'value'
          if (this.editedFields[field.idData] !== undefined) {
            field[label] = this.editedFields[field.idData][label]
          }
        } else if (content.length > 0) {
          let involvedContent = content.filter(c => {
            return c.extension['ID-TTip'] === field.idData
          })
          if (involvedContent.length > 0) {
            if (field.type === 'radio') {
              involvedContent = involvedContent[0]
              field.value = involvedContent.extension.content[0].option
            }
            if (field.type === 'check') { 
              for (const elem of involvedContent) {
                field.value.push(...elem.extension.content.map(c => c.option))
              }
            }
            if (field.type === 'input') {
              involvedContent = involvedContent[0]
              for (let i = 0; i < field.content.length; i++) {
                field.content[i].value = involvedContent.extension.content[i]
              }
            }
          }
        }

        this.fields.push(field)
      }
    },

    parseInput(datum) {
      const typeInput = {
        TEXT: 'text',
        NUM: 'number',
        DATE: 'date',
        PLANCHER: 'number',
        PLAFOND: 'number',
        INTERVAL: 'number'
      }
      const field = {
        type: 'input',
        content: []
      }

      const contentInput = datum.Input

      let sub = contentInput
      let pos = 0
      let nextpos = 0

      while (pos < contentInput.length) {
        if (contentInput[pos] === '<') {
          nextpos = contentInput.indexOf('>', pos)
          sub = contentInput.substring(pos + 1, nextpos).split(':')[0] || 'TEXT'
          field.content.push({type: 'input', typeInput: typeInput[sub], value: ''})
          nextpos += 1
        } else {
          nextpos = contentInput.indexOf('<', pos)
          if (nextpos === -1) {
            nextpos = contentInput.length
          }
          sub = contentInput.substring(pos, nextpos)
          field.content.push({type: 'label', value: sub})
        }
        pos = nextpos
      }

      return field
    },

    parseCheck(datum) {
      const field = {
        type: datum.Type,
        value: datum.Type === 'radio' ? '' : []
      }

      field.choices = JSON.parse(datum.Input)
      return field
    },

    /**
     * Cette fonction est une première approche très simple pour la gestion de champs conditionnel dans les formulaire. Elle ne gère que le cas où le champs doit s'afficher lorsqu'une valeur spécifique apparait dans un champs précédent. Cette implémentation ne permet pas l'affichage conditionnel du champs pour une valeur spécifique rentré dans un champs situé après dans le formulaire.
     * @param {Object} data Données du champs issu de la base de données
     * @param {Object} field Objet final décrivant l'affichage pour permettre à Vuejs l'affichage
     */
    parseConditions(data, field) {
      for (const detail of data.Details) {
        if (detail.type === '<SI>') {
          const targetSrc = this.fields.find(f => f.idData === detail.cond_Code)
          if (targetSrc !== undefined) {
            field.condition = {value: detail.cond_val, target: targetSrc}
          }
          return
        }
      }
    },

    checkConditions(field) {
      if (field.condition === undefined) {
        return true
      }

      switch (field.condition.target.type) {
      case 'radio':
        return field.condition.value === field.condition.target.value
      case 'check':
        return field.condition.target.value.includes(field.condition.value)
      default:
        return false
      }
    },

    async getDataForm() {
      try {
        const params = new URLSearchParams()

        params.append('idSection', this.idSection)
        params.append('idClassification', this.hierarchy.id)

        if (this.tooltipData.content) {
          params.append('idGroup', this.tooltipData.extension.IdGroupe)
        }

        const response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/form/choices?${params}`, {
          method: 'GET',
          credentials: 'include',
        })
        this.dataForm = await response.json()
        //Ouverture avec un click droit il y aura peut-être des données liés
        this.parseForm(this.tooltipData.extension ? this.tooltipData.extension.content : [], false)
      } catch (err) {
        console.error(err)
      }
    },
    onInput(event, field, text, radio) {
      this.sendEvent({
        event: event,
        params: {
          text: text,
          change: radio === true
        }
      })

      if (text === '') {
        delete this.editedFields[field.idData]
      } else {
        this.editedFields[field.idData] = field
      }
      this.computedField()
      this.collaborativeEventTreated()
    },
    onInputDate(event) {
      this.sendEvent({
        event: event,
        params: {
          text: this.inputDate,
          change: true
        }
      })
      this.editedFields.date = this.inputDate
      this.checkDateValidity(true)
      this.collaborativeEventTreated()
    },
    onClickCheckbox(event, field) {
      this.sendEvent({ event: event })
      if (field.value.length > 0) {
        this.editedFields[field.idData] = field
      } else {
        delete this.editedFields[field.idData]
      }
      this.collaborativeEventTreated()
    },
    checkDateValidity(allowMoveCircle) {
      this.isDateValid = false
      const testDate = new Date(this.inputDate)

      if (testDate.toString() !== 'Invalid Date') {
        this.isDateValid = true
        let found = false
        const isoDate = testDate.toISOString()
        for (const circle of this.circles) {
          if (isoDate >= circle.period.start && isoDate < circle.period.end) {
            if (allowMoveCircle) {
              this.changeMainCircle({ selectedCircleData: circle })
            }
            found = true
            break
          }
        }

        if (found === false) {
          this.parseForm([], true)
        }
      }
    },
    computedField() {
      let heightField = null
      let weightField = null
      let imcField = null

      for (const field of this.fields) {
        switch (field.idData) {
        case 'diab10':
          weightField = field
        case 'diab11':
          heightField = field
        case 'diab12':
          imcField = field
        }
      }

      if (heightField && weightField && imcField) {
        const weightValue = weightField.content[0].value
        const heightValue = heightField.content[0].value
        if (heightValue > 0 && weightValue > 0 && isNaN(heightValue) === false && isNaN(weightValue) === false) {
          imcField.content[0].value = (+weightValue / Math.pow((+heightValue / 100), 2)).toFixed(1)
        } else {
          imcField.content[0].value = ''
        }
      }
    },
    setFocusOnFirst(idGroup) {
      let input = null

      //TO-DO Améliorer cette partie avec des conditions un peu lunaire
      if (idGroup !== null) { //Survole d'1 événement sur le cercle
        input = this.$refs.content.querySelector(`input[id-group="${idGroup}"]`)
      }

      if (input === null) { //1 section survolé ou cas par défaut
        input = this.$refs.content.querySelector('input')
      }

      if (input !== null) {
        input.setAttribute('code-focus', true)
        input.focus()
      }
    },
    getBaseTransferObj() {
      return {
        section: this.section,
        date: new Date(this.inputDate).toISOString()
      }
    },
    onPressEnterField(event) {
      this.$store.commit(`formEntry/${mutationTypes.SET_SAVING_FIELDS_EVENT}`, event)
    },
    getDataToSave() {
      const events = []

      for (const [idData, fieldToSave] of Object.entries(this.editedFields)) {
        if (idData === 'date') {
          continue
        }
        const content = []
        const result = {
          ...this.getBaseTransferObj(),
          ...{
            'ID-TTip': fieldToSave.idData,
            content: []
          }
        }

        if (fieldToSave.type === 'radio') {
          const selectedOption = fieldToSave.choices.find(c => c.option === fieldToSave.value)
          if (selectedOption) {
            content.push(selectedOption)
            result.content = content
            events.push(result)
          }
          fieldToSave.value = ''
        } else if (fieldToSave.type === 'check') {
          for (const value of fieldToSave.value) {
            const selectedOption = fieldToSave.choices.find(c => c.option === value)
            if (selectedOption) {
              const tmp = {...result}
              tmp.content = [selectedOption]
              events.push(tmp)
            }
          }
          //les checkbox reste checked, à corriger
          //fieldToSave.value = []
        }
        else {
          let push = false
          for (const c of fieldToSave.content) {
            content.push(c.value)
            if (c.type === 'input' && c.value !== '') {
              c.value = ''
              push = true
            }
          }
          result.content = content
          if (push) {
            events.push(result)
          }
        }
      }
      return events      
    },
    async readBioPdf(event, file) {
      if (event.isTrusted && !file) {
        return
      }

      this.sendEvent({
        event: event,
        params: {
          text: 'bioFile',
          change: true
        }
      })

      if (event.isTrusted) {
        let formData = new FormData()
  
        formData.append("file", file)
        formData.append("params", JSON.stringify({
          mots_clefs: [
            'CHOLESTEROL',
            'TRIGLYCERIDES',
            'LDL CHOLESTEROL',
            'H.D.L',
            'L.D.L'
          ],
          units: ["g/l"]
        }))
  
        try {
          const response = await EyeFetch(this.$store,
            `${process.env.VUE_APP_SERVER_BASE_URL}/patient/diabete/bio`,
            {
              method: "POST",
              credentials: 'include',
              headers: {
                'Content-type': 'multipart/form-data'
              },
              body: formData
            }
          )
          const data = await response.json()
          this.fillResultBio(data)
        } catch (e) {
          console.error('error', e)
        }
        this.collaborativeEventTreated()
      } else {
        this.wsSocket.on('collaborative:diabete:readBio', this.onBioWsData)
      }
    },
    
    onBioWsData(data) {
      this.fillResultBio(data)
      this.wsSocket.off('collaborative:diabete:readBio', this.onBioWsData)
      this.collaborativeEventTreated()
    },

    fillResultBio(data) {
      const results = ['diab23','diab24','diab25','diab27','diab25']

      for (const [i, result] of results.entries()) {
        const field = this.fields.find(f => f.idData === result)

        if (field) {
          if (data[i] !== null) {
            field.content[0].value = data[i][0]
            if (data[i][0] === '') {
              delete this.editedFields[field.idData]
            } else {
              this.editedFields[field.idData] = field
            }
          }
        }
      }
    },

    onFocusEvent(event, d) {
      this.sendEvent({event: event})
      const fieldBox = event.target.getBoundingClientRect()
      this.$store.commit(`formEntry/${mutationTypes.SET_CONFIG_BOX_ARROW}`, {
        idSection: this.idSection,
        top: fieldBox.top
      })
      if (d !== null && this.isRightPanel && this.isLockedDataEntry === false) {
        const isFocusFromCode = event.target.getAttribute('code-focus') === 'true'
        event.target.removeAttribute('code-focus')

        if (isFocusFromCode === false) {          
          // const focusEvent = this.events.find(e => {
          //   return e.circleParent.active && e['ID-TTips'].includes(d.idData)
          // })
  
          // if (focusEvent) {
          //   this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, focusEvent)
          // }
        }
      }
      this.collaborativeEventTreated()
    },

    onBlurEvent(event) {
      if (event.isTrusted) {
        const isBlurFromCode = event.target.getAttribute('code-blur') === 'true'
        event.target.removeAttribute('code-focus')
        if (isBlurFromCode) {
          this.sendEvent({event: event})
          this.$store.commit(`formEntry/${mutationTypes.SET_CONFIG_BOX_ARROW}`, null)
          this.collaborativeEventTreated()
        }
      } else {
        this.sendEvent({event: event})
        this.$store.commit(`formEntry/${mutationTypes.SET_CONFIG_BOX_ARROW}`, null)
        this.collaborativeEventTreated()
      }
    },

    onClickPadLockSection(event) {
      this.sendEvent({ event: event })
      if (this.lockedPie !== null) {
        this.$store.commit(`formEntry/${mutationTypes.SET_LOCKED_PIE_TO_UNLOCK}`, this.lockedPie)
      } else if (this.lockedEvent !== null) {
        this.$store.commit(`formEntry/${mutationTypes.SET_LOCKED_EVENT}`, null)
      }
      this.collaborativeEventTreated()
    },

    /*
      Sauvegarde tmp des infos rentrée par l'utilisateur
        -> Si Survol evenemtn / Pie => Survol prioritaire pour consulter info
        -> Si arret survol / pas de blocage => Affichage données non sauvegardés + Allumer icons fleches rouge
      Survol d'un événement remplir la tooltip
      Tab ou clique Evenemtn survolé mode Edition
      Déplacement depuis la liste allume triangle
    */

    fillTooltipFields(allowDeferment, allowEditedData) {
      const content = []
      let period = null

      if (this.hoveredEvent !== null || this.lockedEvent !== null) {
        const event = this.lockedEvent || this.hoveredEvent
        period = event.circleParent.period
      } else {
        period = this.selectedPeriod
      }
      const highlightedEvents = this.getTooltipEventsSelectedPeriod(this.idSection, period, allowDeferment)
      highlightedEvents.map(event => {
        content.push(...event.extension.content)
      })

      if (content.length > 0) {
        this.setDate(content[0].onsetDateTime)
      } else {
        const currentDate = (new Date()).toISOString()
        if (currentDate >= period.start && currentDate < period.end) {
          this.setDate(currentDate)
        } else {
          this.setDate(period.start)
        }
      }
      this.parseForm(content, allowEditedData)
    },

    getTooltipEventsSelectedPeriod(sectionName, period, allowDeferment) {
      if (period === null) {
        return []
      }
      const startPeriod = new Date(period.start)
      const endPeriod = new Date(period.end)
      const events = this.events.filter(event => {
        const eventDate = new Date(event.onsetDateTime)
        if (allowDeferment === false && event.deferment === true) {
          return false
        }
        return (
          event.extension.dataEntry !== true
          && event.Section === sectionName
          && eventDate >= startPeriod
          && eventDate < endPeriod
        )
      })
      return events
    },

    clearEditedFields() {
      this.editedFields = {}
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.radio-input-to-be-saved {
  accent-color: red;
}

.checkbox-input-to-be-saved {
  accent-color: red;
}

.text-input-to-be-saved {
  /* border: solid 1px red; */
  color: red
}
</style>