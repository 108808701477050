<template>
  <ListBase
    id="unclassified-menu"
    ref="list-base"
  >
    <ul>
      <li
        v-for="(event, index) in unclassified"
        :key="index"
        class="unclassified-event-container"
        @contextmenu="e => onContextMenu(e, event)"
      >
        <p>
          {{ event.resourceType }}
        </p>
        <p>
          {{ event.code }}
        </p>
        <p>{{ event.onsetDateTime?.substring(0, 10) || "Non daté" }}</p>
      </li>
    </ul>
  </ListBase>
</template>

<script>
import * as d3 from 'd3'
import utils from '@/libraries/utils.js'
import * as mutationTypes from "@/store/mutations-types.js"
import { mapActions } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'

export default {
  name: 'ListTimeUnclassified',
  components: {
    ListBase
  },
  emits: ['changeState'],
  computed: {
    unclassified() {
      return this.$store.state.unclassified
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    /**
     * Cette fonction est appelé pour initier le déplacement de la liste / panel (ouverture et fermeture de celui-ci)
     * @param  {...any} params Liste des paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    },
    /**
     * Cette fonction permet l'affichage du détail d'un événement non classé dans une tooltip
     * @param {Event} event Evenement fournit par le listener
     * @param {EyeEvent} d Evenement non classé sur lequel l'utilisateur souhaite afficher le détail
     */
    onContextMenu(event, d) {
      this.sendEvent({ event: event })
      event.preventDefault();
      event.stopPropagation();

      this.$store.commit(`event/common/${mutationTypes.TOOLTIPS_ADD_EVENTS}`, d);
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
.unclassified-event-container {
  padding-inline: 10px;
  padding-bottom: 10px;
}
</style>