import * as d3 from 'd3'
import * as eventsTypes from '@/shared/enums/eventsTypes.js'

/**
 * class Utils
 */

class Utils {
  /**
   * Permet de calculer l'age d'un utilisateur en fonction de sa date de naissance
   * @param {Date} birthday Il s'agit de la date de naissance du patient
   * @returns {Number} Age du patient
   */
  calculateAge(birthday) { // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  /**
   * Lors de certaines transition, il est parfois nécessaire d'opérer un changement de couleur. d3js n'arrive pas à effectuer de lui même une transition de couleur lorsque celle-ci sont définis avec des variable css. Cette fonction permet de retourner l'interpolateur RGB entre les 2 couleurs fournit en paramètre.
   * @param {String} base Couleur de base, elle peut-être une variable CSS
   * @param {String} next Couleur finale, elle peut-être une variable CSS
   * @returns {Function} Interpolateur RGB entre les deux couleurs fournit en paramètre
   */
  getRGBInterpolatorCssVar(base, next) {
    let style = getComputedStyle(document.body)

    //Vérification que la valeur de stroke est bien une variable CSS
    if (base.startsWith('var(') && base.endsWith(')')) {
      //Obtention de la valeur de la variable CSS
      base = style.getPropertyValue(base.slice(4, -1))
    }
    if (next.startsWith('var(') && next.endsWith(')')) {
      next = style.getPropertyValue(next.slice(4, -1))
    }
    
    //Création pour la transition d'un interpolateur RGB définissant le "degradé" de couleur entre la couleur de base qu'avait le cercle et la couleur final qu'il doit avoir
    const interpolator = d3.interpolateRgb(base, next)
    return interpolator
  }

  isInDarkMode() {
    return (localStorage.getItem('mainTheme') !== "light"
      && window.matchMedia
      && window.matchMedia('(prefers-color-scheme: dark)').matches
    ) || localStorage.getItem('mainTheme') === "dark"
  }

  /**
   * Cette fonction permet de calculer la position de la souris à l'intérieur de la cible courante de l'événement. Le point de repère et le coin supérieur gauche de la cible. La position est ensuite transmise aux autres participants de la session collaborative pour vérification de si un scroll est nécessaire de leur côté pour rendre visible la zone survolée par le présentateur
   * @param {Event} event Il s'agit de l'événement fournit par le listener
   */
  sendCheckScroll(event, store) {
    const rect = event.currentTarget.getBoundingClientRect()
    const offsetX = event.clientX - rect.left
    const offsetY = event.clientY + event.currentTarget.scrollTop - rect.top
    store.dispatch('ws/sendEvent', {
      event: {
        type: 'checkScroll',
        currentTarget: event.currentTarget,
        target: event.target
      },
      params: {
        offsetX,
        offsetY
      }
    })
    store.dispatch('ws/collaborativeEventTreated')
  }

  /**
   * Cette fonction permet pour un événement de retourner le type de ressource de cet événement dans un certain format pour le système de déterminant la couleur devant être utilisé à l'affichage en fonction de la nature de l'événement
   * @param {EyeEvent} event Evenement pour lequel le type de ressource pour les couleurs d'affichage doit être retourné
   * @returns {String} event.resourceType
   */
  getEventResourceTypeColor(event, store) {
    if (store.getters.isInNurseEntry && event.Section && event.Section.includes('Traitement')) {
      return 'nurse-entry-trt'
    } else if (eventsTypes[event.resourceType?.toLowerCase()]) {
      return event.resourceType?.toLowerCase()
    } else {
      return 'default'
    }
  }

  differentEventOrManyLocation(base, elem) {
    return (base.id !== elem.id) || (base.id === elem.id && base.parentSection !== elem.parentSection)
  }

  uniqEvent(base, elem) {
    return base.id === elem.id && base.parentSection === elem.parentSection
  }

  searchEventsFromRanks(events, filters) {
    return events.filter(e => {
      for (let i = 0; i < e.ranks.length; i++) {
        const eventMatchScore = e.ranks[i].events.find(filters)
        if (eventMatchScore) {
          return true
        }
      }
      return false
    })
  }

  findEventFromRanks(events, filters) {
    return events.find(e => {
      for (let i = 0; i < e.ranks.length; i++) {
        const eventMatchScore = e.ranks[i].events.find(filters)
        if (eventMatchScore) {
          return true
        }
      }
      return false
    })
  }

  onGlobalAnimationEnd(store, callback) {
    if (store.getters.isLoadingRepresentation === true) {
      const watchEnd = store.watch(() => store.getters.isLoadingRepresentation, (value) => {
        if (value === false) {
          callback()
          watchEnd()
        }
      })
    } else {
      callback()
    }
  }

  onCircleMoveAnimationEnd(store, callback) {
    if (store.getters['circle/isCirclesMoving'] === true) {
      const watchEnd = store.watch(() => store.getters['circle/isCirclesMoving'], (value) => {
        if (value === false) {
          callback()
          watchEnd()
        }
      })
    } else {
      callback()
    }
  }

  downloadObjectAsJson(exportObj, exportName){
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  scrollIntoViewAndWait(element, container) {
    return new Promise(resolve => {
      // if ('onscrollend' in window) {
      //   console.log('Hello')
      //   container.addEventListener('scrollend', () => {
      //     console.log('scorllEnd')
      //     resolve()
      //   }, {once: true})
      //   element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      // } else {
      //   element.scrollIntoView({ block: 'center', inline: 'center' })
      //   resolve()
      // }
      element.scrollIntoView({ block: 'center', inline: 'center' })
      resolve()
    })
  }
}

export default new Utils()