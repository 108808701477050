<template>
  <div
    id="list-events-to-add" 
    ref="list-events-to-add"
  >
    <div class="list-events-to-add-content">
      <ul class="list-none p-0">
        <li
          v-for="(event, index) in eventsToAdd"
          :key="index"
          class="flex flex-row pb-5 justify-between"
        >
          <div
            @click="(e) => onClickEventToAdd(e, event)"
          >
            <p>
              {{ `${(event.code) ? '['+event.code+'] ' : ''}${event.defaultLabel}` }}
            </p>
            <p>{{ event.date?.substring(0, 10) || "Non daté" }}</p>
          </div>
          <CrossButton
            @close="(e) => onDeleteEvent(e, event.id)"
          />
        </li>
      </ul>
      <div
        v-if="eventsToAdd.length > 0"
        class="mt-2 flex flex-col"
      >
        <button @click="saveEvents">
          Enregistrer
        </button>
      </div>
      <div v-else>
        <p
          class="flex flex-row justify-center"
        >
          Aucun événement à enregistrer
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as d3 from 'd3'
import utils from '@/libraries/utils.js'
import D3Animation from '@/config/D3Animation.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import * as mutationTypes from '@/store/mutations-types.js'
import CrossButton from '@/components/Button.vue'
import EyeFetch from '@/libraries/EyeFetch.js'

export default {
  components: {
    CrossButton
  },
  computed: {
    ...mapGetters({
      eventsToAdd: 'event/edit/eventsToAdd',
      unitPerCircle: "circle/unitPerCircle",
      periodUnit: "circle/periodUnit"
    })
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      getDataRepresentation: 'circle/getDataRepresentation',
      deleteUserCache: 'deleteUserCache',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    /**
     * Permet de gérer l'ouverture et la fermeture du conteneur affichant la liste des événements non classés par l'application
     * @method
     * @public
     * @param {Boolean} isDisplayed Détermine si la liste doit être affiché
     */
    movePanel(isDisplayed) {
      const panelTransition = d3.select(this.$refs["list-events-to-add"])
        .transition()
        .duration(D3Animation.LIST_ADD_EVENTS)

      if (isDisplayed) {
        panelTransition
          .style('width', '200px')
      } else {
        panelTransition
          .style('width', '0px')
      }
    },
    /**
     * Permet de contrôler la position de la souris à l'intérieur du conteneur des événements non classés puis de l'envoyer aux autres participants de la session collaborative afin d'engendrer un scroll sur leur affichage si la zone où se trouve la souris du présentateur n'est pas visible
     * @method
     * @public
     */
    initScrollEmitter() {
      d3.select(this.$refs['list-events-to-add'])
        .on('mousemove', (event) => {
          utils.sendCheckScroll(event)
        })
    },
    async saveEvents(event) {
      try {
        if (event.isTrusted) {
          await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/patient/event`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(this.eventsToAdd)
          })
        }
        // Seulement si le présentateur ou l'utilisateur a exécuté l'action
        this.sendEvent({ event: event })
        this.$store.commit(`pie/${mutationTypes.RESET_SELECTED_SECTION}`)
        await this.deleteUserCache()
        this.getDataRepresentation({
          unitPerCircle: this.unitPerCircle,
          periodUnit: this.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
        this.$store.commit(`event/edit/${mutationTypes.RESET_EVENT_TO_ADD}`)
        this.collaborativeEventTreated()
      } catch (err) {
        console.error(err)
      }
    },
    onDeleteEvent(event, id) {
      this.sendEvent({event})
      this.$store.commit(`event/edit/${mutationTypes.REMOVE_EVENT_TO_ADD}`, id)
      this.collaborativeEventTreated()
    },
    onClickEventToAdd(event, eventToEdit) {
      this.sendEvent({event})
      this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_EVENT_TO_ADD}`, eventToEdit)
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
#list-events-to-add {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: var(--color-bg-1);
  border-left: solid var(--color-border);
  border-width: 1px;
  width: 0px;
  overflow: scroll;
  user-select: none;
  color: var(--color-text);
  font-size: 1.2rem;
}

.list-events-to-add-content {
  margin-inline: 5px;
}
</style>
