import { getItemMenu } from "./contextMenuItems.js"
import featureGroup from '@/enums/featureGroup.js'
import sharedMenu from "@/config/shared-menu.js"
import { onOff } from '@/config/fonction-menu.js'

import AppContextChecker from "@/libraries/AppContextChecker.js"

/**
 * Tableau de configuration des fonctionnalités du menu contextuel principale de l'application
 * EyeContextMenuItem[]
 */


const menuStruct = [
  {
    labelId: 'Logo0',
    selected: false,
    children: [
      { labelId: 'Diags_memorises', featureGroups: [featureGroup.FILTERING], selected: true },
      { labelId: 'DP', featureGroups: [featureGroup.FILTERING], selected: false, conditions: AppContextChecker.otherThanPharmacienTypePatient},
      { labelId: 'Sev>=2', featureGroups: [featureGroup.FILTERING], selected: false },
      { labelId: 'Sev>=3', featureGroups: [featureGroup.FILTERING], selected: false },
      { labelId: '5+recents', featureGroups: [featureGroup.FILTERING], selected: false },
      { labelId: 'ATCD>=3', featureGroups: [featureGroup.FILTERING], selected: false },
      { labelId: 'Sev>=3+ATCD>=3', featureGroups: [featureGroup.FILTERING], selected: false },
      {
        labelId: 'Temps',
        selected: false,
        children: [
          { labelId: 'Semaine', featureGroups: [featureGroup.FILTERING, featureGroup.PANEL], selected: false },
          { labelId: 'Mois', featureGroups: [featureGroup.FILTERING, featureGroup.PANEL], selected: false }
        ]
      },
      { labelId: 'Top10', featureGroups: [featureGroup.FILTERING, featureGroup.PANEL], selected: false },
      { labelId: 'AffichageTOUS', featureGroups: [featureGroup.FILTERING], selected: false },
      { labelId: 'Types_event', featureGroups: [featureGroup.PANEL], selected: false, conditions: AppContextChecker.otherThanPharmacienTypePatient },
      {
        labelId: 'Mode',
        selected: false,
        children: [
          { labelId: 'DossierPatient', featureGroups: [featureGroup.MODE], selected: false },
          {
            labelId: 'Populationnel',
            selected: false,
            featureGroups: [featureGroup.MODE],
            children: [
              { labelId: 'PopRegroupement/section', featureGroups: [featureGroup.POPMERGE], selected: false },
              { labelId: 'PopRegroupement/code+sev', featureGroups: [featureGroup.POPMERGE], selected: false },
              { labelId: 'PopRegroupement/code+sev_stat', featureGroups: [featureGroup.POPMERGE], selected: false },
              { labelId: 'PopRegroupement/code+sev/podium', featureGroups: [featureGroup.POPMERGE], selected: false },
              { labelId: 'PopRegroupement/code+sev_stat/podium', featureGroups: [featureGroup.POPMERGE], selected: false },
              { labelId: 'PopRegroupement/code+sev/podiumM/cercle', featureGroups: [featureGroup.POPMERGE], selected: false },
              { labelId: 'PopRegroupement/code+sev_stat/podium/cercle', featureGroups: [featureGroup.POPMERGE], selected: false },
              { labelId: 'Echelle',
                selected: false,
                featureGroups: [featureGroup.MODE],
                children: [
                  {labelId: 'Echelle/lineaire', featureGroups: [featureGroup.LADDER], selected: false },
                  {labelId: 'Echelle/racine-carre', featureGroups: [featureGroup.LADDER], selected: false },
                  {labelId: 'Echelle/algo-Jenks', featureGroups: [featureGroup.LADDER], selected: false }
                ]
              }
            ]
          }
        ],
        conditions: AppContextChecker.otherThanPharmacienTypePatient
      },
      { labelId: 'LiensCR', selected: false, conditions: AppContextChecker.otherThanPharmacienTypePatient },
      // { labelId: 'carnetvaccin', selected: false },
      { labelId: 'SauvegarderFiltres', featureGroups: [featureGroup.PANEL], selected: false }
    ]
  },
  {
    labelId: 'HistoryIcon',
    selected: false,
    children: [
      { labelId: 'TempsOP', featureGroups: [featureGroup.TEMPORALITY], selected: false },
      { labelId: 'MoisTemp', featureGroups: [featureGroup.TEMPORALITY], selected: false },
      { labelId: 'Trimestre', featureGroups: [featureGroup.TEMPORALITY], selected: false },
      { labelId: 'Semestre', featureGroups: [featureGroup.TEMPORALITY], selected: false },
      { labelId: '1 an', featureGroups: [featureGroup.TEMPORALITY], selected: true },
      { labelId: '2 ans', featureGroups: [featureGroup.TEMPORALITY], selected: false },
      { labelId: '3 ans', featureGroups: [featureGroup.TEMPORALITY], selected: false },
      { labelId: '4 ans', featureGroups: [featureGroup.TEMPORALITY], selected: false }
    ]
  },
  {
    labelId: 'Logo2',
    selected: false,
    children: [
      // { labelId: 'CustomHierarchy', selected: false },
      { labelId: 'Diabetologie', featureGroups: [featureGroup.CLASSIFICATION], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'DiabetologieNurseEntry', featureGroups: [featureGroup.CLASSIFICATION], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'DiabetologieNurseEntryTrt', featureGroups: [featureGroup.CLASSIFICATION], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'HierarchyGHM', featureGroups: [featureGroup.CLASSIFICATION], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'HierarchyUM', featureGroups: [featureGroup.CLASSIFICATION], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'TESTW', featureGroups: [featureGroup.CLASSIFICATION], selected: false, conditions: AppContextChecker.isInPharmacienTypePatient },
      // { labelId: 'TESTW2', featureGroups: [featureGroup.CLASSIFICATION], selected: false, conditions: AppContextChecker.isInPharmacienTypePatient },
      { labelId: 'TESTW3', featureGroups: [featureGroup.CLASSIFICATION], selected: false, conditions: AppContextChecker.isInPharmacienTypePatient },
      { labelId: 'QOR', featureGroups: [featureGroup.CLASSIFICATION], selected: false, conditions: AppContextChecker.isInQOR },
      // {
      //   labelId: 'EchelleW',
      //   selected: false,
      //   children: [
      //     { labelId: 'EchelleValeurW', featureGroups: [featureGroup.ECHELLEW], selected: false },
      //     { labelId: 'EchelleSeverityW', featureGroups: [featureGroup.ECHELLEW], selected: false },
      //   ],
      //   conditions: AppContextChecker.isInPharmacienTypePatient
      // },
      {
        labelId: 'Tooltips',
        selected: false,
        children: [
          { labelId: 'TooltipsOnOff', selected: false },
          { labelId: 'TooltipsReorg', selected: false },
          { labelId: 'TooltipsSupp', selected: false }
        ]
      },
      ...sharedMenu,
      { labelId: 'Centrer_representation', selected: false }
    ]
  },
  {
    labelId: 'Logo3',
    selected: false,
    children: [
      {
        labelId: 'FiltrePatient',
        selected: false,
        children: [
          { labelId: 'AucunFiltrePatient', featureGroups: [featureGroup.PATIENT_FILTERING], selected: true },
          { labelId: 'CatFiltrePatient', featureGroups: [featureGroup.PATIENT_FILTERING], selected: false },
          { labelId: 'CorresFiltrePatient', featureGroups: [featureGroup.PATIENT_FILTERING], selected: false }
        ],
        conditions: AppContextChecker.otherThanPharmacienTypePatient
      },
      { labelId: 'FactAggrCOVID', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'Euroscore2.2', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'ATCDfam', selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'CHA2', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'HasBled', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'Desir', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'Findrisc', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInGeneralisteTypePatient },
      { labelId: 'ScoreWelcoop', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInPharmacienTypePatient },
      { labelId: 'ScoreQor0', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInQOR },
      { labelId: 'ScoreQor1', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInQOR },
      { labelId: 'ScoreQor2', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInQOR },
      { labelId: 'ScoreQor3', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInQOR },
      { labelId: 'ScoreQor4', featureGroups: [featureGroup.SCORE], selected: false, conditions: AppContextChecker.isInQOR },
      {
        labelId: 'Emplacement',
        selected: false,
        children: [
          { labelId: 'Emplacement1.012', featureGroups: [featureGroup.SCORE_POSITION], selected: false },
          { labelId: 'Emplacement1.03', featureGroups: [featureGroup.SCORE_POSITION], selected: false },
          { labelId: 'Emplacement1.066', featureGroups: [featureGroup.SCORE_POSITION], selected: false },
          { labelId: 'Emplacement1.1', featureGroups: [featureGroup.SCORE_POSITION], selected: false },
          { labelId: 'Emplacement1.15', featureGroups: [featureGroup.SCORE_POSITION], selected: false },
          { labelId: 'Emplacement1.2', featureGroups: [featureGroup.SCORE_POSITION], selected: false },
          { labelId: 'Emplacement1.3', featureGroups: [featureGroup.SCORE_POSITION], selected: false },
          { labelId: 'Emplacement1.4', featureGroups: [featureGroup.SCORE_POSITION], selected: false  }
        ],
        conditions: AppContextChecker.otherThanPharmacienTypePatient
      },
    ]
  },
  {
    labelId: 'Logo4',
    selected: false,
    children: [
      { labelId: 'RetourAccueil', selected: false },
      { labelId: 'AfficherNonCl', featureGroups: [featureGroup.PANEL], selected: false, conditions: AppContextChecker.otherThanPharmacienTypePatient },
      // { labelId: 'ImporterFichier', selected: false },
      { labelId: 'ModeEdition', selected: false, conditions: AppContextChecker.otherThanPharmacienTypePatient },
      {
        labelId: 'Collaboration',
        selected: false,
        children: [
          { labelId: 'CollabCreer', selected: false },
          { labelId: 'CollabJoindre', selected: false },
          { labelId: 'CollabQuit', selected: false },
          { labelId: 'CollabCouperVisio', selected: false }, //conditions: AppContextChecker.isAdmin },
          { labelId: 'CollabListForceJoin', selected: false } //conditions: AppContextChecker.isAdmin }
        ]
      },
      { labelId: 'deconnecter', selected: false }
    ]
  }
]

//gestion de l'état des listes présente dans la borderlist du caroussel comprend la fonciton waitFunction et findObjectFound

let contextMenuConfig = []
const origin = "watcher"

/***
 * @function
 * @name initContextMenuWatchers
 * @description
 * Cette fonction permet d'utiliser les "watcher" qui gère la gestion de l'état du menu contextuel pour l'ensemble des 
 * paramètre ui peuvent être changerdepuis les borderlist
 */

var list = ["LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY","LIST_CHOOSE_TYPE_EVENTS_DISPLAY"]

function initContextMenu(store) {
  initContextMenuWatchers(store)
  fillMenu(menuStruct, contextMenuConfig, contextMenuConfig, store)
}

function initContextMenuWatchers(store) {
  /**
   * gère la gestion du changement de l'état du filtrage par temps (semaine ou mois) depuis la borderlst LIST_TIME_FILTER
   */
  store.watch(() => store.getters['event/common/eventFilters'], (newValue,oldValue)=>{
    if (newValue.time !== undefined && oldValue.time !== undefined && newValue.time.unit !== oldValue.time.unit) {
      findObjectList(contextMenuConfig, newValue.time.unit === 'WEEK'
        ? 'Semaine'
        : 'Mois'
      )
    }
  })

  /***
   * watcher pour le changement de la borderlist active, permet la gestion de l'état de l'affichage dans le menu contextuel
   */
  store.watch(() => store.getters['borderList/currentDisplayedBorderList'], (newValue, oldValue) => {
    switch(newValue ? newValue : oldValue){
    case 'LIST_MEMORIZED_EVENTS':
      if(list.includes(oldValue)){
        oldValue === 'LIST_CHOOSE_TYPE_EVENTS_DISPLAY' ? findObjectList(contextMenuConfig,'Types_event') : findObjectList(contextMenuConfig,'SauvegarderFiltres')
      }else{
        findObjectList(contextMenuConfig,'Diags_memorises')
      }
      break;
    case 'LIST_TIME_FILTER':
      findObjectList(contextMenuConfig,'Semaine')
      break;
    case 'LIST_CHOOSE_TYPE_EVENTS_DISPLAY':
      findObjectList(contextMenuConfig,'Types_event')
      break;
    case 'LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY':
      findObjectList(contextMenuConfig,'SauvegarderFiltres')
      break;
    case 'LIST_UNCLASSIFIED':
      findObjectList(contextMenuConfig,'AfficherNonCl')
      break;
    case 'LIST_TOP_10':
      findObjectList(contextMenuConfig,'Top10')
      break;
    default:
      break;
    }
  })

  store.watch(() => store.getters['refCircle/score/idScore'], (newValue,oldValue) => {
    switch(newValue ? newValue : oldValue){
    case 1 :
      findObjectList(contextMenuConfig,'FactAggrCOVID')
      break;
    case 4 :
      findObjectList(contextMenuConfig,'Euroscore2.2')
      break;
    case 6 :
      findObjectList(contextMenuConfig,'Desir')
      break;
    case 4 :
      findObjectList(contextMenuConfig,'SauvegarderFiltres')
      break;
    case 7 :
      findObjectList(contextMenuConfig,'Findrisc')
      break;
    case 3 :
      findObjectList(contextMenuConfig,'CHA2')
      break;
    default:
      break;
    }
  })

  store.watch(() => store.getters['user/typePatientContext'], () => {
    contextMenuConfig.length = 0
    fillMenu(menuStruct, contextMenuConfig, contextMenuConfig, store)
  })
}

/**
 * @function
 * @name findObjectFound
 * @param {*} contextMenuConfigObject 
 * @param {*} labelId
 * @decription Cette fonction permet de retrouver la liste selectionner du 
 * caroussel dans la menu contextuel et de ce fait de pouvoir gérer 
 * l'état de la selection de celle-ci dans le menu contextuel 
 */

async function findObjectList(contextMenuConfigObject, labelId){
  for(const element in contextMenuConfigObject){
    if(typeof contextMenuConfigObject[element] === 'object'){
      findObjectList(contextMenuConfigObject[element], labelId)
    }else{
      if (element === 'labelId' && contextMenuConfigObject[element] === labelId) {
        await onOff(contextMenuConfig, contextMenuConfigObject, origin)
      }
    }
  }
}

/**
 * @function
 * @name fillMenu
 * @param {*} menuItems
 * @param {*} dest
 * @param {*} entryConfig
 * @param {*} hierarchy
 */

function fillMenu(menuItems, dest, entryConfig, store, hierarchy = '') {
  let index = 0
  for (const item of menuItems) {
    if (item.conditions !== undefined && item.conditions(store) === false) {
      continue
    }

    const fillItem = getItemMenu(entryConfig, item.labelId)
    fillItem.id = `${hierarchy}${index}`
    fillItem.selected = item.selected
    fillItem.labelId = item.labelId
    if (item.selected) {
      fillItem.state = 'on'
    }
    if (item.hasOwnProperty('featureGroups')) {
      fillItem.featureGroups = item.featureGroups
    }
    if (item.hasOwnProperty('children')) {
      fillItem.children = []
      fillMenu(item.children, fillItem.children, entryConfig, store, `${hierarchy}${index}-`)
    }
    //Affectation de la fonctionnalité on/off sur le logo d'une section du menu
    if (item.hasOwnProperty('onOffLabelId')) {
      const onOffRef = getItemMenu(entryConfig, item.onOffLabelId)
      fillItem.on = onOffRef.on
      fillItem.off = onOffRef.off
    }

    dest.push(fillItem)
    index++
  }
}

/**
 * @function
 * @name exportContextMenuStruct
 * @param {*} contextMenu 
 * @param {*} exportStruct 
 * @returns 
 */

function exportContextMenuStruct(contextMenu, exportStruct = []) {
  for (const menu of contextMenu) {
    const entry = {
      labelId: menu.labelId,
      selected: menu.selected
    }
    if (menu.hasOwnProperty('featureGroups')) {
      entry.featureGroups = menu.featureGroups
    }
    if (menu.hasOwnProperty('onOffLabelId')) {
      entry.onOffLabelId = menu.onOffLabelId
    }
    if (menu.hasOwnProperty('children')) {
      entry.children = []
      exportContextMenuStruct(menu.children, entry.children)
    }
    exportStruct.push(entry)
  }
  return exportStruct
}

/**
 * @function
 * @name resetContextMenu
 * @param {*} struct 
 */

function resetContextMenu(store, struct = menuStruct) {
  contextMenuConfig.length = 0
  fillMenu(struct, contextMenuConfig, contextMenuConfig, store)
}

export default contextMenuConfig
export {
  fillMenu,
  resetContextMenu,
  exportContextMenuStruct,
  initContextMenu
}