<template>
  <svg
    width="28" 
    height="24"
  >
    <polyline
      points="2.5 5 5 2.5 15 2.5 17.5 5 17.5 15 15 17.5 5 17.5 2.5 15 2.5 5"
      stroke="var(--color-text)"
      stroke-width="1"
      stroke-linecap="round"
      fill="none"
      stroke-linejoin="round" 
    />
    <line
      x1="5"
      x2="15"
      y1="7.5"
      y2="7.5"
      stroke="var(--color-text)"
      stroke-linecap="round"
      stroke-width="1" 
    />
    <line
      x1="5"
      x2="15"
      y1="12.5"
      y2="12.5"
      stroke="var(--color-text)"
      stroke-linecap="round"
      stroke-width="1" 
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMenuList'
}
</script>