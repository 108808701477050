<template>
  <div id="record-progress-bar">
    <div
      ref="progressBarPlaceHolder"
      class="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700"
      @click="onClickProgressBar"
    >
      <div
        class="bg-green-600 h-2.5 rounded-full dark:bg-green-500"
        e2e="replayProgressBar"
        :style="`width: ${progressBarWidth}%`"
      />
    </div>
    <div class="flex justify-between">
      <p>{{ secondsElapsed }}</p>
      <p>{{ maxSeconds }}</p>
    </div>
  </div>
</template>

<script setup>
import {useStore} from 'vuex'
import {ref, computed, watch} from 'vue'
import * as mutationTypes from '@/store/mutations-types.js'

const store = useStore()
const progressBarWidth = ref(0)
const progressBarPlaceHolder = ref(null)

const secondsElapsed = computed(() => store.getters['record/secondsElapsed'])
const maxSeconds = computed(() => store.getters['record/replayDuration'])

watch(secondsElapsed, () => {
  setProgressBarAt(secondsElapsed.value)
})

function onClickProgressBar(event) {
  const width = progressBarPlaceHolder.value.offsetWidth
  const percentage = event.offsetX / width
  let targetSeconds = maxSeconds.value * percentage
  targetSeconds = Math.trunc(targetSeconds / 10) * 10
  store.commit(`record/${mutationTypes.SET_RECORD_SECONDS_ELAPSED}`, targetSeconds)
  store.dispatch('record/getStateAtTime', {seconds: targetSeconds})
}

function setProgressBarAt(seconds) {
  progressBarWidth.value = seconds / maxSeconds.value * 100
}
</script>