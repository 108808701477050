<template>
  <ListBase
    id="list-patient-notes"
    ref="list-base"
  >
    <ul>
      <li
        v-for="(note, index) in patientNotes"
        :key="index"
        class="mb-5"
      >
        <PatientNote :note="note" />
      </li>
    </ul>
  </ListBase>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'
import PatientNote from '@/components/patientNotes/patientNote.vue'
import borderListTypes from '@/enums/borderList/borderListTypes.js'

export default {
  name: 'ListPatientNotes',
  components: {
    ListBase,
    PatientNote
  },
  computed: {
    ...mapGetters({
      patientData: 'patient/patientData',
      patientNotes: 'patient/patientNotes',
      currentDisplayedBorderList: 'borderList/currentDisplayedBorderList'
    })
  },
  watch: {
    currentDisplayedBorderList() {
      if (this.currentDisplayedBorderList === borderListTypes.LIST_PATIENT_NOTES) {
        this.getAllPatientNotes()
      }
    }
  },
  methods: {
    ...mapActions({
      getAllPatientNotes: 'patient/getAllPatientNotes'
    }),
    /**
     * Cette fonction est appelé pour initier le déplacement de la liste / panel (ouverture et fermeture de celui-ci)
     * @param  {...any} params Liste des paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    }
  }
}
</script>