import circle from '@/store/modules/circle.js'
import event from '@/store/modules/event/event.js'
import layout from '@/store/modules/layout.js'
import ws from '@/store/modules/ws.js'
import patient from '@/store/modules/patient.js'
import user from '@/store/modules/user.js'
import assembly from '@/store/modules/assembly.js'
import refCircle from '@/store/modules/refCircle/refCircle.js'
import search from '@/store/modules/search.js'
import formEntry from '@/store/modules/formEntry.js'
import stateManager from '@/store/modules/stateManager.js'
import pie from '@/store/modules/pie.js'
import medical from '@/store/modules/medical/medical.js'
import record from '@/store/modules/record.js'

import e2eTesting from '@/store/modules/e2eTesting.js'
import proportion from '@/libraries/Proportion.js'

import { createStore } from 'vuex'
import * as mutationTypes from './mutations-types.js'
import _ from 'lodash'

import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import * as hierarchy from '@/shared/enums/hierarchy.js'

import * as eventsTypes from '@/shared/enums/eventsTypes.js'
import BorderListTypes from '@/enums/borderList/borderListTypes.js'

import EyeFetch from '@/libraries/EyeFetch.js'
import borderList from '@/store/modules/borderList/BorderList.js'

const getDefaultState = () => {
  return {
    /**
     * Il s'agit de la périod sur laquelle se trouve le cercle principal
     * @type {EyePeriod}
     */
    selectedPeriod: null,
    /**
     * Il s'agit de l'historique des sections selectionnés par l'utilisateur lors de recatégorisation
     * @type {Int[][]}
     */
    historySections: [{asSection: null, sections: [], hierarchy: {
      type: hierarchy.type.SYSTEM,
      id: hierarchy.hierarchy.DEFAULT
    }}],
    /**
     * Il s'agit des sections actuellement affichés sur la représentation
     * @type {EyeSection[]}
     */
    sections: [],
    /**
     * Détermine si la représentation à finit de charger après un appel au serveur pour l'obtention de nouvelles données de cercle
     * @type {Boolean}
     */
    loadingRepresentation: false,
    /**
     * Détermine si les libellés de section doivent être affichés
     * @type {Boolean}
     */
    displayLabelSections: true,
    /**
     * Détermine si le menu affichant la liste des précédentes séléctions de sections doit être affiché
     * @type {Boolean}
     */
    displayHistorySections: false,
    /**
     * Index dans l'historique des selections de sections sur lequel l'utilisateur est positionné
     * @type {Number}
     */
    indexHistorySection: 0,
    /**
     * Coordonnées du coin supérieur gauche du menu d'historique des selections de sections
     * @type {EyeCoordinates}
     */
    historySectionMenuPos: { x: 0, y: 0 },
    /**
     * Objet de configuration déterminant les types d'événement devant être affiché sur la représentation
     * @type {EyeDisplayedEvents}
     */
    displayedEventsTypes: {
      procedure: {
        types: [
          {
            type: eventsTypes.procedure.ALL
          }
        ]
      },
      immunization: {
        types: [
          {
            type: eventsTypes.immunization.ALL
          }
        ]
      },
      condition: {
        types: [
          {
            type: eventsTypes.condition.ALL
          }
        ]
      },
      allergy: {
        types: [
          {
            type: eventsTypes.allergy.ALL
          }
        ]
      },
    },
    /**
     * Détermine le mode dans lequel se trouve l'application
     * @type {Object} type, options
     */
    mode:  {
      type: eyediagMode.mode.PATIENT,
      options: {}
    },
    previousMode: [],
    hierarchy: {
      type: hierarchy.type.SYSTEM,
      id: hierarchy.hierarchy.DEFAULT
    },
    /**
     * Détermine si le mode édition est activé
     * @type {Boolean}
     */
    isEditMode: false,
    /**
     * Libellé de filtrage dans laquel l'application se trouve
     * @type {String}
     */
    labelFilters: "Diag Mémorisés",
    /**
     * Ratio rem / px pour l'unité rem
     * @type {Number}
     */
    ratioRemPx: localStorage.getItem("ratioRemPx") ? localStorage.getItem('ratioRemPx') : 62.5,
    /**
     * Podium des sections contenant les événements du top3 du classement des événements les plus présents. Cette varaible est utilisé en mode collaboratif pour mettre en couleur ces sections
     * @type {Number[]}
     */
    eventsPodium: [],
    unclassified: [],
    /**
     * Variable qui prend comme valeur la columns du fichier .csv/.xslx d'où on extrait les sections
     * @type {String[]}
     */
    asSection: null,
    isChoseSectionModalOpen: false,
    columnsFile: null,
    /**
     * Définit le type et les potentiels paramètres lorsqu'une erreur globale est levée dans l'application
     * @type {Object}
     */
    error: {
      type: null,
      params: {}
    },
    reqAbortController: new AbortController(),
    /**
     * Données supplémentaires qui changent en fonction du mode / hierarchie
     * @type {Object}
     */
    dataExtension: {}
  }
}

const Store = createStore({
  strict: false,
  modules: {
    borderList: borderList,
    circle: circle,
    event: event,
    layout: layout,
    ws: ws,
    patient: patient,
    user: user,
    assembly: assembly,
    refCircle: refCircle,
    search: search,
    formEntry: formEntry,
    stateManager: stateManager,
    pie: pie,
    medical: medical,
    record: record,
    e2eTesting: e2eTesting
  },
  state: getDefaultState(),
  getters: {
    selectedPeriodLabel: state => (state.selectedPeriod) ? state.selectedPeriod.label : '',
    sections: state => state.sections,
    isLoadingRepresentation: state => state.loadingRepresentation,
    displayLabelSections: state => state.displayLabelSections,
    isEditMode: state => state.isEditMode,
    labelFilters: state => state.labelFilters,
    ratioRemPx: state => state.ratioRemPx,
    eyediagMode: state => state.mode,
    mode: state => state.mode,
    isChoseSectionModalOpen: state => state.isChoseSectionModalOpen,
    columnsFile: state => state.columnsFile,
    asSection: state => state.asSection,
    historySections: state =>  state.historySections,
    numberOfSelectedSections: state => state.historySections[state.indexHistorySection].sections.length,
    eventsPodium: state => state.eventsPodium,
    error: state => state.error,
    reqAbortController: state => state.reqAbortController,
    hierarchy: state => state.hierarchy,
    isInPatient: state => state.mode.type === eyediagMode.mode.PATIENT && state.mode.options.cohortBase !== true,
    isInPopulational: state => state.mode.type === eyediagMode.mode.POPULATIONAL || (state.mode.type === eyediagMode.mode.PATIENT && state.mode.options.cohortBase === true),
    isInNurseEntry: state => state.hierarchy.type === hierarchy.type.SYSTEM && [hierarchy.hierarchy.DIABETOLOGIE_NURSE_ENTRY, hierarchy.hierarchy.DIABETOLOGIE_NURSE_ENTRY_TRAITEMENT].includes(state.hierarchy.id),
    dataExtension: state => state.dataExtension
  },
  mutations: {
    /**
     * Met à jour les sections acutellement représentées
     * @param {EyeSection[]} payload Liste des sections représentées sur l'application
     * @method
     * @public
     */
    [mutationTypes.UPDATE_SECTIONS](state, payload) {
      const sections = payload

      sections.forEach(section => section.selectedByPie = false)
      state.sections = sections
    },
    [mutationTypes.OPEN_COLUMNS_MODAL](state, payload) {
      state.isChoseSectionModalOpen = payload
    },
    /**
     * Définit la période temporelle actuellement selectionné par l'utilisateur
     * @param {EyePeriod} payload Période temporelle selectionné par l'utilisateur
     * @method
     * @public
     */
    [mutationTypes.SET_SELECTED_PERIOD](state, payload) {
      state.selectedPeriod = payload
    },
    /**
     * Définit si la représentation est en cours de chargement
     * @method
     * @public
     */
    [mutationTypes.SET_LOADING_REPRESENTATION](state, payload) {
      state.loadingRepresentation = payload
    },
    /**
     * Définit l'index de la selection sur lequel l'utilisateur se trouve dans l'historique des selections de sections
     * @method
     * @public
     */
    [mutationTypes.SET_INDEX_HISTORY_SECTIONS](state, payload) {
      state.indexHistorySection = payload
    },
    /**
     * Définit si les libellés des sections doivent être affiché à l'utilisateur
     * @method
     * @public
     */
    [mutationTypes.SET_DISPLAY_LABEL_SECTIONS](state, payload) {
      state.displayLabelSections = payload
    },
    /**
     * Définit le tableau d'historique des sections précédements séléctionnées
     * @param {Int[]} payload Tableau contenant les identifiants des sections séléctionnées par l'utilisateur
     * @method
     * @public
     */
    [mutationTypes.SET_HISTORY_SECTIONS](state, payload) {
      if (payload.length >= 2 && payload[payload.length - 1].hierarchy === undefined) {
        payload[payload.length - 1].hierarchy = payload[payload.length - 2].hierarchy
      }

      state.historySections = payload

      if (payload.length === 0) {
        state.historySections.push([])
      }
    },
    /**
     * Définit si le menu d'historique des selections de sections doit être affiché
     * @method
     * @public
     */
    [mutationTypes.SET_DISPLAY_HISTORY_SECTIONS](state, payload) {
      state.displayHistorySections = payload

      if(this.numberOfSelectedSections > 1){
        state.mode.options.subdivide = false
      }else{
        state.mode.options.subdivide = true
      }
    },
    /**
     * Définit les coordonnées du coin supérieur gauche du menu d'historique des selections de sections
     * @method
     * @public
     */
    [mutationTypes.SET_HISTORY_SECTIONS_MENU_POS](state, payload) {
      state.historySectionMenuPos = payload
    },
    /**
     * Définit les types d'événements devant être affichés sur la représentation
     * @method
     * @public
     */
    [mutationTypes.SET_TYPES_EVENTS_TO_DISPLAY](state, payload) {
      state.displayedEventsTypes = payload
    },
    /**
     * Définit si l'application est en mode édition
     * @method
     * @public
     */
    [mutationTypes.IS_EDIT_MODE](state, isEditMode) {
      state.isEditMode = isEditMode !== undefined ? isEditMode : !state.isEditMode
    },
    /**
     * Permet de mettre à jour le libellé décrivant les filtres actuellement appliqués sur la représentation
     * @method
     * @public
     */
    [mutationTypes.UPDATE_LABEL_FILTERS](state, payload) {
      state.labelFilters = payload
    },
    /**
     * Permet de définir le ratio REM / PX
     * @method
     * @public
     */
    [mutationTypes.SET_RATIO_REM_PX](state, payload) {
      state.ratioRemPx = payload;
      localStorage.setItem('ratioRemPx', payload);
    },
    /**
     * Permet de définir le mode dans lequel se trouve l'application
     * @method
     * @public
     */
    [mutationTypes.SET_EYEDIAG_MODE] (state, payload) {
      state.mode = payload
    },
    /**
     * Permet d'ajouter un mode eyediag à l'historique des modes
     * @method
     * @public
     */
    [mutationTypes.ADD_PREVIOUS_MODE] (state, payload) {
      state.previousMode.push(payload)
    },
    /**
     * Permet de retirer le dernier mode eyediag de l'historique des modes
     * @method
     * @public
     */
    [mutationTypes.REMOVE_PREVIOUS_MODE] (state) {
      state.previousMode.pop()
    },
    /**
     * Permet de définir le podium des événements par rapport à leur nombre d'occurence sur l'ensemble des données (de plus présent au moins présent)
     * @method
     * @public
     */
    [mutationTypes.SET_EVENTS_PODIUM] (state, payload) {
      state.eventsPodium = payload
    },
    /**
     * Permet partager dans le store les columns du fichier traité
     * @method
     * @public
     */
    [mutationTypes.SET_COLUMNS_FILE](state, payload) {
      state.columnsFile = payload
    },
    /**
     * Permet partager dans le store les columns du fichier traité
     * @method
     * @public
     */
    [mutationTypes.SET_AS_SECTION](state, payload) {
      state.asSection = payload
    },
    /**
     * Permet de définir le type et les paramètres d'une erreur globale dans l'application
     * @param {Object} payload Objet contenant le type et les paramètres de l'erreur
     */
    [mutationTypes.SET_ERROR] (state, payload) {
      state.error = payload
    },
    /**
     * Permet de mettre à jour la liste des événements non classés
     * @param {State} state state du module
     * @param {EyeEvent[]} payload Liste des événements non classés
     */
    [mutationTypes.SET_UNCLASSIFIED](state, payload) {
      state.unclassified = payload
    },
    [mutationTypes.SET_ABORT_CONTROLLER](state, payload) {
      state.reqAbortController = payload
    },
    [mutationTypes.SET_HIERARCHY](state, payload) {
      state.hierarchy = payload
    },
    [mutationTypes.SET_DATA_EXTENSION](state, payload) {
      state.dataExtension = payload
    }
  },
  actions: {
    /**
     * Permet de reset par défaut le state de l'application. Cette fonction est utilise avant chaque accès à un nouveau patient pour remttre à 0 tout ce qui a pu être effectué sur l'application
     * @method
     * @public
     */
    async resetState(context) {
      const promises = []
      const modulesToReset = ['circle', 'event', 'patient', 'refCircle', 'formEntry', 'layout', 'borderList']
      Object.assign(context.state, getDefaultState())

      modulesToReset.forEach(module => {
        promises.push(context.dispatch(`${module}/resetState`, null, { root: true }))
      })

      return Promise.all(promises)
    },
    /**
     * Permet de compléter l'initialisation du store, au début du mode collaboratif après receptionné et merge les informations principales du store du présentateur
     * @method
     * @public
     */
    async stateInitCollab(context, payload) {
      const backUp = _.merge(context.rootState, payload.store)
      this.replaceState(backUp)

      await context.dispatch(`patient/getPatientData`, null, {root: true})
      await context.dispatch(`circle/getDataRepresentation`, {
        unitPerCircle: context.rootState.circle.unitPerCircle,
        periodUnit: context.rootState.circle.periodUnit,
        reloadDataTypes: payload.reload
      }, {root: true})

      if (context.rootState.refCircle.score.idScore !== null) {
        await context.dispatch(`refCircle/score/getScoreCircle`, null, {root: true})
      }
    },
    /**
     * Permet la suppression du cache de l'utilisateur sur le serveur. Cela permet lorsque celui-ci ajoute un événement en mode édition d'être sûr que ce nouvel événement sera pris en compte sur la représentation lors de la réactualisation des données
     * @method
     * @public
     */
    async deleteUserCache() {
      await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/user/cache`, {
        method: 'DELETE',
        credentials: 'include'
      })
    },
    /**
     * Permet de mettre à jour l'affichage de l'application par rapport aux dimensions de la fenêtre
     * @method
     * @public
     */
    updateLayout(context) {
      proportion.updateProportion()
      const transform = proportion.getTransformRepresentation()
      context.commit(`layout/${mutationTypes.UPDATE_WINDOW_SIZE}`, null, { root: true })
      context.commit(`layout/${mutationTypes.UPDATE_CENTERX}`, transform.x, { root: true })
      context.commit(`layout/${mutationTypes.UPDATE_CENTERY}`, transform.y, { root: true })
      context.commit(`layout/${mutationTypes.UPDATE_SCALE}`, transform.k, { root: true })
      context.commit(`layout/${mutationTypes.SET_RADIUS}`, proportion.getRadiusSize(), { root: true })
      context.dispatch('event/common/generateEvents', null, { root: true })
    },
    /**
     * Cette fonction permet d'ajouter une entrée dans le tableau de la liste des événements selectionnées
     * @param {*} context 
     * @param {Object} payload Entrée devant être ajoutée à l'historique des sections selectionnées
     */
    addEntryHistorySections(context, payload) {
      const baseHistorySection = context.state.historySections.splice(
        0,
        context.state.indexHistorySection + 1
      )

      baseHistorySection.push(payload)
      context.commit(mutationTypes.SET_HISTORY_SECTIONS, baseHistorySection)
      context.commit(mutationTypes.SET_INDEX_HISTORY_SECTIONS, context.state.indexHistorySection + 1)
    },
    /**
     * Permet de récupérer les informations principales du store. Cette fonction est utilisé par le présentateur pour ensuite transmettre les informations principales de son store aux autres participants de la session collaborative
     * @method
     * @public
     */
    getState() {
      let store = { ...this.state }
      delete store['ws']
      delete store['layout']
      delete store['user']
      delete store['unclassified']
      delete store['assembly']
      delete store['record']
      //Attention en mode collaboratif, la personne qui rejoint en cours de route aura un stateManager vierge
      delete store['stateManager']

      store = _.cloneDeep(store)
      delete store.eventsPodium
      delete store.circle.circles
      delete store.circle.circlesConfig
      delete store.circle.repCircleColor
      delete store.circle.hoveredCircle
      delete store.circle.temporalityMenuSelectedCircle

      delete store.event.common.hoveredEvent
      delete store.event.common.hoveredRefEvent
      delete store.event.common.events
      delete store.event.common.symbols
      delete store.event.common.filteredEvents
      delete store.event.common.selectedEvents
      delete store.event.common.labelToDisplay
      delete store.event.common.linkedEvents
      delete store.event.common.antecedentRankEvent
      
      delete store.sections
      delete store.patient.patient
      delete store.patient.patientNotes
      delete store.refCircle.associatedEvents.associatedEventsCircle
      delete store.refCircle.score.hoveredEvent
      delete store.refCircle.score.scoreCircle
      delete store.refCircle.score.scoreChecker
      delete store.refCircle.familyHistory.familyHistoryCircle

      //A corriger ça serait bien de pas avoir à les supprimer
      delete store['formEntry']

      //Utile seulement pour l'ajout de force d'un user dans une session collaborative
      if (store.borderList.currentDisplayedBorderList === BorderListTypes.LIST_FORCE_JOIN_COLLAB) {
        store.borderList.isDisplayedList = false
        store.borderList.currentDisplayedBorderList = null
      }

      return store
    }
  }
})

export default Store