/**
 * Définit les modes dans lesquels l'application peut-être et les options disponibles pour chaque mode
 */
export const mode = {
  'PATIENT': 'PATIENT',
  'POPULATIONAL': 'POPULATIONAL',
  'UNIVERSAL':'UNIVERSAL'
}

export const populationalOptions = {
  mergedBy: {
    'SECTION': 'SECTION',
    'CODE': 'CODE'
  },
  severity: {
    'CODE': 'CODE',
    'STATS': 'STATS'
  },
  podiumBy: {
    'ALL_CIRCLES': 'ALL_CIRCLE',
    'CIRCLE': 'CIRCLE'
  },
  content: {
    'MAIN_DIAGNOSIS': 'MAIN_DIAGNOSIS',
    'GHM': 'GHM',
    'ASSOCIATED_DIAGNOSIS': 'ASSOCIATED_DIAGNOSIS'
  },
  focusContent: {
    'RUM': 'RUM',
    'RSS': 'RSS'
  },
  typeMerge: {
    'LINEAR': 'LINEAR',             // ECHELLE LINEAIRE,
    'RACINECARRE': 'RACINECARRE',   // ECHELLE RACINE CARRE,
    'JENKS': 'JENKS'                // ECHELLE JENKS    
  }
}