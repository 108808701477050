<template>
  <g>
    <g
      v-for="(point, index) in points"
      :key="index"
    >
      <circle
        :class="
          (point.peak === true)
            ? 'point-debug-event'
            : (index === 0 || point.idSection !== points[index - 1].idSection)
              ? 'point-debug-section'
              : (point.inter)
                ? 'point-debug-inter'
                : 'point-debug'
        "
        :cx="point.coordinates[0]"
        :cy="point.coordinates[1]"
        r="4"
        @click="printPointData(point)"
      />
      <path
        v-if="index === 0 || point.idSection !== points[index - 1].idSection"
        class="line-debug-section"
        :d="generateLineChangeSection(point)"
      />
    </g>
  </g>
</template>

<script>

import { mapGetters } from 'vuex'
import * as d3 from 'd3'
import CircleUtility from "@/libraries/CircleUtility.js";

export default {
  name: 'PointDebug',
  props: {
    /**
     * Détermine si les points de debug doivent être affiché seulement sur le cercle principal ou tous
     * @type {Boolean}
     */
    onlyCurrent: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    /**
     * Liste des points composant le ou les cercles
     * @type {EyePoint[]}
     */
    points: null
  }),
  computed: {
    ...mapGetters({
      circles: "circle/circles",
      theme: "circle/theme",
      radius: "layout/radius"
    }),
  },
  watch: {
    onlyCurrent() {
      this.calcPoint()
    },
    circles() {
      this.calcPoint()
    }
  },
  mounted() {
    this.calcPoint()
  },
  methods: {
    /**
     * Affiche les information d'un point de tracé
     * @param {EyeEvent} point Information d'un point de tracé
     */
    printPointData(point) {
      console.log(point)
    },
    /**
     * Cette fonction permet de récupérer l'ensemble des points composant les cercles
     * @method
     * @public
     */
    calcPoint() {
      let circlesData = []
      if (this.onlyCurrent) {
        circlesData = this.circles.filter(circle => circle.active === true)
      } else {
        circlesData = this.circles.filter(circle => CircleUtility.isVisibleCircle(circle.configIndex >= 0))
      }
      circlesData = circlesData.map(circle => {
        const radius = this.getRadius(circle.configIndex)
        return {
          radius: radius,
          points: circle.points.map(point => {
            const radiusPoint = CircleUtility.getRadiusAtPoint(radius, point.severity)
            return {
              ...point,
              ...{
                radius: radiusPoint,
                coordinates: d3.pointRadial(point.angle, radiusPoint)
              }
            }
          })
        }
      })
      let points = circlesData.map(circle => circle.points)
      if (points.length > 0) {
        points = points.reduce((prev, current) => prev.concat(current))
      }
      this.points = points
    },
    /**
     * Cette fonction permet de récupérer la rayon d'un cercles
     * @method
     * @public
     * @param {Number} indexConfig Index de configuration du cercle dans le tableau des configuration de style graphique / proportions
     */
    getRadius(indexConfig) {
      if (indexConfig < 0) {
        return this.radius * this.theme[0].coefRadius;
      } else if (indexConfig >= this.theme.length) {
        return (
          this.radius *
          (this.theme[this.theme.length - 1].coefRadius +
            (indexConfig - this.theme.length + 1) * 0.18)
        );
      } else {
        return this.radius * this.theme[indexConfig].coefRadius;
      }
    },
    /**
     * Cette fonction permet d'afficher un trait vertical passant par un point. Elle est utilisé pour mettre en évidence les point terminant une section afin de mettre en évidence là où commence et là où se termine une section
     * @method
     * @public
     * @param {EyePoint} point Dernier point composant une section
     */
    generateLineChangeSection(point) {
      const pointsRadialFormat = [
        [point.angle, point.radius - 40],
        [point.angle, point.radius],
        [point.angle, point.radius + 40]
      ]

      const pathLineRadial = d3
        .lineRadial()
        .curve(d3.curveCardinalClosed.tension(0))(pointsRadialFormat);
      
      return pathLineRadial;
    }
  }
}
</script>

<style scoped>

.point-debug {
  fill: orange
}

.point-debug-inter {
  fill: blue
}

.point-debug-event {
  fill: red
}

.point-debug-section {
  fill: magenta
}

.line-debug-section {
  stroke: magenta;
  stroke-width: 1.5px;
}


</style>