<template>
  <dialog
    ref="modal"
    class="modal_custom"
  >
    <div>
      <div
        class="flex flex-row justify-between"
      >
        <h3>{{ sectionName }}</h3>
        <CrossButton
          @close="exitModal"
        />
      </div>
      <MainInformations
        v-show="!optionsEnabled"
        ref="eventMainInformation"
        :section-name="sectionName"
        @options="optionsEnabled = true"
        @save="onSaveEvent"
        @delete="onDeleteEvent"
      />
      <Options
        v-show="optionsEnabled"
        ref="eventOptions"
        @save="onSaveOptions"
        @cancel="optionsEnabled = false"
      />
    </div>
  </dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "../../../store/mutations-types.js";
import * as d3 from 'd3'

import MainInformations from "./MainInformations.vue"
import Options from "./Options.vue"
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import CrossButton from "@/components/Button.vue"
import eventEditingTypes from "@/shared/enums/eventEditingTypes.js"
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import utils from "@/libraries/utils.js"
import EyeFetch from "@/libraries/EyeFetch.js"

let idSelectedSection = null

export default {
  name: "ModalDialog",
  components: {
    MainInformations,
    Options,
    CrossButton
  },
  data: () => {
    return {
      sectionName: "",
      optionsEnabled: false,
      options: {},
      idCounter: 0,
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters({
      pieSelectedSections: "pie/pieSelectedSections",
      isEditMode: 'isEditMode',
      editEvent: 'event/edit/editingEventToAdd',
      editingType: 'event/edit/editingType',
      unitPerCircle: 'circle/unitPerCircle',
      periodUnit: 'circle/periodUnit',
      isInPatient: 'isInPatient',
      isInNurseEntry: 'isInNurseEntry'
    })
  },
  watch: {
    pieSelectedSections() {
      if (this.isEditMode && this.isInPatient && this.isInNurseEntry === false) {
        if (this.pieSelectedSections.length > 0) {
          this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_TYPE}`, eventEditingTypes.ADD)
          idSelectedSection = this.pieSelectedSections[0].id
          this.sectionName = this.pieSelectedSections[0].name
          this.options = {}
          this.idCounter = 0
          this.$refs["modal"].show()
          this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_EVENTS_TO_ADD)
        } else {
          this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_TYPE}`, null)
          this.exitModal(null)
        }
      }
    },
    editEvent() {
      if (this.editEvent !== null) {
        this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_TYPE}`, 
          this.pieSelectedSections.length === 0
            ? eventEditingTypes.ALREADY_EXIST
            : eventEditingTypes.ADD
        )
        if (this.$refs.modal.open === false) {
          this.$refs["modal"].show()
        }
        this.$refs.eventMainInformation.editEvent(this.editEvent)
        this.$refs.eventOptions.editEvent(this.editEvent)
        this.options.note = this.editEvent.note
        this.isEditing = true
      }
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      deleteEvent: 'event/edit/deleteEvent',
      deleteUserCache: 'deleteUserCache',
      getDataRepresentation: 'circle/getDataRepresentation'
    }),
    async onSaveEvent(event) {
      const mainData = this.$refs.eventMainInformation.getMainInformation()
      let newEvents = []

      if (this.editingType === eventEditingTypes.ADD && this.options.recurrence && this.options.recurrence.enable) {
        const dates = d3[this.options.recurrence.intervalUnit].every(this.options.recurrence.interval).range(new Date(this.options.recurrence.start), new Date(this.options.recurrence.end))

        newEvents = dates.map(date => ({
          id: `TMP-${this.idCounter++}`,
          code: mainData.selectedEventCode,
          date: date.toISOString(),
          severity: +mainData.severity,
          defaultLabel: mainData.eventLabel,
          idSection: idSelectedSection,
          resourceType: mainData.resourceType,
          note: this.options.note || ''
        }))
      } else {
        newEvents = [{
          id: this.editingType === eventEditingTypes.ALREADY_EXIST ? this.editEvent.id : `TMP-${this.idCounter++}`,
          code: mainData.selectedEventCode,
          date: new Date(mainData.eventDate).toISOString(),
          severity: +mainData.severity,
          defaultLabel: mainData.eventLabel,
          idSection: idSelectedSection,
          resourceType: mainData.resourceType,
          note: this.options.note || ''
        }]
      }

      this.$refs.eventMainInformation.resetForm()
      this.$refs.eventOptions.resetForm()

      if (this.editingType === eventEditingTypes.ADD) {
        this.sendEvent({event})
        this.$store.commit(`event/edit/${mutationTypes.ADD_EVENT_TO_ADD}`, newEvents)
        this.options = {}
        if (this.isEditing) {
          this.$store.commit(`event/edit/${mutationTypes.REMOVE_EVENT_TO_ADD}`, this.editEvent.id)
          this.isEditing = false
        }
      } else {
        if (event.isTrusted) {
          await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/patient/event`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(newEvents)
          })
        }
        this.sendEvent({event})

        await this.deleteUserCache()
        this.getDataRepresentation({
          unitPerCircle: this.unitPerCircle,
          periodUnit: this.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      }
      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    async onDeleteEvent(event) {
      if (event.isTrusted) {
        await this.deleteEvent({
          event: this.editEvent
        })
      }
      this.sendEvent({event})
      await this.deleteUserCache()
      await this.getDataRepresentation({
        unitPerCircle: this.unitPerCircle,
        periodUnit: this.periodUnit,
        reloadDataTypes: reloadDataTypes.CATEGORISATION
      })
      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    onSaveOptions(options) {
      this.options = options
      this.optionsEnabled = false
    },
    exitModal(event) {
      if (event) {
        this.sendEvent({ event })
        this.$store.commit(`event/edit/${mutationTypes.RESET_EVENT_TO_ADD}`)
      }

      this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
      this.$refs["modal"].close()
      this.$refs.eventMainInformation.resetForm()
      this.$refs.eventOptions.resetForm()
      if (event) {
        this.collaborativeEventTreated()
      }
    }
  },
};
</script>

<style>
.modal_custom {
  position: fixed;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg-1);
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  max-width: 700px;
  width: 50vw;
  min-height: 30vh;
  resize: both;
  overflow: auto;
  color: var(--color-text)
}

.input_wrapper {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
}
.modal_form {
  display: flex;
  flex-direction: column;
}

/* .modal_custom button:hover {
  background: rgb(86, 97, 109);
  transition-duration: 500ms;
} */
.modal_custom button:nth-child(1) {
  margin-right: 2px;
}
.modal_custom button:nth-child(2) {
  margin-left: 2px;
}
.button_wrapper {
  display: flex;
  margin-top: 20px;
}
textarea {
  margin-bottom: 15px;
  resize: vertical;
}
dialog::backdrop {
  background: linear-gradient(90deg, navy,rgb(1, 1, 47) );
  opacity: 0.1;
}

#suggestions-modal {
  padding: 10px;
  height: 100px;
  max-height: 100px;
  overflow: scroll;
  margin-top: 20px;
}

.suggestion {
  cursor: pointer;
}

.error-modal {
  color: red;
}

/* .insert-event-recurrence-choose-dates { */
input:invalid {
  border: red solid 1px;
}

</style>