import * as d3 from 'd3'
import * as PieUtils from '@/components/pie/PieUtils.js'
import D3Animation from '@/config/D3Animation.js'
import * as mutationTypes from '@/store/mutations-types.js'
import { useStore } from 'vuex'

export function pieLinkedSection() {
  const linkedSections = {
    "Traitement": "Traitement",
    "EAL": "Traitement EAL",
    "HbA1c": "Traitement Anti-diabétique",
    "Cardiovasculaire": "Traitement Cardiovasculaire"
  }
  const store = useStore()

  function displayLinkedSections(hoveredSection, radius) {
    highLightTrtPieSections(hoveredSection, radius)
    // groupTrtList()
  }

  function hideLinkedSections(hoveredSection, radius) {
    unHighLightTrtPieSections(hoveredSection, radius)
  }

  function selectedLinkedSections(clickedSection, radius) {
    const linkedSections = getSelectionLinkedSections(clickedSection, true)
    const dataSections = []

    for (const section of linkedSections.data()) {
      section.data.selectedByPie = true
      dataSections.push(section.data)
    }

    store.commit(`pie/${mutationTypes.ADD_SELECTED_SECTION}`, dataSections)
    highLightUnhighLightClickedLinkedSections(linkedSections, true, radius)
  }

  function unSelectedLinkedSections(clickedSection, radius) {
    const linkedSections = getSelectionLinkedSections(clickedSection, true)
    const dataSections = []

    for (const section of linkedSections.data()) {
      section.data.selectedByPie = false
      dataSections.push(section.data)
    }

    store.commit(`pie/${mutationTypes.REMOVE_SELECTED_SECTION}`, dataSections)
    highLightUnhighLightClickedLinkedSections(linkedSections, false, radius)
  }

  function getSelectionLinkedSections(hoveredSection, keepSelected) {
    let twin = null

    for (const [key, linkedSection] of Object.entries(linkedSections)) {
      if (hoveredSection.data.name.startsWith(key)) {
        twin = linkedSection
        break
      }
    }

    return d3.select('#group-pie')
      .selectAll(".pie-quarter")
      .filter((section) => {
        if (twin === null) {
          return false
        } else if (keepSelected === false && section.data.selectedByPie === true) {
          return false
        }
        return section.data.name.startsWith(twin)
          && section.data.id !== hoveredSection.data.id
      })
  }

  function highLightTrtPieSections(hoveredSection, radius) {
    const linkedSections = getSelectionLinkedSections(hoveredSection, false)
    const dataSections = linkedSections.data().map(s => s.data)

    store.commit(`pie/${mutationTypes.ADD_HOVERED_SECTION}`, dataSections)

    linkedSections
      .style("fill-opacity", 0.3)
      .transition()
      .duration(D3Animation.PIE_MOUSE_OVER)
      .attr("d", d => PieUtils.arcEnd(d, radius))
      .style("fill", "url(#cyan-gradient)")
  }

  function unHighLightTrtPieSections(hoveredSection, radius) {
    const linkedSections = getSelectionLinkedSections(hoveredSection, false)
    const dataSections = linkedSections.data().map(s => s.data)

    store.commit(`pie/${mutationTypes.REMOVE_HOVERED_SECTION}`, dataSections)

    linkedSections
      .style("fill-opacity", 0.3)
      .transition()
      .duration(D3Animation.PIE_MOUSE_LEAVE)
      .attr("d", d => PieUtils.arcStart(d, radius))
      .style("fill", PieUtils.getColor)
  }

  function highLightUnhighLightClickedLinkedSections(linkedSections, selectedByPie, radius) {
    linkedSections
      .style("fill-opacity", 0.3)
      .transition()
      .duration(D3Animation.PIE_MOUSE_CLICK)
      .attr("d", d => selectedByPie ? PieUtils.arcClick(d, radius) : PieUtils.arcEnd(d, radius))
      .style(
        "fill",
        selectedByPie
          ? "url(#aquam-gradient)"
          : "url(#cyan-gradient)"
      )
  }

  // function groupTrtList() {
  // }

  return {
    displayLinkedSections,
    hideLinkedSections,
    selectedLinkedSections,
    unSelectedLinkedSections
  }
}