import UserRole from "@/shared/enums/userRole.js"

const isInGeneralisteTypePatient = store => store.getters['user/typePatientContext'] === UserRole.GENERALISTE
const isInPharmacienTypePatient = store => store.getters['user/typePatientContext'] === UserRole.PHARMACIEN
const otherThanPharmacienTypePatient = store => store.getters['user/typePatientContext'] !== UserRole.PHARMACIEN
const isInQOR = store => store.getters['user/typePatientContext'] === UserRole.QOR

const isAdmin = store => {
  if (store.getters['user/userData'] && store.getters['user/userData'].Role) {
    return store.getters['user/userData'].Role.includes(UserRole.ADMIN)
  }
  return false
}

export default {
  isInGeneralisteTypePatient,
  isInPharmacienTypePatient,
  isInQOR,
  otherThanPharmacienTypePatient,
  isAdmin
}