<template>
  <div>
    <div
      v-for="(patient, index) in subMenuItems"
      :key="index"
      class="home-patient-description"
    >
      <div
        :class="isInCollaboration && (menuItemSelected === 'Patients' && !patient.collaborative) ? 'non-collaborative-patient' : 'collaborative-patient'"
        :style="{ cursor: (isInCollaboration && menuItemSelected === 'Patients' && !patient.collaborative) ? 'not-allowed' : 'pointer' }"
        @mouseover="(e) => update_patient_id(e, patient)"
        @click="(e) => showPatientRepresentation(e, patient)"
      >
        <div
          v-if="menuItemSelected === 'Record'"
          e2e="homeRecordItem"
          :record-item="patient.IdRoom"
          :e2e-test-name="patient.TestName"
          @mouseover="hoveredRecord = patient"
          @mouseleave="hoveredRecord = null"
        >
          <span e2e="homeIdPatient">{{ `${patient.TestName} ${patient.Name} ${patient.Surname}` }}</span>
          <br>
          <p>{{ patient.Start }}</p>
        </div>
        <div v-else>
          <span e2e="homeIdPatient">{{ patient.id }}</span> - <strong>{{ patient.nom }}</strong> {{ patient.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import * as mutationTypes from '@/store/mutations-types.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'

const store = useStore()
const router = useRouter()

const props = defineProps({
  subMenuItems: {
    type: Array,
    required: true
  },
  menuItemSelected: {
    type: String,
    required: true
  }
})

const emits = defineEmits(['update-patient-id', 'on-record'])

const isInCollaboration = computed(() => store.getters['ws/isInCollaboration'])

function update_patient_id(e, patient) {
  emits('update-patient-id', e, patient)
}

/**
* Cette fonction est appelé lorsque l'utilisateur clique sur le bouton pour visualiser un dossier patient
* @param {Event} event Evenement fournit par le listener
* @param {Number} idPatient Identifiant du patient pour lequel la représentation doit se charger
**/
async function showPatientRepresentation(event, patient) {
  if (props.menuItemSelected !== 'Record' && isInCollaboration && !patient.collaborative) {
    return
  }
  store.dispatch('ws/sendEvent', { event })
  if (props.menuItemSelected === 'Record') {
    emits('on-record')
  } else {
    const idPatient = patient.id
    if (idPatient === 13) {
      store.commit(mutationTypes.SET_EYEDIAG_MODE, {
        type: eyediagMode.mode.POPULATIONAL,
        options: {
          mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
          severity: eyediagMode.populationalOptions.severity.STATS,
          content: eyediagMode.populationalOptions.content.GHM
        }
      })
    }
    await router.push({ name: "EyeApp", params: { idPatient } })
  }
  store.dispatch('ws/collaborativeEventTreated')
}

</script>