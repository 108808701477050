<template>
  <GenericModal
    :id="id"
    :title="title"
    :description="description"
    :buttons="buttons"
    theme="error"
    @close="defaultAction"
  />
</template>

<script>
import GenericModal from '@/components/modal/GenericModal.vue'
import { mapGetters } from 'vuex'
import errorTypes from '@/enums/errorTypes.js'
import * as mutationTypes from '@/store/mutations-types.js'

export default {
  components: {
    GenericModal
  },
  data: () => ({
    /**
     * Titre de la modal
     * @type {String}
     */
    title: 'Créer une session',
    /**
     * Description de la modal
     * @type {String}
     */
    description: '',
    /**
     * Tableau décrivant les boutons présent sur la modal
     * @type {EyeGenericModalButton[]}
     */
    buttons: [],
    /**
     * Identifiant de la modal générique
     * @type {String}
     */
    id: "modal-global-error",
    /**
     * Fonction qui sera exécutée lorsque l'utilisateur presse le bouton croix de la modal
     * @type {Function}
     */
    defaultAction: () => {}
  }),
  computed: {
    ...mapGetters({
      errorData: 'error'
    })
  },
  watch: {
    errorData() {
      switch (this.errorData.type) {
      case errorTypes.TOO_MANY_CONCURRENT_USER:
        this.setupConcurrentUserError()
        break
      case errorTypes.SERVER:
        this.setupServerError()
        break
      }
    }
  },
  methods: {
    /**
     * Cette fonction permet l'initialisation de la modal lorsque l'erreur TOO_MANY_CONCURRENT_USER est lévée. C'est à dire lorsque la limite du nombre d'utilisateurs connectés au même comtpe en simultané est atteinte
     */
    setupConcurrentUserError() {
      const elem = document.getElementById(this.id)

      if (elem.open) {
        return
      }

      this.title = "Nombre maximal de connexions simultanées"
      this.description = "Le nombre maximal de connexions simultanées a été atteint pour ce compte. Vous allez être déconnecté"
    
      this.buttons = [
        {
          label: 'Ok',
          backgroundColorTheme: 'error',
          color: 'white',
          onClick: () => {
            const elem = document.getElementById(this.id)
            elem.close()
            this.$store.commit(mutationTypes.SET_ERROR, {
              type: null,
              params: {}
            })
            window.location.href = `${process.env.VUE_APP_SERVER_BASE_URL}/oidc/session/end`
          }
        }
      ]
      this.defaultAction = this.buttons[0].onClick
      elem.showModal()
    },
    /**
     * Cette fonction permet l'initialisation de la modal lorsque l'erreur SERVER est lévée. C'est lorsqu'une erreur s'est produite sur le serveur lors du traitement des données
     */
    setupServerError() {
      const elem = document.getElementById(this.id)

      if (elem.open) {
        return
      }

      this.title = "Erreur Serveur"
      this.description = "Une erreur est survenue lors du traitement des données par le serveur. Voulez-vous réessayer ?"
      
      this.buttons = [
        {
          label: 'Réessayer',
          backgroundColorTheme: 'success',
          color: 'white',
          onClick: () => {
            const elem = document.getElementById(this.id)
            elem.close()
            this.$store.dispatch(
              this.errorData.params.origin,
              this.errorData.params.params
            )
            this.$store.commit(mutationTypes.SET_ERROR, {
              type: null,
              params: {}
            })
          },
        },
        {
          label: 'Annuler',
          backgroundColorTheme: 'error',
          color: 'white',
          onClick: () => {
            const elem = document.getElementById(this.id)
            elem.close()
            this.$store.commit(mutationTypes.SET_ERROR, {
              type: null,
              params: {}
            })
          }
        }
      ]
      this.defaultAction = this.buttons[1].onClick
      elem.showModal()
    }
  }
}
</script>