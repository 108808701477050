<template>
  <div />
</template>

<script>
import authGrantTypes from '@/shared/enums/authGrantTypes.js'
import ErrorManager from '@/libraries/ErrorManager'
import EyeFetch from '@/libraries/EyeFetch'
import * as mutationTypes from '@/store/mutations-types.js'
import { mapActions } from 'vuex'

export default {
  name: 'LoginComponent',
  async created() {
    if (this.$route.query.code) {
      const searchParams = new URLSearchParams()
      searchParams.append('code', this.$route.query.code)
      searchParams.append('grant_type', authGrantTypes.AUTHORIZATION_CODE)

      try {
        let response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/token/?${searchParams}`, {
          method: 'GET',
          credentials: 'include',
          eyeTokenRequest: true
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {})
          return
        }
      } catch (err) {
        ErrorManager.fetchError(err)
        return
      }
    }
    const data = await this.getUserData()
    //Si on a réussi à obtenir les infos de l'utilisateur access_token ok, sinon il va être redirigé sur la page de login
    if (data !== null) {
      this.$store.commit(`user/${mutationTypes.SET_LOGGED_IN}`, true)
      this.$router.push({ name: "Home"});
    }
  },
  methods: {
    ...mapActions({
      getUserData: "user/getUserData"
    })
  }
}

</script>