import * as mutationTypes from '@/store/mutations-types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

const getDefaultState = () => {
  return {
    /**
     * Tableau comprenant les différents états du store qui ont été sauvegardé
     * @type {EyeState[]}
     */
    states: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    states: state => state.states
  },
  mutations: {
    /**
     * Met à jour la liste contenant les identifiants des différents assemblages de filtres créé par l'utilisateur
     * @param {State} state state du module
     * @param {Object[]} payload liste d'objets contenant les identifiants des assemblages créé par l'utilisateur
     */
    [mutationTypes.ADD_STATE](state, payload) {
      state.states.push(payload)
    }
  },
  actions: {
    async showState(context, state) {
      await context.dispatch('resetState', null, {root: true})
      await context.dispatch('stateInitCollab', {
        store: state.state,
        reload: reloadDataTypes.TEMPORALITY
      }, { root: true})
    },
    async saveState(context, { name }) {
      const state = await context.dispatch('getState', null, { root: true})
      context.commit(`stateManager/${mutationTypes.ADD_STATE}`, {
        name: name,
        state: state
      }, { root: true })
    }
  }
}