import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from "@/libraries/EyeFetch"
import * as mutationTypes from '@/store/mutations-types.js'
import utils from '@/libraries/utils.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

export default {
  namespaced: true,
  state: {
    record: process.env.VUE_APP_RECORD_SESSION === "true",
    recordEvents: [],
    indexRecordEvent: 0,
    secondsElapsed: 0,
    startRecordDate: null,
    replay: false,
    replayRoomId: null,
    replayDuration: 0,
    timeoutIdReplay: null,
    intervalIdReplay: null,
    isPlayingReplay: false,
    queueRecordEvents: []
  },
  getters: {
    record: state => state.record,
    replay: state => state.replay,
    secondsElapsed: state => state.secondsElapsed,
    replayDuration: state => state.replayDuration,
    isPlayingReplay: state => state.isPlayingReplay
  },
  mutations: {
    [mutationTypes.SET_RECORD_STATUS] (state, payload) {
      state.record = payload
    },
    [mutationTypes.SET_RECORD_EVENTS] (state, payload) {
      state.recordEvents = payload
    },
    [mutationTypes.SET_RECORD_INDEX_EVENT] (state, payload) {
      state.indexRecordEvent = payload
    },
    [mutationTypes.SET_RECORD_SECONDS_ELAPSED] (state, payload) {
      state.secondsElapsed = payload
    },
    [mutationTypes.SET_START_RECORD_DATE] (state, payload) {
      state.startRecordDate = payload
    },
    [mutationTypes.SET_RECORD_REPLAY] (state, payload) {
      state.replay = payload
    },
    [mutationTypes.SET_REPLAY_ROOM_ID] (state, payload) {
      state.replayRoomId = payload
    },
    [mutationTypes.SET_TIMEOUT_ID_REPLAY] (state, payload) {
      state.timeoutIdReplay = payload
    },
    [mutationTypes.SET_INTERVAL_ID_REPLAY] (state, payload) {
      state.intervalIdReplay = payload
    },
    [mutationTypes.SET_REPLAY_DURATION] (state, payload) {
      state.replayDuration = payload
    },
    [mutationTypes.SET_IS_PLAYING_REPLAY] (state, payload) {
      state.isPlayingReplay = payload
    },
    [mutationTypes.SET_QUEUE_RECORD_EVENTS] (state, payload) {
      state.queueRecordEvents = payload
    },
    [mutationTypes.ADD_ELEM_QUEUE_RECORD_EVENTS] (state, payload) {
      state.queueRecordEvents.push(payload)
    }
  },
  actions: {
    async addRecord() {
      await EyeFetch(this,
        `${process.env.VUE_APP_SERVER_BASE_URL}/user/record`,
        {
          method: "POST",
          credentials: 'include'
        }
      )
    },
    async getRecords() {
      let response = await EyeFetch(this,
        `${process.env.VUE_APP_SERVER_BASE_URL}/user/records`,
        {
          method: "GET",
          credentials: 'include'
        }
      )

      if (!response.ok) {
        ErrorManager.requestError(response, {origin: 'record/getRecords', params: null})
        return 
      }

      response = await response.json()
      return response.records
    },
    async getStateAtTime(context, {seconds}) {
      if (context.state.timeoutIdReplay !== null) {
        clearTimeout(context.state.timeoutIdReplay)
        context.commit(mutationTypes.SET_TIMEOUT_ID_REPLAY, null)
      }
      const msPlayer = seconds * 1000
      let response = await EyeFetch(this,
        `${process.env.VUE_APP_SERVER_BASE_URL}/user/record/state/${context.state.replayRoomId}?sec=${seconds}`,
        {
          method: "GET",
          credentials: 'include'
        }
      )

      if (!response.ok) {
        ErrorManager.requestError(response, {origin: 'record/getStateAtTime', params: { seconds }})
        return
      }

      response = await response.json()
      await context.dispatch('ws/onPresenterStateTransfer', response, {root: true})
      context.commit(mutationTypes.SET_START_RECORD_DATE, performance.now() - (seconds * 1000))

      for (const [index, recordEvent] of context.state.recordEvents.entries()) {
        if (recordEvent.ms >= msPlayer) {
          context.commit(mutationTypes.SET_RECORD_INDEX_EVENT, index)
          break
        }
      }
      utils.onGlobalAnimationEnd(this, () => {
        context.dispatch('dispatchRecordEvent')
      })
    },
    async getRecord(context, sessionDetail) {
      context.commit(mutationTypes.SET_REPLAY_DURATION, (new Date(sessionDetail.End) - new Date(sessionDetail.Start)) / 1000)
      let response = await EyeFetch(this,
        `${process.env.VUE_APP_SERVER_BASE_URL}/user/record/${sessionDetail.IdRoom}`,
        {
          method: "GET",
          credentials: 'include'
        }
      )

      if (!response.ok) {
        ErrorManager.requestError(response, {origin: 'record/getRecord', params: sessionDetail})
        return 
      }

      response = await response.json()
      context.commit(mutationTypes.SET_REPLAY_ROOM_ID, sessionDetail.IdRoom)
      context.commit(`ws/${mutationTypes.SET_DISPLAY_CURSOR}`, true, {root: true})
      context.commit(mutationTypes.SET_RECORD_EVENTS, response.collaborativeEvents.map(e => JSON.parse(e)))
      context.commit(mutationTypes.SET_RECORD_INDEX_EVENT, 0)
      context.commit(mutationTypes.SET_RECORD_REPLAY, true)
      context.commit(mutationTypes.SET_IS_PLAYING_REPLAY, true)
      context.commit(mutationTypes.SET_START_RECORD_DATE, performance.now())
      context.dispatch('startReplayInterval')
      context.dispatch('dispatchRecordEvent')
    },
    async startRecording(context) {
      context.dispatch('ws/createRoom', null, {root: true})
      context.commit(mutationTypes.SET_START_RECORD_DATE, performance.now())
      setInterval(() => {
        context.commit(mutationTypes.SET_RECORD_SECONDS_ELAPSED, context.rootState.record.secondsElapsed + 10)
        context.dispatch('sendRecordChunk', {exitBrowser: false})
        context.dispatch('ws/sendPresenterState', {
          idSocket: null,
          reload: reloadDataTypes.TEMPORALITY
        }, {root: true})
      }, 10000)
    },
    async sendRecordChunk(context, {exitBrowser}) {
      const body = {
        recordEvents: context.state.queueRecordEvents
      }
      context.commit(mutationTypes.SET_QUEUE_RECORD_EVENTS, [])

      if (exitBrowser === true) {
        navigator.sendBeacon(
          `${process.env.VUE_APP_SERVER_BASE_URL}/user/record/chunk/${context.rootState.ws.roomId}`,
          JSON.stringify(body)
        )
      } else {
        try {
          await EyeFetch(this,
            `${process.env.VUE_APP_SERVER_BASE_URL}/user/record/chunk/${context.rootState.ws.roomId}`,
            {
              method: "POST",
              credentials: 'include',
              body: JSON.stringify(body),
              keepalive: true
            }
          )
        } catch (err) {
          console.log(err)
        }
      }
    },
    dispatchRecordEvent(context) {
      if (context.state.isPlayingReplay === false || context.state.indexRecordEvent >= context.state.recordEvents.length - 1) {
        return
      }

      let timeoutId = null
      const currentRecordEvent = context.state.recordEvents[context.state.indexRecordEvent]
      let remainingSeconds = currentRecordEvent.ms - (performance.now() - context.state.startRecordDate)

      switch (currentRecordEvent.collaborativeEventType) {
      case 'event':
        timeoutId = setTimeout(() => {
          context.dispatch('ws/onCollaborativeEvent', currentRecordEvent, { root: true })
        }, remainingSeconds)
        break
      case 'properties':
        timeoutId = setTimeout(() => {
          context.dispatch('ws/onCollaborativeEvent', currentRecordEvent, { root: true })
          context.dispatch('dispatchRecordEvent')
        }, remainingSeconds)
        break
      case 'cursorPosition':
        timeoutId = setTimeout(() => {
          context.dispatch('ws/onCollaborativeMovePresenterCursor', currentRecordEvent, { root: true })
          context.dispatch('dispatchRecordEvent')
        }, remainingSeconds)
        break
      }
      context.commit(mutationTypes.SET_TIMEOUT_ID_REPLAY, timeoutId)
      context.commit(mutationTypes.SET_RECORD_INDEX_EVENT, context.state.indexRecordEvent + 1)
    },
    getElapsedMs(context) {
      if (context.state.startRecordDate !== null) {
        return performance.now() - context.state.startRecordDate
      } else {
        return 0
      }
    },
    startReplayInterval(context) {
      let intervalId = setInterval(() => {
        context.commit(`${mutationTypes.SET_RECORD_SECONDS_ELAPSED}`, context.state.secondsElapsed + 1)
        if (context.state.secondsElapsed === context.state.replayDuration) {
          clearInterval(intervalId)
        }
      }, 1000)
      context.commit(`${mutationTypes.SET_INTERVAL_ID_REPLAY}`, intervalId)
    },
    playReplay(context) {
      context.commit(mutationTypes.SET_IS_PLAYING_REPLAY, true)
      context.commit(mutationTypes.SET_START_RECORD_DATE, performance.now() - (context.state.secondsElapsed * 1000))
      context.dispatch('startReplayInterval')
      context.dispatch('dispatchRecordEvent')
    },
    pauseReplay(context) {
      context.commit(mutationTypes.SET_IS_PLAYING_REPLAY, false)
      clearTimeout(context.state.timeoutIdReplay)
      clearInterval(context.state.intervalIdReplay)
      context.commit(mutationTypes.SET_TIMEOUT_ID_REPLAY, null)
    }
  }
}