<template>
  <div>
    <ContextMenu
      v-if="menuConfig.show"
      :x="menuConfig.x"
      :y="menuConfig.y"
      :items="menuConfig.items"
      :z-index="5"
      @close="onCloseContextMenu"
    />
    <div id="menu-list-top-container">
      <IconMenuList
        id="top-el-menu-list"
        ref="topElMenuList"
        @click="onClickMenu"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListTopElemOptions'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, watch } from 'vue'
import menuTypes from '@/enums/menu_types.js'
import ContextMenu from "@/components/ContextMenu.vue"
import IconMenuList from '@/assets/icons/menuList.vue'
import * as mutationTypes from '@/store/mutations-types.js'
import TopListSort from '@/enums/borderList/topListSort.js'
import { modifySelection } from '@/config/fonction-menu.js'

const store = useStore()

const topElMenuList = ref(null)
/**
 * Configuration du menu contextuel s'affichant lors d'un clique droit sur le bouton
 * @type {EyeContextMenu}
 */
const menuConfig = ref({
  show: false,
  x: 0,
  y: 0,
  items: [
    {
      id: 0,
      label: "Récurrence",
      labelId: TopListSort.SIZE,
      type: menuTypes.TEXT,
      selected: true,
      click: () => {
        store.commit(`borderList/topElem/${mutationTypes.UPDATE_SORT_TOP_LIST}`, TopListSort.SIZE)
      }
    },
    {
      id: 1,
      label: "Sévérité",
      labelId: TopListSort.SEVERITY,
      type: menuTypes.TEXT,
      selected: false,
      click: () => {
        store.commit(`borderList/topElem/${mutationTypes.UPDATE_SORT_TOP_LIST}`, TopListSort.SEVERITY)
      }
    },
  ]
})

const sortTop = computed(() => store.getters['borderList/topElem/sortTop'])

watch(sortTop, (newSort, oldSort) => {
  modifySelection(menuConfig.value, newSort, oldSort)
})

function onCloseContextMenu() {
  menuConfig.value.show = false
}

/**
 * Cette fonction permet l'initialisation des actions sur le bouton de la liste des elements mémorisés
 * @method
 * @public
 */
function onClickMenu(event) {
  store.dispatch('ws/sendEvent', {event: event})
  event.stopPropagation()
  event.preventDefault()
  menuConfig.value.show = !menuConfig.value.show

  const boundingRect = topElMenuList.value.$el.getBoundingClientRect()
  menuConfig.value.x = boundingRect.x - 100
  menuConfig.value.y = boundingRect.y - 92

  //Faire un nextTick et getBoundingRect sur le composant du menuContextuel ou voir avec une ref si moyen de choper la taille
  store.dispatch('ws/collaborativeEventTreated')
}
</script>