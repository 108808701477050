import * as d3 from 'd3'
import * as EyeColor from '@/assets/color.js'
import D3Animation from '@/config/D3Animation.js'
import utils from '@/libraries/utils.js'
import store from '@/store/index.js'

/**
 * Class Event
 */

class Event {
  static displayOtherLocationsEvent(selection, event) {
    selection = selection.filter(e => e.id === event.id && e.parentSection !== event.parentSection)
    const otherLocation = selection.data()

    selection
      .attr("r", (d) => d.r)
      .transition()
      .duration(D3Animation.EVENT_APPEAR_CIRCLE)
      .style('stroke-opacity', 1)
      .style('fill', EyeColor.getFillColorEventCircle(0.8))

    d3.select('#arc-clone-container')
      .selectAll('g')
      .data(otherLocation)
      .enter()
      .append('g')
      .each((k, i, nodes) => {
        const g = d3.select(nodes[i])

        g.append('path')
          .attr('class', d => `arc-clone-${utils.getEventResourceTypeColor(d, store)}`)
          .attr("transform", d => `translate(${d.cx}, ${d.cy})`)
          .attr('d', d3.arc()
            .innerRadius(event.r + 8)
            .outerRadius(event.r + 9)
            .startAngle(Math.PI / 6)
            .endAngle(5 * (Math.PI / 6))
          )
          .transition()
          .duration(D3Animation.EVENT_APPEAR_TRANSPLANT_PARENTHESIS)
          .attr('d', d3.arc()
            .innerRadius(event.r + 5)
            .outerRadius(event.r + 6)
            .startAngle(Math.PI / 6)
            .endAngle(5 * (Math.PI / 6))
          )

        g.append('path')
          .attr('class', d => `arc-clone-${utils.getEventResourceTypeColor(d, store)}`)
          .attr("transform", d => `translate(${d.cx}, ${d.cy})`)
          .attr('d', d3.arc()
            .innerRadius(event.r + 8)
            .outerRadius(event.r + 9)
            .startAngle(7 * (Math.PI / 6))
            .endAngle(11 * (Math.PI / 6))
          )
          .transition()
          .duration(D3Animation.EVENT_APPEAR_TRANSPLANT_PARENTHESIS)
          .attr('d', d3.arc()
            .innerRadius(event.r + 5)
            .outerRadius(event.r + 6)
            .startAngle(7 * (Math.PI / 6))
            .endAngle(11 * (Math.PI / 6))
          )
      })
  }

  static deleteParenthesisOtherLocations(selection, event) {
    d3.select('#arc-clone-container')
      .selectAll('g')
      .remove()
    
    if (selection !== null) {
      selection = selection.filter(e => e.id === event.id && e.parentSection !== event.parentSection)
      
      selection
        .style('stroke-opacity', (d) => (d.selected === true ? 1 : 0))
        .style('fill', (d) => EyeColor.getFillColorEventCircle(d.selected === true ? 0.8 : 0))
    }
  }
}

export default Event