<template>
  <ListBase
    id="list-force-join-collab"
    ref="list-base"
  >
    <h3 class="m-b-20">
      Ajout participants
    </h3>
    <button
      @click="refreshConnectedUser"
    >
      Refresh
    </button>
    <div>
      <div
        v-for="(connectedUser, index) in connectedUsers"
        :key="index"
      >
        <p>{{ connectedUser.name }} {{ connectedUser.familyName }}</p>
        <div class="flex">
          <button @click="onJoin(connectedUser)">
            Joindre
          </button>
          <button @click="onRemove(connectedUser)">
            Supprimer
          </button>
        </div>
      </div>
    </div>
  </ListBase>
</template>

<script>
import ListBase from '@/components/borderLists/ListBase.vue'
import utils from '@/libraries/utils'
import { mapGetters  } from 'vuex'
import * as d3 from 'd3'

export default {
  name: 'ListForceJoinCollab',
  components: {
    ListBase
  },
  data: () => ({
    connectedUsers: []
  }),
  computed: {
    ...mapGetters({
      currentDisplayedBorderList: 'borderList/currentDisplayedBorderList',
      eyediagMode: 'eyediagMode',
      wsSocket: "ws/wsSocket",
      roomId: "ws/roomId",
    })
  },
  mounted() {
    this.initWsEvent()
  },
  beforeUnmount() {
    this.wsSocket.off('debug:userConnected', this.onUserConnectedData)
  },
  methods: {
    initWsEvent() {
      this.wsSocket.on('debug:userConnected', this.onUserConnectedData)
    },
    onUserConnectedData(data) {
      this.connectedUsers = data.users
    },
    refreshConnectedUser() {
      this.connectedUsers = []

      this.wsSocket.emit('debug:userConnected')
    },
    onJoin(user) {
      this.wsSocket.emit('debug:userJoin', {
        user: user,
        roomId: this.roomId
      })
    },
    onRemove(user) {
      this.wsSocket.emit('debug:userRemove', {
        user: user,
        roomId: this.roomId
      })
    },
    /**
     * Cette fonction est appelé pour initier le déplacement de la liste / panel (ouverture et fermeture de celui-ci)
     * @param  {...any} params Liste des paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    }
  }
}
</script>

<style scoped>
h3 {
  display: flex;
  justify-content: center;
}
</style>