<template>
  <ListBase
    id="select-event-menu"
    ref="list-base"
  >
    <div
      id="selected-event-container"
      ref="selected-event-container"
    >
      <div
        v-for="(event, index) in events"
        :key="index"
        class="selected-event"
      >
        <div 
          class="red-circle-container" 
          style="width: 50px"
        >
          <span 
            :class="{
              [`dot-${getEventResourceTypeColor(event, $store)}`]: true,
              [`dot-fill-${getEventResourceTypeColor(event, $store)}`]: hoveredEvent && event.id === hoveredEvent.id,
            }"
            :style="`width: ${event.r * 2}px; height: ${event.r * 2}px`"
          />
        </div>

        <p class="selected-event-text">
          {{ event.defaultLabel }}
        </p>
      </div>
    </div>
  </ListBase>
</template>

<script>

import * as d3 from "d3";
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "@/store/mutations-types";
import MemorizedEventsSortTypes from '@/enums/memorized_events_sort_types.js'
import ListBase from "@/components/borderLists/ListBase.vue"

import utils from '@/libraries/utils.js'

export default {
  name: "ListSelectedEvent",
  components: {
    ListBase
  },
  emits: ['changeState'],
  data: () => ({
    /**
     * Liste des événements mémorisés par l'utilisateur
     * @type {EyeEvent[]}
     */
    events: []
  }),
  computed: {
    ...mapGetters({
      memorizedEvents: "event/common/memorizedEvents",
      hoveredEvent: "event/common/hoveredEvent",
      listMemorizedEventsSort: 'event/common/listMemorizedEventsSort',
      currentDisplayedBorderList: 'borderList/currentDisplayedBorderList',
      isDisplayedList: 'borderList/isDisplayedList'
    })
  },
  watch: {
    memorizedEvents: {
      handler() {
        this.applySort()
      },
      deep: true
    },
    listMemorizedEventsSort() {
      this.applySort()
    },
    events: {
      handler() {
        this.linkDomDataD3()
      },
      flush: 'post'
    }
  },
  created() {
    this.sortBySelection()
  },
  mounted() {
    this.linkDomDataD3()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      sendProperties: "ws/sendProperties",
      collaborativeEventTreated: "ws/collaborativeEventTreated",
      getAllPatientNotes: "patient/getAllPatientNotes"
    }),

    /**
     * Cette fonction permet de lier les événements affichés dans la liste avec D3 pour la gestion des événements
     * @method
     * @public
     */
    linkDomDataD3() {
      d3.select(this.$refs['selected-event-container'])
        .selectAll(".selected-event")
        .data(this.events)
        .on("mouseover", (event, d) => {
          this.sendEvent({ event: event })
          this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, d, { root: true })
          this.collaborativeEventTreated()
        })
        .on("mouseleave", (event) => {
          this.sendEvent({ event: event })
          this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, null, { root: true })
          this.collaborativeEventTreated()
        })
        .on("contextmenu",(event,d)=>{
          this.sendEvent({ event: event })
          event.stopPropagation()
          event.preventDefault()

          this.$store.commit(`event/common/${mutationTypes.TOOLTIPS_ADD_EVENTS}`, d)
          this.collaborativeEventTreated()
        })
    },
    /**
     * Permet la gestion de l'ouverture / fermeture de la liste
     * @param  {...any} params Paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    },
    /**
     * Format une date du format iso au format %d/%m/%Y
     * @method
     * @public
     */
    formatDate(date) {
      const formatDate = d3.timeFormat("%d/%m/%Y");
      return formatDate(d3.isoParse(date));
    },
    /**
     * Cette fonction permet d'appliquer le type de trie précédement selectionné par l'utilisateur sur la liste des événements mémorisés
     * @method
     * @public
     */
    applySort() {
      switch (this.listMemorizedEventsSort.type) {
      case MemorizedEventsSortTypes.DATE:
        this.sortByDate()
        break
      case MemorizedEventsSortTypes.CODE:
        this.sortByCode()
        break
      case MemorizedEventsSortTypes.SEVERITY:
        this.sortBySeverity()
        break
      case MemorizedEventsSortTypes.SELECTION:
        this.sortBySelection()
        break
      }
    },
    /**
     * Permet un trie par date ascendant ou descendant sur la liste des événements mémorisés
     * @method
     * @public
     */
    sortByDate() {
      const events = [...this.memorizedEvents]
      if (this.listMemorizedEventsSort.asc) {
        this.events = events.sort((a,b) => new Date(a.onsetDateTime) - new Date(b.onsetDateTime))
      } else {
        this.events = events.sort((a,b) => new Date(b.onsetDateTime) - new Date(a.onsetDateTime))
      }
    },
    /**
     * Permet un trie par code ascendant ou descendant sur la liste des événements mémorisés
     * @method
     * @public
     */
    sortByCode() {
      const events = [...this.memorizedEvents]
      if (this.listMemorizedEventsSort.asc) {
        this.events = events.sort((a,b) => a.code.localeCompare(b.code))
      } else {
        this.events = events.sort((a,b) => b.code.localeCompare(a.code))
      }
    },
    /**
     * Permet un trie par sévérité ascendant ou descendant sur la liste des événements mémorisés
     * @method
     * @public
     */
    sortBySeverity() {
      const events = [...this.memorizedEvents]
      if (this.listMemorizedEventsSort.asc) {
        this.events = events.sort((a,b) => a.severity - b.severity)
      } else {
        this.events = events.sort((a,b) => b.severity - a.severity)
      }
    },
    /**
     * Permet un trie par ordre de séléction ascendant ou descendant sur la liste des événements mémorisés
     * @method
     * @public
     */
    sortBySelection() {
      if (this.listMemorizedEventsSort.asc) {
        this.events = [...this.memorizedEvents]
      } else {
        this.events = [...this.memorizedEvents].reverse()
      }
    },
    getEventResourceTypeColor: utils.getEventResourceTypeColor,
  }
}
</script>

<style scoped>
#selected-events-action {
  height: 10px;
  width: 10px;
  background-color: none;
  border-radius: 50%;
  border: 1.5px solid var(--color-text);
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
}

.dot-condition {
  background-color: none;
  border-radius: 50%;
  border: 2px solid var(--color-primary-accent-opaque);
  display: inline-block;
}

.dot-procedure {
  background-color: none;
  border-radius: 50%;
  border: 2px solid var(--color-secondary-accent-opaque);
  display: inline-block;
}

.dot-immunization, .dot-allergy {
  background-color: none;
  border-radius: 50%;
  border: 2px solid var(--c-green-1);
  display: inline-block;
}

.dot-default {
  background-color: none;
  border-radius: 50%;
  border: 2px solid var(--color-primary-accent-opaque);
  display: inline-block;
}

.dot-fill-condition {
  background-color: var(--color-primary-accent-trsp);
}

.dot-fill-default {
  background-color: var(--color-primary-accent-trsp);
}

.dot-fill-procedure {
  background-color: var(--color-secondary-accent-trsp);
}

.dot-fill-immunization, .dot-fill-allergy {
  background-color: var(--c-green-1);
}

.selected-event {
  padding-bottom: 10px;
  display: inline-grid;
  grid-template-columns: 1fr 4fr;
  display: flex;
}

.selected-event-text {
  display: inline-block;
  padding: 0px;
  margin-left: 10px;
  font-size: 1.2rem;
  width: calc(100% - 60px);
  text-align: justify-all;
}
.red-circle-container{
  display: flex;
  justify-content: right;
}
</style>