<template>
  <div 
    id="ListeSix" 
    class="fixed right-0 flex flex-row"
  >
    <div
      id="border-list-action-container"
      class="self-end"
    >
      <div class="flex flex-col items-end">
        <ListOptions />
        <StateManager />
        <AddPatientNotes />
        <div class="flex flex-row items-center">
          <div 
            id="display-NameList-container"
            class="mr-2"
          >
            <span
              id="displayNameList"
            />
          </div>
          <svg  
            id="border-list-arrow-action"
            ref="border-list-arrow-action"
            class="inline-block"
            width="28"
            height="20"
          >
            <polyline
              ref="arrow-svg"
              points="15 5 5 10 15 15"
              stroke="var(--color-text)"
              stroke-width="2.2"
              stroke-linecap="round"
              fill="none"
              stroke-linejoin="round" 
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="relative">
      <ListSelectedEvent
        :ref="borderListTypes.LIST_MEMORIZED_EVENTS"
      />
      <ListTimeFilter
        :ref="borderListTypes.LIST_TIME_FILTER"
      />
      <ListChooseTypeEventsDisplayVue
        :ref="borderListTypes.LIST_CHOOSE_TYPE_EVENTS_DISPLAY"
      />
      <ListChooseAssemblyElements
        :ref="borderListTypes.LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY"
      />
      <ListUnclassified
        :ref="borderListTypes.LIST_UNCLASSIFIED"
      />
      <ListEventsToAdd
        :ref="borderListTypes.LIST_EVENTS_TO_ADD"
      />
      <ListTop10
        :ref="borderListTypes.LIST_TOP_10"
      />
      <ListEnterHierarchy
        :ref="borderListTypes.LIST_ENTER_HIERARCHY"
      />
      <ListNurseEntry
        :ref="borderListTypes.LIST_NURSE_ENTRY"
      />
      <ListForceJoinCollab
        :ref="borderListTypes.LIST_FORCE_JOIN_COLLAB"
      />
      <ListPatientNotes
        :ref="borderListTypes.LIST_PATIENT_NOTES"
      />
      <div 
        id="caroussel-container" 
        ref="caroussel-container" 
        class="absolute none justify-around items-center w-full overflow-hidden bottom-0"
      >
        <div 
          id="list-caroussel" 
          ref="list-caroussel" 
          class="flex justify-between sticky"
        >
          <svg
            v-for="(caroussel, index) in carousselList"
            :id="caroussel.type"
            :key="index"
            width="12" 
            height="12"
            @mouseover="(e) => onMouseOverCarousselItem(e, caroussel)"
            @mouseleave="(e) => onMouseLeaveCarousselItem(e, caroussel)"
            @click="(e) => onMouseClickCarousselItem(e, caroussel)"
          >
            <circle 
              cx="6" 
              cy="6"
              r="5" 
              :fill="(currentDisplayedBorderList === caroussel.type) ? 'red' : 'none'" 
              stroke="var(--color-text)"
              stroke-width="1" 
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ListSelectedEvent from "@/components/borderLists/ListSelectedEvent/ListSelectedEvent.vue"
import ListTimeFilter from "@/components/borderLists/ListTimeFilter/ListTimeFilter.vue"
import ListChooseTypeEventsDisplayVue from '@/components/borderLists/ListChooseTypeEventsDisplay/ListChooseTypeEventsDisplay.vue'
import ListChooseAssemblyElements from "@/components/borderLists/ListChooseAssemblyElements/ListChooseAssemblyElements.vue"
import ListUnclassified from "@/components/borderLists/ListUnclassified/ListUnclassified.vue"
import ListEventsToAdd from "@/components/borderLists/ListEventsToAdd/ListEventsToAdd.vue"
import ListTop10 from "@/components/borderLists/ListTop10/ListTop10.vue"
import ListEnterHierarchy from "@/components/borderLists/ListEnterHierarchy/ListEnterHierarchy.vue"
import ListNurseEntry from "@/components/borderLists/ListNurseEntry/ListNurseEntry.vue"
import ListForceJoinCollab from "@/components/borderLists/ListForceJoinCollab/ListForceJoinCollab.vue"
import * as mutationTypes from "@/store/mutations-types"
import ListPatientNotes from "@/components/borderLists/ListPatientNotes/ListPatientNotes.vue"

import borderListTypes from '@/enums/borderList/borderListTypes.js'
import timeFilter from '@/enums/time_filter.js'
import { mapGetters, mapActions } from 'vuex'
import * as d3 from "d3"
import D3Animation from "@/config/D3Animation.js"
import AddPatientNotes from '@/components/patientNotes/AddPatientNotes.vue'
import StateManager from "@/components/hud/state/StateManager.vue"
import ListOptions from '@/components/borderLists/Options.vue'

import AppContextChecker from "@/libraries/AppContextChecker.js"

export default {
  components: {
    ListSelectedEvent,
    ListTimeFilter,
    ListChooseTypeEventsDisplayVue,
    ListChooseAssemblyElements,
    ListUnclassified,
    ListEventsToAdd,
    ListTop10,
    ListEnterHierarchy,
    ListNurseEntry,
    ListForceJoinCollab,
    ListPatientNotes,
    AddPatientNotes,
    StateManager,
    ListOptions
  },
  emits: ['changeState'],
  data: () => ({
    /**
     * Liste des types de contenu possibles dans le panel de droite de l'application
     * @type {Object<EyeEnumBorderListType>}
     */
    borderListTypes: borderListTypes,
    carousselList: []
  }),
  computed: {
    ...mapGetters({
      currentDisplayedBorderList: 'borderList/currentDisplayedBorderList',
      isDisplayedList: 'borderList/isDisplayedList',
      isInNurseEntry: 'isInNurseEntry'
    })
  },
  watch:{
    /**
     * Gestion de s'il est nécessaire d'emettre un événement entrainant le mouvement de la représentation sur le côté gauche
     */
    isDisplayedList(){
      this.moveArrowButton()

      if (this.isDisplayedList) {
        d3.select('#caroussel-container')
          .transition()
          .delay(500)
          .style('display','flex')
      } else {
        d3.select('#caroussel-container')
          .style('display','none')
        d3.select('#container')
          .transition()
          .delay(500)
          .style('width', '0')
      }
      this.$emit('changeState', this.isDisplayedList)
    },
    /**
     * Affichage ou non des listes en fonction de la liste active + gestion du caroussel
     */
    currentDisplayedBorderList(newList, oldList) {
      if (oldList) {
        this.$refs[oldList].movePanel(false)
      }

      if (newList) {
        //Paramétre time, utilisé seulement avec la liste LIST_TIME_FILTER
        this.controlEvent({isEnabled: true, typeEventslist: newList, time: timeFilter.WEEK})
        this.$store.commit(`borderList/${mutationTypes.SET_OLD_DISPLAYED_BORDER_LIST}`, oldList)
        this.$refs[newList].movePanel(true)
      }
    },
    isInNurseEntry() {
      this.setCarousselConfig()
    }
  },
  mounted() {
    this.setCarousselConfig()
    this.initEvent()
  },
  
  methods:{
    ...mapActions({
      sendEvent: "ws/sendEvent",
      sendProperties: "ws/sendProperties",
      collaborativeEventTreated: "ws/collaborativeEventTreated",
      controlEvent: "event/common/controlEvent"
    }),
    /**
     * Cette fonction permet l'initialisation des actions sur le bouton de la liste des elements mémorisés
     * @method
     * @public
     */
    initEvent() {
      d3.select(this.$refs['border-list-arrow-action'])
        .on('click', (event) => {
          this.sendEvent({ event: event })
          this.onClickArrowButton()
          this.collaborativeEventTreated()
        })
    },
    /**
     * Cette fonction est appelé lorsque l'utilisateur clique sur le bouton gérant la liste des événements mémorisés
     * @public
     */
    onClickArrowButton() {
      if (this.currentDisplayedBorderList === null) {
        this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_MEMORIZED_EVENTS)
      } else {
        this.$refs[this.currentDisplayedBorderList].movePanel(!this.isDisplayedList)
      }
    },

    /**
     * Animation de la flèche pour afficher les listes sur la droite.
     * @public
     */
    moveArrowButton(){
      const points = this.isDisplayedList ? '5 5 15 10 5 15' : '15 5 5 10 15 15'

      d3.select(this.$refs['arrow-svg'])
        .transition()
        .duration(D3Animation.BORDER_LIST_ARROW)
        .attr('points', points)
    },
    onMouseOverCarousselItem(event, d) {
      this.sendEvent({event: event})
      d3.select('#displayNameList').text(d.label)
      d3.select(event.currentTarget)
        .select('circle')
        .attr('fill', 'red')
      this.collaborativeEventTreated()
    },
    onMouseLeaveCarousselItem(event, d) {
      this.sendEvent({event: event})
      if (d.type !== this.currentDisplayedBorderList) {
        d3.select(event.currentTarget)
          .select('circle')
          .attr('fill', 'none')
      }
      d3.select('#displayNameList').text('')
      this.collaborativeEventTreated()
    },
    onMouseClickCarousselItem(event, d) {
      this.sendEvent({ event: event })
      this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, d.type)
      this.collaborativeEventTreated()
    },
    setCarousselConfig() {
      const carousselList = [
        { type: borderListTypes.LIST_MEMORIZED_EVENTS, label: 'Evenements mémorisés' },
        { type: borderListTypes.LIST_TIME_FILTER, label: 'Filtrage par temps', conditions: this.isInNurseEntry},
        { type: borderListTypes.LIST_CHOOSE_TYPE_EVENTS_DISPLAY, label: 'Types d\'événement', conditions: this.isInNurseEntry === false && AppContextChecker.otherThanPharmacienTypePatient(this.$store) },
        { type: borderListTypes.LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY, label: 'Sauvegarder filtres'},
        { type: borderListTypes.LIST_TOP_10, label: 'Top 10', conditions: this.isInNurseEntry === false },
        { type: borderListTypes.LIST_PATIENT_NOTES, label: 'Notes Patient'},
        { type: borderListTypes.LIST_NURSE_ENTRY, label: 'Suivi Diabétologie', conditions: this.isInNurseEntry}
      ]
      this.carousselList = carousselList.filter(options => options.conditions === undefined || options.conditions === true)
    }
  }
}
</script>

<style scoped>
  #ListeSix{
    height:100vh;
  }

  #border-list-action-container {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: calc(100vh - 15px);
    margin-right: 15px;
    margin-bottom: 15px;
  }

  #displayNameList{
    width: 150px;
  }

  #caroussel-container {
    height: 50px;
    background-color: var(--color-bg-1-list);
    z-index: 6;
    display: none;
  }

  #list-caroussel {
    bottom: 20px;
    width: 150px;
  }
</style>