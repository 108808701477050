/**
 * Définit les groupes des groupes de fonctionnalités. Ces groupes sont utiles pour le menu contextuel afin de lier certaines fonctionnalités entre elles lors des options on / off
 */
export default {
  "FILTERING": "FILTERING",
  "TEMPORALITY": "TEMPORALITY",
  "SCORE": "SCORE",
  "DISPLAY_SIZE": "DISPLAY_SIZE",
  "DISPLAY_LABEL_SECTION": "DISPLAY_LABEL_SECTION",
  "MODE": "MODE",
  "POPMERGE": "POPMERGE",
  "REPCOLOR": "REPCOLOR",
  "CIRCLE_THEME": "CIRCLE_THEME",
  "APP_THEME": "APP_THEME",
  "PATIENT_FILTERING": "PATIENT_FILTERING",
  "SCORE_POSITION": "SCORE_POSITION",
  "PANEL": "PANEL",
  "CLASSIFICATION": "CLASSIFICATION"
}