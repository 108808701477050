<template>
  <ContextMenu
    v-if="contextMenu.show"
    :x="contextMenu.x"
    :y="contextMenu.y"
    :items="contextMenu.items"
    :z-index="501"
    @close="contextMenu.show = false"
  />
  <div
    v-if="displayViewerLayer"
    id="collab-viewer-layer"
    ref="collab-viewer-layer"
    @contextmenu="onContextMenu"
    @click="contextMenu.show = false"
  />
</template>

<script>
import ContextMenu from "../components/ContextMenu.vue"
import { mapGetters } from "vuex"
import sharedMenu from '@/config/shared-menu.js'
import { fillMenu  } from "@/config/contextMenu"

/**
 * Ce composant est utile lors d'une session collaborative. Ce composant permet d'afficher une div invisible prenant toute la page au premier plan. Son but est d'empecher l'utilisateur d'effectuer des actions sur l'application. Ce composant est utilisé dans 2 contextes différents. D'une part lorsque l'utilisateur est spectateur d'une session collaborative et lorsqu'une animation principal s'execute pour empecher l'utilisateur d'executer une autre animation principal qui pourrait provoquer des erreurs d'animation ou dans l'application.
 * @displayName Composant CollaborativeViewerLayer
 */
export default {
  name: "CollaborativeViewerLayer",
  components: {
    ContextMenu,
  },
  data: () => ({
    /**
     * Définit si le composant doit-être actif ou non
     * @type {Boolean}
     */
    displayViewerLayer: false,
    /**
     * Définit la configuration du menu contextuel lorsque le composant est actif
     * @type {EyeContextMenu}
     */
    contextMenu: {
      show: false,
      x: 0,
      y: 0,
      items: [],
    },
  }),
  computed: {
    ...mapGetters({
      isLoadingRepresentation: "isLoadingRepresentation",
      replay: "record/replay"
    }),
    /**
     * Définit si l'utilisateur se trouve dans une session collaborative
     * @type {Boolean}
     */
    isInCollaboration() {
      return this.$store.state.ws.inCollaboration;
    },
    /**
     * Définit si l'utilisateur est spectateur de la session collaborative en cours
     * @type {Boolean}
     */
    isViewer() {
      return this.$store.state.ws.viewer;
    },
  },
  watch: {
    isInCollaboration() {
      this.changeStateLayer()
    },
    isViewer() {
      this.changeStateLayer()
    },
    isLoadingRepresentation() {
      this.changeStateLayer()
    },
    replay() {
      this.changeStateLayer()
    }
  },
  created() {
    const menuStruct = [
      { labelId: 'PrendreLaMain', selected: false, },
      { labelId: 'QuitterCollabSession', selected: false, },
      ...sharedMenu
    ]
    fillMenu(menuStruct, this.contextMenu.items, this.contextMenu.items, this.$store)
  },
  mounted() {
    this.changeStateLayer();
  },
  methods: {
    /**
     *  Cette fonction est appelé lorsque l'utilisateur effectue un clique droit. La fonction permet l'intialisation du menu contextuel si celui-ci n'est pas encore affiché, ou de le ferme si celui-ci était déjà ouvert
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournis au listener
     */
    onContextMenu(event) {
      if (event.isTrusted && this.isInCollaboration && this.isViewer) {
        event.preventDefault();
        if (this.contextMenu.show) {
          this.contextMenu.show = false;
        } else {
          this.contextMenu.x = event.clientX;
          this.contextMenu.y = event.clientY;
          this.contextMenu.show = true;
        }
      }
    },
    /**
     * Cetter fonction permet de déterminer si le calque du composant doit être rendu ou non. Le calque est affiché si l'utilisateur est en session collaborative et qu'il est spectateur OU lorsque l'application effectue une animation principale
     * @method
     * @public
     */
    changeStateLayer() {
      if (
        (this.isInCollaboration && this.isViewer) ||
        this.isLoadingRepresentation || this.replay === true
      ) {
        this.displayViewerLayer = true;
      } else {
        this.displayViewerLayer = false;
      }
    },
  },
};
</script>

<style scoped>
#collab-viewer-layer {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 500; /* avec une mise au premier plan, et en prenant tout l'écran, les événements pouvant être produit par l'utilsateur ne sont trigger par les .on du code */
}
</style>