<template>
  <g
    id="group-lines"
    ref="group-lines"
  />
</template>

<script>
import MixinLines from '@/components/event/MixinLines.js'
import { mapGetters } from 'vuex'

export default {
  name: 'EventFiltering',
  mixins: [MixinLines],
  data: () => ({
    /**
     * Il s'agit de l'id qui sera utilisé pour le groupe contenant les lignes de filtrages
     * @type {String}
     */
    idGroupLines: 'group-lines',
    /**
     * Il s'agit de la classe sera utilisé sur les lignes traçant un filtrage sur des événements dit par défaut
     * @type {String}
     */
    traceDefaultClass: 'trace-default',
    /**
     * Il s'agit de la classe sera utilisé sur les lignes traçant un filtrage sur des diagnostiques
     * @type {String}
     */
    traceConditionClass: 'trace-condition',
    /**
     * Il s'agit de la classe sera utilisé sur les lignes traçant un filtrage sur des actes
     * @type {String}
     */
    traceProcedureClass: 'trace-procedure',
    /**
     * Il s'agit de la classe sera utilisé sur les lignes traçant un filtrage sur des vaccins
     * @type {String}
     */
    traceImmunizationClass: 'trace-immunization',
    /**
     * Il s'agit de la classe sera utilisé sur les lignes traçant un filtrage sur des allergies
     * @type {String}
     */
    traceAllergyClass: 'trace-allergy'
  }),
  computed: {
    ...mapGetters({
      filteredEvents: 'event/common/filteredEvents',
    }),
    /**
     * Détermine si les événements doivent être affichés
     * @type {Boolean}
     */
    isDisplayEvents() {
      return this.$store.state.event.common.displayEvents;
    },
  },
  watch: {
    isDisplayEvents() {
      if (this.isDisplayEvents) {
        this.showFilters(this.filteredEvents)
      } else {
        this.removeFiltering()
      }
    },
    filteredEvents() {
      if (this.isDisplayEvents) {
        this.showFilters(this.filteredEvents)
      }
    }
  }
}
</script>

<style>

.trace-condition, .trace-default {
  stroke: var(--color-primary-accent-opaque);
  stroke-width: 2px;
  fill: none;
  opacity: 1;
  pointer-events: none;
}

.trace-procedure {
  stroke: var(--color-secondary-accent-opaque);
  stroke-width: 2px;
  fill: none;
  opacity: 1;
  pointer-events: none;
}

.trace-immunization, .trace-allergy {
  stroke: var(--c-green-1);
  stroke-width: 2px;
  fill: none;
  opacity: 1;
  pointer-events: none;
}

</style>