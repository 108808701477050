<template>
  <g>
    <path
      id="score-circle"
      ref="ref-circle"
    />
    <path
      id="invisible-score-circle"
      ref="invisible-ref-circle"
    />
    <g id="score-circle-event-container" />
  </g>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MixinRefCircle from '@/components/refCircle/MixinRefCircle.js'
import CircleUtility from '@/libraries/CircleUtility'
import * as d3 from 'd3'
import * as mutationTypes from '@/store/mutations-types.js'

import * as EyeColor from '@/assets/color.js'
import D3Animation from '@/config/D3Animation.js'

import { useScoreChecker } from '@/components/refCircle/ScoreChecker.js'

export default {
  mixins: [MixinRefCircle],
  setup() {
    return useScoreChecker('score-circle-event-container', 'score-circle-events')
  },
  data: () => ({
    /**
     * Il s'agit de l'identifiant donné à l'element du dom du cercle des scores
     * @type {String}
     */
    idRefCircle: 'score-circle',
    /**
     * Il s'agit de l'identifiant donnée à l'lement du dom du cercle des scores invisible permettant d'augmenter la zone de captation des événements de la souris
     * @type {String}
     */
    idInvisibleRefCircle: 'invisible-score-circle',
    /**
     * Il s'agit de l'identifiant donnée au group contenant les cercles des événements du cercle des scores
     * @type {String}
     */
    idEventsContainer: 'score-circle-event-container',
    /**
     * Il s'agit de l'identifiant donnée à chacun des cercles des événements du cercle des scores
     * @type {String}
     */
    classRefEvents: 'score-circle-events'
  }),
  computed: {
    ...mapGetters({
      scoreCircle: "refCircle/score/scoreCircle",
      labelToDisplay: "event/common/labelToDisplay",
      hoveredEvent: "event/common/hoveredEvent",
      matchScoreHoveredEvent: "refCircle/score/hoveredEvent"
    }),
  },
  watch: {
    scoreCircle() {
      if (this.scoreCircle !== null) {
        this.points = this.scoreCircle.points.map((point) => {
          point.radius = CircleUtility.getRadiusAtPoint(this.radius, point.severity)
          return point
        })
        this.draw()
        this.calcEventCircle()
        this.initEventInvisibleRefCircle()
      } else {
        d3.select(`#${this.idRefCircle}`).attr("d", "");
        d3.select(`#${this.idInvisibleRefCircle}`).attr("d", "");
        this.points = []
        this.events = []
        this.disableScoreEvents()
      }
    },
    hoveredEvent() {
      this.onHoveredEventChange(this.hoveredEvent, this.events)
    },
    matchScoreHoveredEvent() {
      this.onMatchScoreHoveredEventChange(this.matchScoreHoveredEvent, this.events)
    }
  },
  mounted() {
    this.initRefCircleEvents()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * Cette fonction permet l'initialisation des événements d'écoute lors du survol / arret de survol des événements du cercle des scores
     */
    initRefCircleEventsEvents() {
      d3.select(`#${this.idEventsContainer}`)
        .selectAll(`.${this.classRefEvents}`)
        .data(this.events)
        .on("mouseover", (event, d) => {
          this.sendEvent({ event: event })
          this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_REF_EVENT}`, d, { root: true })
          this.$store.commit(`event/common/${mutationTypes.PUSH_LABEL_TO_DISPLAY}`, d)
          this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`,[...this.labelToDisplay])
          d3.select(event.currentTarget)
            .transition()
            .duration(D3Animation.SCORE_CIRCLE_APPEAR)
            .style('stroke-opacity', 1)
            .style('fill', EyeColor.getFillColorEventCircle(0.8))

          this.collaborativeEventTreated()
        })
        .on("mouseleave", (event, d) => {
          this.sendEvent({ event: event })
          this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`, this.labelToDisplay.filter((e) => e.id !== d.id))
          this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_REF_EVENT}`, null, { root: true })
          d3.select(event.currentTarget)
            .transition()
            .duration(D3Animation.SCORE_CIRCLE_DISAPPEAR)
            .style('stroke-opacity', 0)
            .style('fill', EyeColor.getFillColorEventCircle(0))

          this.collaborativeEventTreated()
        })
        .on('contextmenu', (event, d) => {
          this.sendEvent({ event: event })
          event.preventDefault()
          event.stopPropagation()
          this.$store.commit(`event/common/${mutationTypes.TOOLTIPS_ADD_EVENTS}`, d)
          this.collaborativeEventTreated()
        })
    },
    /**
     * Cette fonction permet l'initialisation d'événements d'écoute sur le cercle des scores
     */
    initRefCircleEvents() {
      d3.select(this.$refs['invisible-ref-circle'])
        .on('contextmenu', (event) => {
          this.sendEvent({ event: event })
          event.preventDefault()
          event.stopPropagation()
          //TO-DO Utiliser plutôt disableScore à priori Mateo devrait le mettre dans le store suite à l'ajout de fonctionnalité sur les listes
          this.$store.commit(`refCircle/score/${mutationTypes.DISABLE_SCORE}`, null)
          this.collaborativeEventTreated()
        })
    }
  }
}

</script>

<style>
#score-circle {
  fill: none;
  stroke: var(--color-primary-accent-opaque);
  stroke-width: 2px;
}

#invisible-score-circle {
  fill: none;
  stroke: transparent;
  stroke-width: 20px;
}
</style>