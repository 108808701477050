<template>
  <div>
    <form
      method="dialog"
      class="modal_form relative"
      @submit="e => e.preventDefault()"
    >
      <div class="flex flex-row">
        <div class="flex flex-col basis-0 grow mr-1.5">
          <label>Severité:</label>
          <input
            ref="severity-input"
            v-model="severity"
            type="number"
            max="4"
            min="1"
            pattern="[1-4]{1}"
            name="severity"
            required
          >
          <p
            v-for="(error, index) in errors.severity"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </div>
        <div class="flex flex-col basis-0 grow ml-1.5">
          <label>Date:</label>
          <input
            ref="date-input"
            v-model="eventDate"
            type="date"
            name="date"
            required
          >
          <p
            v-for="(error, index) in errors.date"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </div>
      </div>
      <input
        ref="input-diag"
        v-model="eventLabel"
        required
        placeholder="Texte Libre"
        class="mt-3"
      >
      <p
        v-for="(error, index) in errors.event"
        :key="index"
        class="error-modal"
      >
        {{ error }}
      </p>
      <div
        id="suggestions-modal"
        ref="suggestions-modal"
      >
        <ul>
          <li
            v-for="(suggestion, index) in suggestions"
            :key="index"
            class="suggestion relative pr-4 pb-2 flex"
            @click="(e) => selectedSuggestion(e, suggestion)"
          >
            {{ `[${suggestion.code}] ${suggestion.defaultLabel}` }}
            <button
              class="p-0 bg-transparent border-0 absolute right-0"
              @click="(e) => onShowAssociatedEvents(e, suggestion.code)"
            >
              🔗
            </button>
          </li>
        </ul>
      </div>
    </form>
    <div class="grid grid-cols-2 mt-5">
      <button
        class="col-start-1"
        @click="onShowOptions"
      >
        Options
      </button>
      <button
        class="col-start-2"
        @click="onSaveEvent"
      >
        Enregistrer l'événement
      </button>
      <button
        v-if="editingType === 'ALREADY_EXIST'"
        @click="onDeleteEvent"
      >
        Supprimer
      </button>
    </div>
  </div>
</template>

<script>
import EyeFetch from '@/libraries/EyeFetch'
import * as d3 from 'd3'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddEventModalMainInformations',
  props: {
    sectionName: {
      type: String,
      required: true
    }
  },
  emits: ['save', 'options', 'delete'],
  data: () => ({
    selectedEventCode: null,
    eventLabel: "",
    severity: "1",
    eventDate: "",
    suggestions: [],
    errors: {
      severity: [],
      date: [],
      event: [],
    },
    resourceType: 'EyeCustom',
    timeoutInterval: null,
  }),
  computed: {
    ...mapGetters({
      editingType: 'event/edit/editingType'
    })
  },
  mounted() {
    this.initEvents()
    this.eventDate = d3.timeFormat('%Y-%m-%d')(new Date())
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      sendProperties: 'ws/sendProperties',
      getListAssociatedEvents: 'refCircle/associatedEvents/getListAssociatedEvents',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    selectedSuggestion(event, suggestion) {
      this.sendEvent({
        event: event
      })
      this.eventLabel = suggestion.defaultLabel
      this.severity = suggestion.severity
      this.selectedEventCode = suggestion.code
      this.resourceType = suggestion.resourceType
      this.collaborativeEventTreated()
    },
    initEvents() {
      d3.select(this.$refs['severity-input'])
        .on('change', (event) => {
          this.sendEvent({
            event: event,
            params: {
              text: this.severity
            }
          })
          this.collaborativeEventTreated()
        })

      d3.select(this.$refs['date-input'])
        .on('change', (event) => {
          this.sendEvent({
            event: event,
            params: {
              text: this.eventDate
            }
          })
          this.collaborativeEventTreated()
        })

      d3.select(this.$refs['input-diag'])
        .on('input', async (event) => {
          //a partir du moment que l'utilisateur touche à l'input ce n'est plus un event codifié mais custom
          this.resourceType = 'EyeCustom'
          this.selectedEventCode = null
          const lenText = this.eventLabel.length

          if (event.isTrusted) {
            if (this.timeoutInterval !== null) {
              clearTimeout(this.timeoutInterval)
            }
            
            if (lenText < 2) {
              this.timeoutInterval = setTimeout(() => {
                this.sendEvent({
                  event: event,
                  params: {
                    text: ''
                  }
                })
              }, 500)
              return
            }

            if (this.eventLabel[lenText - 1] === ' ' && this.eventLabel[lenText - 2] !== ' ') {
              this.sendEvent({
                event: event,
                params: {
                  text: this.eventLabel
                }
              })
              this.makeSuggestionRequest({
                sentence: this.eventLabel,
                section: this.sectionName
              })
            } else {
              this.timeoutInterval = setTimeout(() => {
                this.sendEvent({
                  event: event,
                  params: {
                    text: this.eventLabel
                  }
                })
                this.makeSuggestionRequest({
                  sentence: this.eventLabel,
                  section: this.sectionName
                })
              }, 500)
            }
          } else if (lenText >= 2) {
            this.sendEvent({ event: event })
            this.makeSuggestionRequest({
              sentence: this.eventLabel,
              section: this.sectionName
            })
            this.collaborativeEventTreated()
          }
        })

      d3.select(this.$refs['suggestions-modal'])
        .on('scroll', () => {
          this.sendProperties({
            target: this.$refs['suggestions-modal'],
            params: {
              scrollTop:this.$refs['suggestions-modal'].scrollTop
            }
          })
        })
    },
    async makeSuggestionRequest({sentence, section}) {
      try {
        let response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/services/autocomplete?sentence=${sentence}&section=${section}`, {
          credentials: 'include'
        })
        response = await response.json()
        this.suggestions = response
      } catch (err) {
        console.error(err)
      }
    },
    async onShowAssociatedEvents(event, code) {
      this.sendEvent({event})
      event.preventDefault()
      event.stopPropagation()
      this.suggestions = await this.getListAssociatedEvents(code)
      this.collaborativeEventTreated()
    },
    checkForm() {
      this.errors = {
        severity: [],
        date: [],
        event: []
      }

      if (this.severity < 1 || this.severity > 4) {
        this.errors.severity.push('La sévérité doit-être comprise entre 1 et 4')
      }
      if (this.eventDate === "") {
        this.errors.date.push("Une date est requise")
      }
      if (this.eventLabel.length < 3) {
        this.errors.event.push("Un minimum de 3 caractères est requis")
      }
    },
    resetForm() {
      this.selectedEventCode = null
      this.eventLabel = ""
      this.severity = "1"
      this.eventDate = d3.timeFormat('%Y-%m-%d')(new Date())
      this.suggestions = []
      this.errors = {}
      this.resourceType = 'EyeCustom'
    },
    editEvent(event) {
      const date = event.onsetDateTime || event.date
      this.selectedEventCode = event.code
      this.eventLabel = event.defaultLabel
      this.severity = event.severity
      this.eventDate = d3.timeFormat('%Y-%m-%d')(new Date(date))
      this.suggestions = []
      this.errors = {}
      this.resourceType = event.resourceType
    },
    getMainInformation() {
      return {
        selectedEventCode: this.selectedEventCode,
        eventLabel: this.eventLabel,
        severity: this.severity,
        eventDate: this.eventDate,
        resourceType: this.resourceType
      }
    },
    onShowOptions(event) {
      this.sendEvent({event})
      this.$emit('options')
      this.collaborativeEventTreated()
    },
    async onSaveEvent(event) {
      this.checkForm()

      for (const errors of Object.values(this.errors)) {
        if (errors.length > 0) {
          return
        }
      }

      await this.$emit('save', event)
    },
    onDeleteEvent(event) {
      this.$emit('delete', event)
    }
  }
}
</script>

<style scoped>

</style>