import * as mutationTypes from '../mutations-types.js'
import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from '@/libraries/EyeFetch.js'
import UserRole from '@/shared/enums/userRole.js'
import cookies from 'vue-cookies'

export default {
  namespaced: true,
  state: {
    /**
     * Information de l'utilisateur utilisant l'application
     * @type {Object}
     */
    userData: {},
    loggedIn: false,
    tokenTimeout: null,
    typePatientContext: UserRole.GENERALISTE,
    availableTypePatientContext: []
  },
  getters: {
    userData: state => state.userData,
    loggedIn: state => state.loggedIn,
    typePatientContext: state => state.typePatientContext,
    availableTypePatientContext: state => state.availableTypePatientContext
  },
  mutations: {
    /**
     * Permet de définir les données de l'utilisateur actuellement connecté à l'application
     * @method
     * @public
     */
    [mutationTypes.UPDATE_USER_DATA] (state, payload) {
      state.userData = payload
    },
    [mutationTypes.SET_LOGGED_IN] (state, payload) {
      state.loggedIn = payload
    },
    [mutationTypes.SET_TYPE_PATIENT_CONTEXT] (state, payload) {
      state.typePatientContext = payload
      cookies.set('typePatientContext', payload, "1d")
    },
    [mutationTypes.SET_AVAILABLE_TYPE_PATIENT_CONTEXT] (state, payload) {
      state.availableTypePatientContext = payload
    }
  },
  actions: {
    /**
     * Permet d'obtenir les informations de l'utilisateur connecté à l'application
     * @method
     * @public
     */
    async getUserData(context) {
      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/user/me`, {
          method: 'GET',
          credentials: 'include'
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'user/getUserData', params: null})
          return null
        }

        response = await response.json()

        context.commit(mutationTypes.UPDATE_USER_DATA, response)
        context.dispatch('calcAvailableTypePatientContext')
        return response
      } catch (err) {
        ErrorManager.fetchError(err)
        return null
      }
    },
    calcAvailableTypePatientContext(context) {
      const patientContext = [UserRole.PHARMACIEN, UserRole.GENERALISTE, UserRole.QOR]
      const findPatientContext = []

      for (const role of context.state.userData.Role) {
        if (patientContext.includes(role)) {
          findPatientContext.push(role)
        }
      }

      if (findPatientContext.length > 0) {
        context.commit(mutationTypes.SET_TYPE_PATIENT_CONTEXT, findPatientContext[0])
      }
      context.commit(mutationTypes.SET_AVAILABLE_TYPE_PATIENT_CONTEXT, findPatientContext)
    },
    async logout() {
      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/user/logout`, {
          method: 'GET',
          credentials: 'include'
        })
        response = await response.json()
  
        const params = new URLSearchParams()
        params.append("xsrf", response.xsrf)
        params.append("logout", "yes")
        response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/oidc/session/end/confirm`, {
          method: "POST",
          credentials: 'include',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          },
          body: params
        })
        response = await response.json()
      } catch {}
      window.location.href = `${process.env.VUE_APP_FRONT_BASE_URL}`
    }
  }
}